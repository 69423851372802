import { get, post } from '@/utils/api';

const state = {};

const mutations = {};

const actions = {
	async get(_, { name })
	{
		const { data } = await get(`/settings/secret/${name}`);

		return data.setting;
	},
	async set(_, { name, changes, merge, overwritePaths })
	{
		const { data } = await post(
			`settings/secret/${name}`,
			{ changes, merge, overwritePaths }
		);

		return data;
	}
};

const getters = {};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
