// @ts-check
/**
 * Media types allowed for a media or status post created through Create User Post
 */
const userPostMediaTypes = {
	images: 'images',
	audio: 'audio',
	videos: 'videos',
	documents: 'documents',
	links: 'links'
};
/**
 * Aspect ratio of a post thumbnail when the post has display mode thumbnail or thumbnailOnly
 */
const userPostThumbnailAspectRatios = {
	'16/9': '16/9',
	'3/2': '3/2',
	1: '1'
};

/** Using the older export syntax so that JEST won't be confused. */
module.exports = {
	userPostMediaTypes,
	userPostThumbnailAspectRatios
};
