import { Utils, I18N } from 'acb-lib';
import ImageUploaderBtn from '@/components/ui/wysiwygPlugins/ImageUploaderBtn';
import EmojiSelector from '@/components/ui/wysiwygPlugins/EmojiSelector';
import { userPostMediaTypes } from '@/configs/mediaConstants';

const devMode = process.env.NODE_ENV === 'development';

/** Duplicated from `aluminate-api/src/utils/Audit.ts`. */
export const logTypes = Object.freeze({
	bulkLanguageUpload: 'bulkLanguageUpload',
	languagePublishedStateToggle: 'languagePublishedStateToggle'
});

export const cUserControlsMenuWidth = 330;

export const powerUps = Object.freeze({
	connections: '629b7721-6d83-4cd3-b6f4-23c07c366c2c',
	membership: '73d88eee-e653-41a2-ab57-556da2030e24',
	entityEmailTemplates: 'a0bf17e7-a07a-42c7-bf9d-2e9426701ea4',
	durations: '193aca47-84b4-402e-9645-d04f8c5d2f55',
	contact: 'ff1333d9-aab7-4f67-9d71-8f2df4f84a64',
	join: 'db8f18c7-589e-465a-994c-22ad3a442764',
	connectionServices: 'cfd6289b-2c8e-4f12-bb3c-e48669493752',
	addToFavorites: '6b660f44-93a6-491b-bfd7-f2fb3ee9226c',
	socialShare: '5d0446fe-3039-47a2-93c0-d659f482773d',
	conceptType: 'b0d74ab6-8dd6-4ac3-97af-e5d99366141d',
	ticketing: 'd6fcdc63-51b2-4078-86e7-6dcd270af1f5'
});

export const powerUpRepeaters = Object.freeze({
	connections: '739183e5-740c-4684-8c2a-f7bc738b6131',
	memberships: '43065c64-6ea9-4d23-b952-f50784beb2f6',
	connectionServices: 'a9e36ea2-0f2c-44ed-88b4-f40467cc8992',
	entityTypes: 'bbb45923-0beb-49f3-b9ff-61064ac26d02',
	entityEmailTemplates: '184614ec-eb0e-48f3-8371-cec3d0132fff'
});

export const hookNames = Object.freeze({
	// onLogin: 'onLogin', // NOT exposed to users
	entityCreated: 'entityCreated',
	entityUpdated: 'entityUpdated',
	entityDeleted: 'entityDeleted',
	newMessage: 'newMessage',
	newConnectionRequest: 'newConnectionRequest',
	connectionRequestAccepted: 'connectionRequestAccepted',
	connectionRequestRejected: 'connectionRequestRejected',
	connectionRequestCompleted: 'connectionRequestCompleted',
	connectionRequestArranged: 'connectionRequestArranged',
	acceptedOnMembership: 'acceptedOnMembership',
	rejectedOnMembership: 'rejectedOnMembership',
	appliedOnMembership: 'appliedOnMembership',
	returnedOnMembership: 'returnedOnMembership',
	completedOnMembership: 'completedOnMembership',
	bannedOnMembership: 'bannedOnMembership',
	withdrawnOnMembership: 'withdrawnOnMembership',
	newCommentReply: 'newCommentReply',
	broadcastNotificationToList: 'broadcastNotificationToList',
	mention: 'mention',
	newDiscussion: 'newDiscussion',
	newUserCreatedEntity: 'newUserCreatedEntity',
	newApplication: 'newApplication',
	abuseReported: 'abuseReported'
});

export const reactionRepeaters = Object.freeze({
	reaction: '2b800725-bb41-43d1-ac0d-197b6ff6249a',
	showOverallScore: '0ce57706-4cd0-4d32-874e-839d1ce5c745', // points to fields.json which points to dataSchema.json
	multipleReaction: '8870652f-9837-451a-a694-499caced9ce4',
	hideOverallScoreIfNegative: 'd8ac4ce6-3af4-42cf-892a-47c7c443b52e',
	hideIndividualScoreIfNegative: 'f73efdf8-d1bb-4076-baf3-664e222b1c83'
});

export const defaultPages = Object.freeze({
	myAccount: 'e7fbe8a2-6f1e-4788-a20a-a3040882ea67',
	socialSignIn: 'b9b98e21-0a2d-4789-ba97-8ded568ca602'
});

// This list IS NOT designed to be exhaustive
// It is instead meant to be for referring to core fields directly in code
export const fields = Object.freeze({
	appliedOnMembership: '9d118d5d-2053-4da1-b8f8-4c77b544c284',
	acceptedOnMembership: 'e8aaf26b-677a-4fe6-b452-cbd4ed437bef',
	rejectedOnMembership: 'c07b3eb7-8268-4d48-ae5f-df287a87f805',
	bannedOnMembership: '4093738e-9506-473f-8457-f111f6c0ceb6',
	returnedOnMembership: '8af2f02d-847e-4fdf-ab9a-452a1e9918cb',
	completedOnMembership: '871f5eca-6563-4c2e-a564-32f07dc960c0',
	withdrawnOnMembership: '39006591-8c18-4d93-b964-fb3cb89615e2',
	permissionType: 'f9d6d23d-57d6-4c85-8721-e2877776ff13'
});

export const userAssetTypes = Object.freeze({
	ticket: '05b9a227-cba2-4825-9525-3dc8f97885c5'
});

export const orderItemTypes = Object.freeze({
	ticket: '05b9a227-cba2-4825-9525-3dc8f97885c5'
});

export const ticketTypes = Object.freeze({
	ticketTailor: 'ticketTailor'
});

export const tiptapToolbars = Object.freeze({
	min: Object.freeze([
		'bold',
		'italic',
		'underline',
		'separator',
		EmojiSelector,
		'separator',
		'undo',
		'redo'
	]),
	minExtended: Object.freeze([
		'bold',
		'italic',
		'underline',
		'separator',
		'fore-color',
		'back-color',
		'separator',
		ImageUploaderBtn,
		'separator',
		EmojiSelector,
		'separator',
		'undo',
		'redo'
	]),
	code: Utils.deepFreeze([
		'bold',
		'italic',
		{
			name: 'text-format-dropdown',
			type: 'menu',
			options: {
				list: [
					'underline',
					'strike'
				]
			}
		},
		'separator',
		'undo',
		'redo'
	]),
	markdown: Object.freeze([
		'bold',
		'italic',
		'underline',
		'separator',
		'bullet_list',
		'ordered_list',
		ImageUploaderBtn,
		'separator',
		EmojiSelector,
		'separator',
		'table',
		'code',
		'photo',
		'separator',
		'undo',
		'redo'
	]),
	markdownWithoutImages: Object.freeze([
		'bold',
		'italic',
		'underline',
		'separator',
		'bullet_list',
		'ordered_list',
		'separator',
		EmojiSelector,
		'separator',
		'table',
		'code',
		'separator',
		'undo',
		'redo'
	]),
	basic: Object.freeze([
		'bold',
		'italic',
		Utils.deepFreeze({
			name: 'text-format-dropdown',
			type: 'menu',
			options: {
				list: [
					'underline',
					'strike',
					'code'
				]
			}
		}),
		'separator',
		'fore-color',
		'back-color',
		'separator',
		'align-dropdown',
		'indent-dropdown',
		'separator',
		'list-dropdown',
		'separator',
		'table',
		ImageUploaderBtn,
		'separator',
		EmojiSelector,
		'separator',
		'undo',
		'redo'
	]),
	templateEditor: Object.freeze([
		'bold',
		'italic',
		Utils.deepFreeze({
			name: 'text-format-dropdown',
			type: 'menu',
			options: {
				list: [
					'underline',
					'strike',
					'code'
				]
			}
		}),
		'separator',
		'heading',
		'fore-color',
		'back-color',
		'separator',
		'align-dropdown',
		'indent-dropdown',
		'separator',
		'list-dropdown',
		'separator',
		'table',
		'separator',
		EmojiSelector,
		'separator',
		ImageUploaderBtn,
		'separator',
		'undo',
		'redo'
	]),
	full: Object.freeze([
		'add-more',
		'separator',
		'bold',
		'italic',
		Utils.deepFreeze({
			name: 'text-format-dropdown',
			type: 'menu',
			options: {
				list: [
					'underline',
					'strike',
					'code'
				]
			}
		}),
		'separator',
		'heading',
		'font-family',
		'fore-color',
		'back-color',
		'format_clear',
		'separator',
		'align-dropdown',
		'indent',
		'outdent',
		'line-height',
		'separator',
		'horizontal',
		'bullet_list',
		'ordered_list',
		'todo_list',
		'separator',
		'blockquote',
		'code_block',
		'link',
		'table',
		ImageUploaderBtn,
		'separator',
		'print',
		'separator',
		EmojiSelector,
		'separator',
		'undo',
		'redo'
	])
});

export const tiptapBubbles = Utils.deepFreeze({
	min: [
		'bold',
		'italic',
		'link'
	],
	basic: [
		'bold',
		'italic',
		'separator',
		'heading',
		'font-family',
		'fore-color',
		'back-color',
		'separator',
		'link',
		'separator',
		'align-group'
	]
});

export const integrationSettingsConstants = Utils.deepFreeze({
	ticketTailor: {
		label: 'Ticket Tailor',
		urls: {
			webhook: 'https://app.tickettailor.com/box-office/webhooks'
		},
		webhooksAvailable: ['ORDER.CREATED', 'ORDER.UPDATED', 'EVENT.CREATED', 'EVENT.UPDATED', 'EVENT.DELETED', 'ISSUED_TICKET.CREATED', 'ISSUED_TICKET.UPDATED']
	},
	firebase: {
		label: 'Firebase'
	}
});

export const listMembershipStates = Object.freeze({
	accepted: 'accepted',
	applied: 'applied',
	banned: 'banned',
	completed: 'completed',
	rejected: 'rejected',
	returned: 'returned',
	withdrawn: 'withdrawn'
});

export const listMembershipStatesList = Object.values(listMembershipStates);

export const notificationTypes = Object.freeze({
	2: 'EntityApplication',
	3: 'AdminNotification',
	4: 'EntityNotification',
	5: 'Mention',
	6: 'NewDiscussion',
	7: 'NewCommentReply',
	8: 'ReportAbuse',
	9: 'UserCommunicationAction',
	10: 'Connection',
	14: 'Order',
	15: 'UserAsset',
	16: 'EntityNotificationStatus'
});

export const AdminNotificationTypes = Object.freeze({
	memberRequest: 1,
	newEntityCreated: 2,
	newReportAbuseCreated: 3,
	exportStatus: 4,
	profileUploadStatus: 5,
	userIndexing: 6,
	createAccounts: 7,
	notificationPreferenceFixes: 8
});

export const AdminNotificationStatus = Object.freeze({
	started: 'started',
	running: 'running',
	finishing: 'finishing',
	complete: 'complete',
	failed: 'failed'
});

export const UserDataUploadState = Object.freeze({
	started: 'started',
	running: 'running',
	complete: 'complete'
});

export const UserVerificationUploadState = Object.freeze({
	started: 'started',
	running: 'running',
	complete: 'complete',
	errored: 'errored'
});

export const fieldMapping = Utils.deepFreeze([
	{ name: 'RepeaterEdit', aliases: ['repeater'] },
	{ name: 'TextInput', aliases: ['text', 'textarea', 'hidden', 'email', 'number', 'tel', 'password', 'search', 'month', 'year'] },
	{ name: 'I18NInput', aliases: ['i18n'] },
	{ name: 'DataSchemaSelector', aliases: ['dataSchemaSelector'] },
	{ name: 'ColorPicker', aliases: ['colorPicker'] },
	// { name: 'DurationOptions', aliases: ['durationsPicker'] }, add back in when "Durations" is done
	{ name: 'iconPicker', aliases: ['icon'] },
	{ name: 'MembershipTagsSelector', aliases: ['membershipTagsSelector', 'membershipTags'] }
]);

// this is a simplified `routeConfig` from `router/index.js` of all sub-routes for easy use in old Admin Visibility Permissions (the toggles in User List page) to determine if someone should see a page (and its sub-pages)
export const adminDashboardSubPaths = Object.freeze({
	listDetail: 'userLists',
	listEdit: 'userLists',
	editApplication: 'applications',
	listJoinRequests: 'applications',
	listJoinRequest: 'applications',
	membershipRequest: 'membershipRequests',
	editModuleLayout: 'layouts',
	editPageLayout: 'layouts',
	editEntity: 'entities',
	editEntityDefinition: 'entities',
	editBlock: 'blocks',
	viewConnection: 'connections',
	usersLists: 'users',
	// editUser: 'users',
	editEmail: 'emailTemplateBuilder',
	editMapping: 'dataMappings',
	editReportAbuse: 'reportAbuse',
	insightsDashboard: 'insights'
});

// App related defaults
export const appDefaults = Object.freeze({
	bgColor: '#16172d',
	themeColor: '#16347d',
	startUrl: '/',
	display: 'standalone'
});

export const defaultAdminPagePermissionType = 'viewDashboardPage';

/** Admin Dashboard pages to permissions mapping.
 * List all the paths that can be used to determine if a page should be displayed to the admin - if ANY of them have permission, the page is shown; all paths will be shown in the "Permissions" admin panel on the page. Default action checked for each page is in `defaultAdminPagePermissionType`
 *
 * The main paths that display the tabs on Admin Dashboard should only go off of the 'viewDashboardPage' permission. Otherwise, it's going to cause a lot of confusion.
 *
 * Refer to aluminate-vue/src/router/index.js::children paths in "/admin" for all the paths on Admin Dashboard. "name" is the key here. You'll need to figure out what permissions the page would need on your own
 * @param `path` [required] The path in aluminate-api/src/models/PermissionsManager/permissionAreas.json in dot notation
 * @param `targetId` [optional] Set to indicate which field in $route.params will be used for `subjectId`. Didn't name it as `subjectId` to lessen the confusion it might create as this holds the variable name and not the id value
 * @param `action` [optional] Set if it differs from the default in `defaultAdminPagePermissionType`
 *
 * IMPORTANT:
 * 1. When you get an error saying "You do not have permissions to view this page - undefined" then you're missing the page to permissions mapping here
 * 2. If you have a setup here, and you're on a full-access account, and get an error saying "You do not have permissions to view this page - [page name]" then you're likely missing the setup in `permissionsAreas.json`.
 */
export const adminDashboardPermissionPaths = Utils.deepFreeze({
	dashboard: [
		{ path: 'items.applications' },
		{ path: 'abuseReports' }
	], // Admin Dashboard tab
	insights: [{ path: 'insights' }],
	insights2: [{ path: 'insights2' }],
	insightsDashboard: [{ path: 'insights' }],
	blocks: [{ path: 'items.blocks' }],
	editBlock: [{ path: 'items.blocks', targetId: 'blockId', action: 'update', partOf: 'blocks' }],
	notifications: [{ path: 'notifications' }],
	broadcasts: [{ path: 'notifications', action: 'broadcast' }],
	notificationHistory: [{ path: 'notifications' }],
	// exports: [{ path: 'profiles' }],
	users: [{ path: 'profiles' }],
	usersLists: [{ path: 'profiles', targetId: 'accountId', action: 'accessUsersLists' }],
	editUser: [{ path: 'profiles', targetId: 'accountId', action: 'update' }],
	userLists: [{ path: 'userLists' }],
	listDetail: [{ path: 'userLists', targetId: 'listId', action: 'accessUserData' }],
	listEdit: [{ path: 'userLists', targetId: 'listId', action: 'update' }],
	settings: [{ path: 'settings' }],
	applications: [{ path: 'items.applications' }],
	editApplication: [{ path: 'items.applications', targetId: 'applicationId', action: 'update' }],
	listJoinRequests: [{ path: 'items.applications', targetId: 'applicationId', action: 'update' }],
	listJoinRequest: [{ path: 'items.applications', targetId: 'requestId', action: 'update' }], // TODO figure out if the request is different from an application...
	entities: [{ path: 'items.entityDefinitions' }], // we'll use the same one for powerUps and entityDefinitions too // Admin Dashboard tab
	viewEntities: [{ path: 'items.entityDefinitions', action: 'read', targetId: 'definitionId' }],
	editEntity: [
		{ path: 'items.entityDefinitions', action: 'createEntities', targetId: 'definitionId' },
		{ path: 'items.entityDefinitions', action: 'updateEntities', targetId: 'definitionId' },
		{ path: 'items.entities', action: 'update', targetId: 'entityId' }
	],
	newEntity: [{ path: 'items.entityDefinitions', action: 'createEntities', targetId: 'definitionId' }],
	editEntityDefinition: [
		{ path: 'items.entityDefinitions', targetId: 'definitionId', action: 'create' },
		{ path: 'items.entityDefinitions', targetId: 'definitionId', action: 'update' }
	],
	membershipRequests: [{ path: 'items.applications', targetId: 'applicationId' }],
	membershipRequest: [{ path: 'items.applications', targetId: 'requestId' }],
	layouts: [{ path: 'items.modules' }],
	editModuleLayout: [{ path: 'items.modules', targetId: 'moduleId', action: 'addChildren' }],
	editPageLayout: [{ path: 'items.pages', targetId: 'pageId', action: 'addChildren' }],
	emailTemplateBuilder: [{ path: 'items.templates' }],
	editEmail: [{ path: 'items.templates', targetId: 'emailTemplate' }],
	dataMappings: [{ path: 'items.dataMappings' }],
	editMapping: [{ path: 'items.dataMappings', targetId: 'id', action: 'update' }],
	reportAbuse: [{ path: 'abuseReports' }],
	editReportAbuse: [{ path: 'abuseReports', targetId: 'id' }],
	connections: [{ path: 'connections' }],
	viewConnection: [{ path: 'connections', targetId: 'connectionId' }],
	tools: [{ path: 'tools' }],
	searchManagement: [{ path: 'searchManagement' }]
});

export const I18NLocations = Utils.deepFreeze({
	applications: {
		pre: 'custom.applications.',
		post: '.label'
	},
	blocks: {
		pre: 'custom.blocks.',
		post: '.title'
	},
	connections: {
		pre: 'custom.connections.',
		post: '.label'
	},
	dataMappings: {
		pre: 'custom.dataMappings.',
		post: '.title'
	},
	fields: {
		pre: 'custom.fields.',
		post: '.label'
	},
	modules: {
		pre: 'custom.modules.',
		post: ''
	},
	pages: {
		pre: 'custom.pages.',
		post: ''
	},
	primaryMembership: {
		pre: 'custom.membershipType.',
		post: '.primaryListWord'
	},
	searchFilters: {
		pre: 'custom.search.filters.',
		post: ''
	},
	templates: {
		pre: 'custom.templates.',
		post: '.title'
	},
	userLists: {
		pre: 'custom.userLists.',
		post: '.label'
	}
});

export const calendars = Utils.deepFreeze({
	google: {
		url: 'https://www.google.com/calendar/render?action=TEMPLATE',
		regex: /-|:|\\.\\d+/g,
		parameters(title, location, details, start, end)
		{
			const parameters = {
				text: title,
				location,
				details
			};

			if(start && end)
			{
				// Why 2F? ¯\_(ツ)_/¯ Could not find any documentation but doesn't work without it
				parameters.dates = `${start}%2F${end}`;
			}

			return parameters;
		}
	},
	microsoft: {
		url: 'https://outlook.live.com/owa/?rru=addevent',
		regex: /||\\.\\d+/g,
		parameters(title, location, details, start, end)
		{
			return {
				subject: title,
				location,
				body: details,
				startdt: start,
				enddt: end
			};
		}
	},
	office365: {
		url: 'https://outlook.office.com/owa/?path=/calendar/action/compose&rru=addevent',
		regex: /||\\.\\d+/g,
		parameters(title, location, details, start, end)
		{
			return {
				subject: title,
				location,
				body: details,
				startdt: start,
				enddt: end
			};
		}
	}
});

export const assetTypes = Utils.deepFreeze([
	{
		type: 'image',
		icon: 'fad fa-image',
		component: {
			edit: 'ImageAssetUploader',
			upload: 'ImageAssetUploader'
		}
	}
]);

export const basicAssetFolders = Object.freeze({
	root: 1
});

export const ssiRegisteredProviders = Utils.deepFreeze({
	linkedIn: {
		method: 'manual',
		icon: 'fab fa-linkedin-in',
		color: '#0077B5'
	},
	facebook: {
		method: 'manual',
		icon: 'fab fa-facebook',
		color: '#4267B2'
	},
	google: {
		method: 'manual',
		imageURL: `${devMode ? '' : '/app'}/images/ssi/ssi-google-normal.svg`
	},
	yahoo: {
		method: 'manual',
		icon: 'fab fa-yahoo',
		color: '#720e9e'
	}
});

export const defaultItemSubscriptionOptions = Utils.deepFreeze({
	block: {
		type: 'discussion'
	},
	concepts: {
		type: 'concepts',
		preferenceType: 'items'
	}
});

export const cPageWidthOptions = Object.freeze({
	// default
	fixed: 'fixed',
	// full-width
	fluid: 'fluid'
});

export const fieldDefaultIcons = Utils.deepFreeze({
	default: {
		fallback: 'fad fa-circle',
		text: 'fad fa-text-size',
		textarea: 'fad fa-align-left',
		options: 'fad fa-chevron-square-down',
		radio: 'fad fa-check-circle',
		toggle: 'fad fa-toggle-on',
		email: 'fad fa-at',
		csv: 'fad fa-list-ul',
		group: 'fad fa-object-group',
		checkbox: 'fad fa-tasks',
		heading: 'fad fa-heading',
		structureSelector: 'fad fa-ballot-check',
		paragraph: 'fad fa-paragraph',
		imageUploader: 'fad fa-file-upload',
		number: 'fad fa-sort-numeric-down',
		repeater: 'fad fa-repeat-alt',
		geoLocation: 'fad fa-map-marked-alt',
		date: 'fad fa-calendar-day',
		year: 'fad fa-calendar-alt',
		files: 'fad fa-file-upload'
	},
	// core_firstName
	'197363ad-e047-4634-8f4a-c86433acd8e4': 'fad fa-user',
	// core_dateOfBirth
	'87c13bba-234b-4729-881f-25046154b3aa': 'fad fa-birthday-cake',
	// core_sex
	'f7aea541-c298-4282-bf9e-58dfb551a09e': 'fad fa-venus-mars',
	// core_nationality
	'd8599022-ec36-4c0c-b299-f4ad2ba1c80e': 'fad fa-flag',
	// core_educations.course
	'f4908a63-7244-4f10-b418-607800c43cca': 'fad fa-graduation-cap',
	// core_educations.qualification
	'f7c6221c-8627-4567-ac4a-c757b5f68ba1': 'fad fa-diploma',
	// core_educations.yearEnded
	'79651769-bdd2-44bb-8ee3-93048f225fd5': 'fad fa-calendar-alt',
	// core_educations.yearStarted
	'5278f19a-8a9b-46d9-95b3-ce604b12a0e5': 'fad fa-calendar-alt',
	// core_educations.faculty
	'283fe7e9-6c36-4db5-b580-26daef9ec06a': 'fad fa-chalkboard-teacher',
	// core_educations.city
	'dbf18d71-a546-4add-99c3-8d3f97738868': 'fad fa-map-marker',
	// core_educations.country
	'a9d3e37e-cdd2-410f-bf27-db109388d75c': 'fad fa-globe-europe',
	// core_educations.institution
	'ecd3ef0c-160b-4dec-94a2-6417f671cde7': 'fad fa-graduation-cap',
	// core_otherEducations.institution
	'725b938c-daa3-444f-92fc-8e1484e89121': 'fad fa-graduation-cap',
	// core_otherEducations.yearStarted
	'866451e9-520b-4bb1-90d9-15dd8c704630': 'fad fa-calendar-alt',
	// core_otherEducations.yearEnded
	'd0529299-2160-44d2-bd14-eda39fe9ee12': 'fad fa-calendar-alt',
	// core_otherEducations.city
	'78aa1c5d-b2d1-4439-b29d-c54bd11a35fb': 'fad fa-map-marker',
	// core_employments.industry
	'a3fcd74f-e75e-4974-9514-610e7a1a4d35': 'fad fa-industry-alt',
	// core_employments.type
	'af0d60bd-5ff7-4be1-87bf-dd87742fd716': 'fad fa-calendar-star',
	// core_employments.city
	'5f1b3927-8143-4ba2-b238-1baa0b1ff834': 'fad fa-map-marker',
	// core_employments.department
	'ab6c0b5a-0c5a-4df0-9567-dc6a8a8b17de': 'fad fa-building',
	// core_employments.dateStarted
	'950f82ef-b3d2-4ed4-9db7-7b64fa104d1f': 'fad fa-calendar-alt',
	// core_employments.dateEnded
	'670c9e21-ebab-42cd-a873-7a41e33a6879': 'fad fa-calendar-alt',
	// core_employments.website
	'148e415c-719b-4c3a-967b-9fc1cf157b85': 'fad fa-desktop',
	// core_employments.country
	'3938c3cf-3446-42f1-bc39-630abe052164': 'fad fa-globe-europe',
	// core_employments.description
	'3dedf5d5-9399-4e87-82c3-621dc59f710e': 'fad fa-file-alt',
	// core_otherEducations.course
	'06226f14-fb8a-4ff6-a7be-d54d03f90992': 'fad fa-graduation-cap',
	// core_otherEducations.qualification
	'eda7ad3d-359a-4b05-a70d-e45715395047': 'fad fa-diploma',
	// core_otherEducations.country
	'f6dcbd7d-0d66-42b3-833e-653c26e0de4b': 'fad fa-globe-europe',
	// core_bio
	'65cd5d88-0f10-4255-a43a-8f79d817173b': 'fad fa-bell',
	// core_formerName
	'6a4436f2-313e-4cf3-b433-86ffdf0503d1': 'fad fa-user',
	// core_homeAddressLine1
	'2cb547ad-caf5-4a82-8f86-f2216f240ea7': 'fad fa-map-marked-alt',
	// core_homeAddressLine2
	'e948a279-8ba2-4fb2-8364-f45e90e7d066': 'fad fa-map-marked-alt',
	// core_homePostcode
	'72c54a2b-dda6-4fec-8882-fb319ccf3ee2': 'fad fa-mailbox',
	// core_homeState
	'2527281f-36e5-4f33-adb4-e47c5440e01b': 'fad fa-mailbox',
	// core_homeCity
	'f442866b-8e81-4ca0-a356-6bbac3aabfd1': 'fad fa-map-marker',
	// core_homeCounty
	'b7b299e7-9934-4927-8515-d9daeab73b8f': 'fad fa-flag',
	// core_homeCountry
	'6ae0da69-403f-4b11-b258-dbe46e483d9e': 'fad fa-globe-europe',
	// core_mentorIdealConnection
	'be8d5142-8003-4177-a85f-569ae2f6f96d': 'fad fa-hands-helping',
	// core_mentorSummary
	'd4157a82-192d-4752-9a76-8b302dbff4f4': 'fad fa-clipboard-list',
	// core_mentorProfessionalExperienceYears
	'a66df72f-f790-456e-9ac4-34f35f5b7514': 'fad fa-calendar-check',
	// core_classOf
	'9f900172-2039-49d6-bc4c-236f5a1388a7': 'fad fa-graduation-cap',
	// core_ambassadorSummary
	'7b268d13-a9b4-4352-b1fc-21313a567382': 'fad fa-clipboard-list',
	// core_ambassadorIdealConnection
	'eb662459-0dd9-4648-a57d-c3ae1f9cf38e': 'fad fa-hands-helping'
});

// Default emoji list
export const emojiList = Utils.deepFreeze([
	{ data: '😀', category: 'Peoples', aliases: ['grinning'] },
	{ data: '😃', category: 'Peoples', aliases: ['smiley'] },
	{ data: '😄', category: 'Peoples', aliases: ['smile'] },
	{ data: '😁', category: 'Peoples', aliases: ['grin'] },
	{ data: '😆', category: 'Peoples', aliases: ['laughing', 'satisfied'] },
	{ data: '😅', category: 'Peoples', aliases: ['sweat_smile'] },
	{ data: '😂', category: 'Peoples', aliases: ['joy'] },
	{ data: '🤣', category: 'Peoples', aliases: ['rofl'] },
	{ data: '😌', category: 'Peoples', aliases: ['relaxed'] },
	{ data: '😊', category: 'Peoples', aliases: ['blush'] },
	{ data: '😇', category: 'Peoples', aliases: ['innocent'] },
	{ data: '🙂', category: 'Peoples', aliases: ['slightly_smiling_face'] },
	{ data: '🙃', category: 'Peoples', aliases: ['upside_down_face'] },
	{ data: '😉', category: 'Peoples', aliases: ['wink'] },
	{ data: '😌', category: 'Peoples', aliases: ['relieved'] },
	{ data: '😍', category: 'Peoples', aliases: ['heart_eyes'] },
	{ data: '😘', category: 'Peoples', aliases: ['kissing_heart'] },
	{ data: '😗', category: 'Peoples', aliases: ['kissing'] },
	{ data: '😙', category: 'Peoples', aliases: ['kissing_smiling_eyes'] },
	{ data: '😚', category: 'Peoples', aliases: ['kissing_closed_eyes'] },
	{ data: '😋', category: 'Peoples', aliases: ['yum'] },
	{ data: '😜', category: 'Peoples', aliases: ['stuck_out_tongue_winking_eye'] },
	{ data: '😝', category: 'Peoples', aliases: ['stuck_out_tongue_closed_eyes'] },
	{ data: '😛', category: 'Peoples', aliases: ['stuck_out_tongue'] },
	{ data: '🤑', category: 'Peoples', aliases: ['money_mouth_face'] },
	{ data: '🤗', category: 'Peoples', aliases: ['hugs'] },
	{ data: '🤓', category: 'Peoples', aliases: ['nerd_face'] },
	{ data: '😎', category: 'Peoples', aliases: ['sunglasses'] },
	{ data: '🤡', category: 'Peoples', aliases: ['clown_face'] },
	{ data: '🤠', category: 'Peoples', aliases: ['cowboy_hat_face'] },
	{ data: '😏', category: 'Peoples', aliases: ['smirk'] },
	{ data: '😒', category: 'Peoples', aliases: ['unamused'] },
	{ data: '😞', category: 'Peoples', aliases: ['disappointed'] },
	{ data: '😔', category: 'Peoples', aliases: ['pensive'] },
	{ data: '😟', category: 'Peoples', aliases: ['worried'] },
	{ data: '😕', category: 'Peoples', aliases: ['confused'] },
	{ data: '🙁', category: 'Peoples', aliases: ['slightly_frowning_face'] },
	{ data: '☹️', category: 'Peoples', aliases: ['frowning_face'] },
	{ data: '😣', category: 'Peoples', aliases: ['persevere'] },
	{ data: '😖', category: 'Peoples', aliases: ['confounded'] },
	{ data: '😫', category: 'Peoples', aliases: ['tired_face'] },
	{ data: '😩', category: 'Peoples', aliases: ['weary'] },
	{ data: '😤', category: 'Peoples', aliases: ['triumph'] },
	{ data: '😠', category: 'Peoples', aliases: ['angry'] },
	{ data: '😡', category: 'Peoples', aliases: ['rage', 'pout'] },
	{ data: '😶', category: 'Peoples', aliases: ['no_mouth'] },
	{ data: '😐', category: 'Peoples', aliases: ['neutral_face'] },
	{ data: '😑', category: 'Peoples', aliases: ['expressionless'] },
	{ data: '😯', category: 'Peoples', aliases: ['hushed'] },
	{ data: '😦', category: 'Peoples', aliases: ['frowning'] },
	{ data: '😧', category: 'Peoples', aliases: ['anguished'] },
	{ data: '😮', category: 'Peoples', aliases: ['open_mouth'] },
	{ data: '😲', category: 'Peoples', aliases: ['astonished'] },
	{ data: '😵', category: 'Peoples', aliases: ['dizzy_face'] },
	{ data: '😳', category: 'Peoples', aliases: ['flushed'] },
	{ data: '😱', category: 'Peoples', aliases: ['scream'] },
	{ data: '😨', category: 'Peoples', aliases: ['fearful'] },
	{ data: '😰', category: 'Peoples', aliases: ['cold_sweat'] },
	{ data: '😢', category: 'Peoples', aliases: ['cry'] },
	{ data: '😥', category: 'Peoples', aliases: ['disappointed_relieved'] },
	{ data: '🤤', category: 'Peoples', aliases: ['drooling_face'] },
	{ data: '😭', category: 'Peoples', aliases: ['sob'] },
	{ data: '😓', category: 'Peoples', aliases: ['sweat'] },
	{ data: '😪', category: 'Peoples', aliases: ['sleepy'] },
	{ data: '😴', category: 'Peoples', aliases: ['sleeping'] },
	{ data: '🙄', category: 'Peoples', aliases: ['roll_eyes'] },
	{ data: '🤔', category: 'Peoples', aliases: ['thinking'] },
	{ data: '🤥', category: 'Peoples', aliases: ['lying_face'] },
	{ data: '😬', category: 'Peoples', aliases: ['grimacing'] },
	{ data: '🤐', category: 'Peoples', aliases: ['zipper_mouth_face'] },
	{ data: '🤢', category: 'Peoples', aliases: ['nauseated_face'] },
	{ data: '🤧', category: 'Peoples', aliases: ['sneezing_face'] },
	{ data: '😷', category: 'Peoples', aliases: ['mask'] },
	{ data: '🤒', category: 'Peoples', aliases: ['face_with_thermometer'] },
	{ data: '🤕', category: 'Peoples', aliases: ['face_with_head_bandage'] },
	{ data: '😈', category: 'Peoples', aliases: ['smiling_imp'] },
	{ data: '👿', category: 'Peoples', aliases: ['imp'] },
	{ data: '👹', category: 'Peoples', aliases: ['japanese_ogre'] },
	{ data: '👺', category: 'Peoples', aliases: ['japanese_goblin'] },
	{ data: '💩', category: 'Peoples', aliases: ['hankey', 'poop', 'shit'] },
	{ data: '👻', category: 'Peoples', aliases: ['ghost'] },
	{ data: '💀', category: 'Peoples', aliases: ['skull'] },
	{ data: '☠️', category: 'Peoples', aliases: ['skull_and_crossbones'] },
	{ data: '👽', category: 'Peoples', aliases: ['alien'] },
	{ data: '👾', category: 'Peoples', aliases: ['space_invader'] },
	{ data: '🤖', category: 'Peoples', aliases: ['robot'] },
	{ data: '🎃', category: 'Peoples', aliases: ['jack_o_lantern'] },
	{ data: '😺', category: 'Peoples', aliases: ['smiley_cat'] },
	{ data: '😸', category: 'Peoples', aliases: ['smile_cat'] },
	{ data: '😹', category: 'Peoples', aliases: ['joy_cat'] },
	{ data: '😻', category: 'Peoples', aliases: ['heart_eyes_cat'] },
	{ data: '😼', category: 'Peoples', aliases: ['smirk_cat'] },
	{ data: '😽', category: 'Peoples', aliases: ['kissing_cat'] },
	{ data: '🙀', category: 'Peoples', aliases: ['scream_cat'] },
	{ data: '😿', category: 'Peoples', aliases: ['crying_cat_face'] },
	{ data: '😾', category: 'Peoples', aliases: ['pouting_cat'] },
	{ data: '👐', category: 'Peoples', aliases: ['open_hands'] },
	{ data: '🙌', category: 'Peoples', aliases: ['raised_hands'] },
	{ data: '👏', category: 'Peoples', aliases: ['clap'] },
	{ data: '🙏', category: 'Peoples', aliases: ['pray'] },
	{ data: '🤝', category: 'Peoples', aliases: ['handshake'] },
	{ data: '👍', category: 'Peoples', aliases: ['+1', 'thumbsup'] },
	{ data: '👎', category: 'Peoples', aliases: ['-1', 'thumbsdown'] },
	{ data: '👊', category: 'Peoples', aliases: ['fist_oncoming', 'facepunch', 'punch'] },
	{ data: '✊', category: 'Peoples', aliases: ['fist_raised', 'fist'] },
	{ data: '🤛', category: 'Peoples', aliases: ['fist_left'] },
	{ data: '🤜', category: 'Peoples', aliases: ['fist_right'] },
	{ data: '🤞', category: 'Peoples', aliases: ['crossed_fingers'] },
	{ data: '✌️', category: 'Peoples', aliases: ['v'] },
	{ data: '🤘', category: 'Peoples', aliases: ['metal'] },
	{ data: '👌', category: 'Peoples', aliases: ['ok_hand'] },
	{ data: '👈', category: 'Peoples', aliases: ['point_left'] },
	{ data: '👉', category: 'Peoples', aliases: ['point_right'] },
	{ data: '👆', category: 'Peoples', aliases: ['point_up_2'] },
	{ data: '👇', category: 'Peoples', aliases: ['point_down'] },
	{ data: '☝️', category: 'Peoples', aliases: ['point_up'] },
	{ data: '✋', category: 'Peoples', aliases: ['hand', 'raised_hand'] },
	{ data: '🤚', category: 'Peoples', aliases: ['raised_back_of_hand'] },
	{ data: '🖐', category: 'Peoples', aliases: ['raised_hand_with_fingers_splayed'] },
	{ data: '🖖', category: 'Peoples', aliases: ['vulcan_salute'] },
	{ data: '👋', category: 'Peoples', aliases: ['wave'] },
	{ data: '🤙', category: 'Peoples', aliases: ['call_me_hand'] },
	{ data: '💪', category: 'Peoples', aliases: ['muscle'] },
	{ data: '🖕', category: 'Peoples', aliases: ['middle_finger', 'fu'] },
	{ data: '✍️', category: 'Peoples', aliases: ['writing_hand'] },
	{ data: '🤳', category: 'Peoples', aliases: ['selfie'] },
	{ data: '💅', category: 'Peoples', aliases: ['nail_care'] },
	{ data: '💍', category: 'Peoples', aliases: ['ring'] },
	{ data: '💄', category: 'Peoples', aliases: ['lipstick'] },
	{ data: '💋', category: 'Peoples', aliases: ['kiss'] },
	{ data: '👄', category: 'Peoples', aliases: ['lips'] },
	{ data: '👅', category: 'Peoples', aliases: ['tongue'] },
	{ data: '👂', category: 'Peoples', aliases: ['ear'] },
	{ data: '👃', category: 'Peoples', aliases: ['nose'] },
	{ data: '👣', category: 'Peoples', aliases: ['footprints'] },
	{ data: '👁', category: 'Peoples', aliases: ['eye'] },
	{ data: '👀', category: 'Peoples', aliases: ['eyes'] },
	{ data: '🗣', category: 'Peoples', aliases: ['speaking_head'] },
	{ data: '👤', category: 'Peoples', aliases: ['bust_in_silhouette'] },
	{ data: '👥', category: 'Peoples', aliases: ['busts_in_silhouette'] },
	{ data: '👶', category: 'Peoples', aliases: ['baby'] },
	{ data: '👦', category: 'Peoples', aliases: ['boy'] },
	{ data: '👧', category: 'Peoples', aliases: ['girl'] },
	{ data: '👨', category: 'Peoples', aliases: ['man'] },
	{ data: '👩', category: 'Peoples', aliases: ['woman'] },
	{ data: '👱‍♀', category: 'Peoples', aliases: ['blonde_woman'] },
	{ data: '👱', category: 'Peoples', aliases: ['blonde_man', 'person_with_blond_hair'] },
	{ data: '👴', category: 'Peoples', aliases: ['older_man'] },
	{ data: '👵', category: 'Peoples', aliases: ['older_woman'] },
	{ data: '👲', category: 'Peoples', aliases: ['man_with_gua_pi_mao'] },
	{ data: '👳‍♀', category: 'Peoples', aliases: ['woman_with_turban'] },
	{ data: '👳', category: 'Peoples', aliases: ['man_with_turban'] },
	{ data: '👮‍♀', category: 'Peoples', aliases: ['policewoman'] },
	{ data: '👮', category: 'Peoples', aliases: ['policeman', 'cop'] },
	{ data: '👷‍♀', category: 'Peoples', aliases: ['construction_worker_woman'] },
	{ data: '👷', category: 'Peoples', aliases: ['construction_worker_man', 'construction_worker'] },
	{ data: '💂‍♀', category: 'Peoples', aliases: ['guardswoman'] },
	{ data: '💂', category: 'Peoples', aliases: ['guardsman'] },
	{ data: '👩‍⚕', category: 'Peoples', aliases: ['woman_health_worker'] },
	{ data: '👨‍⚕', category: 'Peoples', aliases: ['man_health_worker'] },
	{ data: '👩‍🌾', category: 'Peoples', aliases: ['woman_farmer'] },
	{ data: '👨‍🌾', category: 'Peoples', aliases: ['man_farmer'] },
	{ data: '👩‍🍳', category: 'Peoples', aliases: ['woman_cook'] },
	{ data: '👨‍🍳', category: 'Peoples', aliases: ['man_cook'] },
	{ data: '👩‍🎓', category: 'Peoples', aliases: ['woman_student'] },
	{ data: '👨‍🎓', category: 'Peoples', aliases: ['man_student'] },
	{ data: '👩‍🎤', category: 'Peoples', aliases: ['woman_singer'] },
	{ data: '👨‍🎤', category: 'Peoples', aliases: ['man_singer'] },
	{ data: '👩‍🏫', category: 'Peoples', aliases: ['woman_teacher'] },
	{ data: '👨‍🏫', category: 'Peoples', aliases: ['man_teacher'] },
	{ data: '👩‍🏭', category: 'Peoples', aliases: ['woman_factory_worker'] },
	{ data: '👨‍🏭', category: 'Peoples', aliases: ['man_factory_worker'] },
	{ data: '👩‍💻', category: 'Peoples', aliases: ['woman_technologist'] },
	{ data: '👨‍💻', category: 'Peoples', aliases: ['man_technologist'] },
	{ data: '👩‍💼', category: 'Peoples', aliases: ['woman_office_worker'] },
	{ data: '👨‍💼', category: 'Peoples', aliases: ['man_office_worker'] },
	{ data: '👩‍🔧', category: 'Peoples', aliases: ['woman_mechanic'] },
	{ data: '👨‍🔧', category: 'Peoples', aliases: ['man_mechanic'] },
	{ data: '👩‍🔬', category: 'Peoples', aliases: ['woman_scientist'] },
	{ data: '👨‍🔬', category: 'Peoples', aliases: ['man_scientist'] },
	{ data: '👩‍🎨', category: 'Peoples', aliases: ['woman_artist'] },
	{ data: '👨‍🎨', category: 'Peoples', aliases: ['man_artist'] },
	{ data: '👩‍🚒', category: 'Peoples', aliases: ['woman_firefighter'] },
	{ data: '👨‍🚒', category: 'Peoples', aliases: ['man_firefighter'] },
	{ data: '👩‍🚀', category: 'Peoples', aliases: ['woman_astronaut'] },
	{ data: '👨‍🚀', category: 'Peoples', aliases: ['man_astronaut'] },
	{ data: '🤶', category: 'Peoples', aliases: ['mrs_claus'] },
	{ data: '🎅', category: 'Peoples', aliases: ['santa'] },
	{ data: '👸', category: 'Peoples', aliases: ['princess'] },
	{ data: '🤴', category: 'Peoples', aliases: ['prince'] },
	{ data: '👰', category: 'Peoples', aliases: ['bride_with_veil'] },
	{ data: '🤵', category: 'Peoples', aliases: ['man_in_tuxedo'] },
	{ data: '👼', category: 'Peoples', aliases: ['angel'] },
	{ data: '🤰', category: 'Peoples', aliases: ['pregnant_woman'] },
	{ data: '🙇‍♀', category: 'Peoples', aliases: ['bowing_woman'] },
	{ data: '🙇', category: 'Peoples', aliases: ['bowing_man', 'bow'] },
	{ data: '💁', category: 'Peoples', aliases: ['tipping_hand_woman', 'information_desk_person', 'sassy_woman'] },
	{ data: '💁‍♂', category: 'Peoples', aliases: ['tipping_hand_man', 'sassy_man'] },
	{ data: '🙅', category: 'Peoples', aliases: ['no_good_woman', 'no_good', 'ng_woman'] },
	{ data: '🙅‍♂', category: 'Peoples', aliases: ['no_good_man', 'ng_man'] },
	{ data: '🙆', category: 'Peoples', aliases: ['ok_woman'] },
	{ data: '🙆‍♂', category: 'Peoples', aliases: ['ok_man'] },
	{ data: '🙋', category: 'Peoples', aliases: ['raising_hand_woman', 'raising_hand'] },
	{ data: '🙋‍♂', category: 'Peoples', aliases: ['raising_hand_man'] },
	{ data: '🤦‍♀', category: 'Peoples', aliases: ['woman_facepalming'] },
	{ data: '🤦‍♂', category: 'Peoples', aliases: ['man_facepalming'] },
	{ data: '🤷‍♀', category: 'Peoples', aliases: ['woman_shrugging'] },
	{ data: '🤷‍♂', category: 'Peoples', aliases: ['man_shrugging'] },
	{ data: '🙎', category: 'Peoples', aliases: ['pouting_woman', 'person_with_pouting_face'] },
	{ data: '🙎‍♂', category: 'Peoples', aliases: ['pouting_man'] },
	{ data: '🙍', category: 'Peoples', aliases: ['frowning_woman', 'person_frowning'] },
	{ data: '🙍‍♂', category: 'Peoples', aliases: ['frowning_man'] },
	{ data: '💇', category: 'Peoples', aliases: ['haircut_woman', 'haircut'] },
	{ data: '💇‍♂', category: 'Peoples', aliases: ['haircut_man'] },
	{ data: '💆', category: 'Peoples', aliases: ['massage_woman', 'massage'] },
	{ data: '💆‍♂', category: 'Peoples', aliases: ['massage_man'] },
	{ data: '🕴', category: 'Peoples', aliases: ['business_suit_levitating'] },
	{ data: '💃', category: 'Peoples', aliases: ['dancer'] },
	{ data: '🕺', category: 'Peoples', aliases: ['man_dancing'] },
	{ data: '👯', category: 'Peoples', aliases: ['dancing_women', 'dancers'] },
	{ data: '👯‍♂', category: 'Peoples', aliases: ['dancing_men'] },
	{ data: '🚶‍♀', category: 'Peoples', aliases: ['walking_woman'] },
	{ data: '🚶', category: 'Peoples', aliases: ['walking_man', 'walking'] },
	{ data: '🏃‍♀', category: 'Peoples', aliases: ['running_woman'] },
	{ data: '🏃', category: 'Peoples', aliases: ['running_man', 'runner', 'running'] },
	{ data: '👫', category: 'Peoples', aliases: ['couple'] },
	{ data: '👭', category: 'Peoples', aliases: ['two_women_holding_hands'] },
	{ data: '👬', category: 'Peoples', aliases: ['two_men_holding_hands'] },
	{ data: '💑', category: 'Peoples', aliases: ['couple_with_heart_woman_man', 'couple_with_heart'] },
	{ data: '👩‍❤️‍👩', category: 'Peoples', aliases: ['couple_with_heart_woman_woman'] },
	{ data: '👨‍❤️‍👨', category: 'Peoples', aliases: ['couple_with_heart_man_man'] },
	{ data: '💏', category: 'Peoples', aliases: ['couplekiss_man_woman'] },
	{ data: '👩‍❤️‍💋‍👩', category: 'Peoples', aliases: ['couplekiss_woman_woman'] },
	{ data: '👨‍❤️‍💋‍👨', category: 'Peoples', aliases: ['couplekiss_man_man'] },
	{ data: '👪', category: 'Peoples', aliases: ['family_man_woman_boy', 'family'] },
	{ data: '👨‍👩‍👧', category: 'Peoples', aliases: ['family_man_woman_girl'] },
	{ data: '👨‍👩‍👧‍👦', category: 'Peoples', aliases: ['family_man_woman_girl_boy'] },
	{ data: '👨‍👩‍👦‍👦', category: 'Peoples', aliases: ['family_man_woman_boy_boy'] },
	{ data: '👨‍👩‍👧‍👧', category: 'Peoples', aliases: ['family_man_woman_girl_girl'] },
	{ data: '👩‍👩‍👦', category: 'Peoples', aliases: ['family_woman_woman_boy'] },
	{ data: '👩‍👩‍👧', category: 'Peoples', aliases: ['family_woman_woman_girl'] },
	{ data: '👩‍👩‍👧‍👦', category: 'Peoples', aliases: ['family_woman_woman_girl_boy'] },
	{ data: '👩‍👩‍👦‍👦', category: 'Peoples', aliases: ['family_woman_woman_boy_boy'] },
	{ data: '👩‍👩‍👧‍👧', category: 'Peoples', aliases: ['family_woman_woman_girl_girl'] },
	{ data: '👨‍👨‍👦', category: 'Peoples', aliases: ['family_man_man_boy'] },
	{ data: '👨‍👨‍👧', category: 'Peoples', aliases: ['family_man_man_girl'] },
	{ data: '👨‍👨‍👧‍👦', category: 'Peoples', aliases: ['family_man_man_girl_boy'] },
	{ data: '👨‍👨‍👦‍👦', category: 'Peoples', aliases: ['family_man_man_boy_boy'] },
	{ data: '👨‍👨‍👧‍👧', category: 'Peoples', aliases: ['family_man_man_girl_girl'] },
	{ data: '👩‍👦', category: 'Peoples', aliases: ['family_woman_boy'] },
	{ data: '👩‍👧', category: 'Peoples', aliases: ['family_woman_girl'] },
	{ data: '👩‍👧‍👦', category: 'Peoples', aliases: ['family_woman_girl_boy'] },
	{ data: '👩‍👦‍👦', category: 'Peoples', aliases: ['family_woman_boy_boy'] },
	{ data: '👩‍👧‍👧', category: 'Peoples', aliases: ['family_woman_girl_girl'] },
	{ data: '👨‍👦', category: 'Peoples', aliases: ['family_man_boy'] },
	{ data: '👨‍👧', category: 'Peoples', aliases: ['family_man_girl'] },
	{ data: '👨‍👧‍👦', category: 'Peoples', aliases: ['family_man_girl_boy'] },
	{ data: '👨‍👦‍👦', category: 'Peoples', aliases: ['family_man_boy_boy'] },
	{ data: '👨‍👧‍👧', category: 'Peoples', aliases: ['family_man_girl_girl'] },
	{ data: '👚', category: 'Peoples', aliases: ['womans_clothes'] },
	{ data: '👕', category: 'Peoples', aliases: ['shirt', 'tshirt'] },
	{ data: '👖', category: 'Peoples', aliases: ['jeans'] },
	{ data: '👔', category: 'Peoples', aliases: ['necktie'] },
	{ data: '👗', category: 'Peoples', aliases: ['dress'] },
	{ data: '👙', category: 'Peoples', aliases: ['bikini'] },
	{ data: '👘', category: 'Peoples', aliases: ['kimono'] },
	{ data: '👠', category: 'Peoples', aliases: ['high_heel'] },
	{ data: '👡', category: 'Peoples', aliases: ['sandal'] },
	{ data: '👢', category: 'Peoples', aliases: ['boot'] },
	{ data: '👞', category: 'Peoples', aliases: ['mans_shoe', 'shoe'] },
	{ data: '👟', category: 'Peoples', aliases: ['athletic_shoe'] },
	{ data: '👒', category: 'Peoples', aliases: ['womans_hat'] },
	{ data: '🎩', category: 'Peoples', aliases: ['tophat'] },
	{ data: '🎓', category: 'Peoples', aliases: ['mortar_board'] },
	{ data: '👑', category: 'Peoples', aliases: ['crown'] },
	{ data: '⛑', category: 'Peoples', aliases: ['rescue_worker_helmet'] },
	{ data: '🎒', category: 'Peoples', aliases: ['school_satchel'] },
	{ data: '👝', category: 'Peoples', aliases: ['pouch'] },
	{ data: '👛', category: 'Peoples', aliases: ['purse'] },
	{ data: '👜', category: 'Peoples', aliases: ['handbag'] },
	{ data: '💼', category: 'Peoples', aliases: ['briefcase'] },
	{ data: '👓', category: 'Peoples', aliases: ['eyeglasses'] },
	{ data: '🕶', category: 'Peoples', aliases: ['dark_sunglasses'] },
	{ data: '🌂', category: 'Peoples', aliases: ['closed_umbrella'] },
	{ data: '☂️', category: 'Peoples', aliases: ['open_umbrella'] },
	{ data: '🐶', category: 'Nature', aliases: ['dog'] },
	{ data: '🐱', category: 'Nature', aliases: ['cat'] },
	{ data: '🐭', category: 'Nature', aliases: ['mouse'] },
	{ data: '🐹', category: 'Nature', aliases: ['hamster'] },
	{ data: '🐰', category: 'Nature', aliases: ['rabbit'] },
	{ data: '🦊', category: 'Nature', aliases: ['fox_face'] },
	{ data: '🐻', category: 'Nature', aliases: ['bear'] },
	{ data: '🐼', category: 'Nature', aliases: ['panda_face'] },
	{ data: '🐨', category: 'Nature', aliases: ['koala'] },
	{ data: '🐯', category: 'Nature', aliases: ['tiger'] },
	{ data: '🦁', category: 'Nature', aliases: ['lion'] },
	{ data: '🐮', category: 'Nature', aliases: ['cow'] },
	{ data: '🐷', category: 'Nature', aliases: ['pig'] },
	{ data: '🐽', category: 'Nature', aliases: ['pig_nose'] },
	{ data: '🐸', category: 'Nature', aliases: ['frog'] },
	{ data: '🐵', category: 'Nature', aliases: ['monkey_face'] },
	{ data: '🙈', category: 'Nature', aliases: ['see_no_evil'] },
	{ data: '🙉', category: 'Nature', aliases: ['hear_no_evil'] },
	{ data: '🙊', category: 'Nature', aliases: ['speak_no_evil'] },
	{ data: '🐒', category: 'Nature', aliases: ['monkey'] },
	{ data: '🐔', category: 'Nature', aliases: ['chicken'] },
	{ data: '🐧', category: 'Nature', aliases: ['penguin'] },
	{ data: '🐦', category: 'Nature', aliases: ['bird'] },
	{ data: '🐤', category: 'Nature', aliases: ['baby_chick'] },
	{ data: '🐣', category: 'Nature', aliases: ['hatching_chick'] },
	{ data: '🐥', category: 'Nature', aliases: ['hatched_chick'] },
	{ data: '🦆', category: 'Nature', aliases: ['duck'] },
	{ data: '🦅', category: 'Nature', aliases: ['eagle'] },
	{ data: '🦉', category: 'Nature', aliases: ['owl'] },
	{ data: '🦇', category: 'Nature', aliases: ['bat'] },
	{ data: '🐺', category: 'Nature', aliases: ['wolf'] },
	{ data: '🐗', category: 'Nature', aliases: ['boar'] },
	{ data: '🐴', category: 'Nature', aliases: ['horse'] },
	{ data: '🦄', category: 'Nature', aliases: ['unicorn'] },
	{ data: '🐝', category: 'Nature', aliases: ['bee', 'honeybee'] },
	{ data: '🐛', category: 'Nature', aliases: ['bug'] },
	{ data: '🦋', category: 'Nature', aliases: ['butterfly'] },
	{ data: '🐌', category: 'Nature', aliases: ['snail'] },
	{ data: '🐚', category: 'Nature', aliases: ['shell'] },
	{ data: '🐞', category: 'Nature', aliases: ['beetle'] },
	{ data: '🐜', category: 'Nature', aliases: ['ant'] },
	{ data: '🕷', category: 'Nature', aliases: ['spider'] },
	{ data: '🕸', category: 'Nature', aliases: ['spider_web'] },
	{ data: '🐢', category: 'Nature', aliases: ['turtle'] },
	{ data: '🐍', category: 'Nature', aliases: ['snake'] },
	{ data: '🦎', category: 'Nature', aliases: ['lizard'] },
	{ data: '🦂', category: 'Nature', aliases: ['scorpion'] },
	{ data: '🦀', category: 'Nature', aliases: ['crab'] },
	{ data: '🦑', category: 'Nature', aliases: ['squid'] },
	{ data: '🐙', category: 'Nature', aliases: ['octopus'] },
	{ data: '🦐', category: 'Nature', aliases: ['shrimp'] },
	{ data: '🐠', category: 'Nature', aliases: ['tropical_fish'] },
	{ data: '🐟', category: 'Nature', aliases: ['fish'] },
	{ data: '🐡', category: 'Nature', aliases: ['blowfish'] },
	{ data: '🐬', category: 'Nature', aliases: ['dolphin', 'flipper'] },
	{ data: '🦈', category: 'Nature', aliases: ['shark'] },
	{ data: '🐳', category: 'Nature', aliases: ['whale'] },
	{ data: '🐋', category: 'Nature', aliases: ['whale2'] },
	{ data: '🐊', category: 'Nature', aliases: ['crocodile'] },
	{ data: '🐆', category: 'Nature', aliases: ['leopard'] },
	{ data: '🐅', category: 'Nature', aliases: ['tiger2'] },
	{ data: '🐃', category: 'Nature', aliases: ['water_buffalo'] },
	{ data: '🐂', category: 'Nature', aliases: ['ox'] },
	{ data: '🐄', category: 'Nature', aliases: ['cow2'] },
	{ data: '🦌', category: 'Nature', aliases: ['deer'] },
	{ data: '🐪', category: 'Nature', aliases: ['dromedary_camel'] },
	{ data: '🐫', category: 'Nature', aliases: ['camel'] },
	{ data: '🐘', category: 'Nature', aliases: ['elephant'] },
	{ data: '🦏', category: 'Nature', aliases: ['rhinoceros'] },
	{ data: '🦍', category: 'Nature', aliases: ['gorilla'] },
	{ data: '🐎', category: 'Nature', aliases: ['racehorse'] },
	{ data: '🐖', category: 'Nature', aliases: ['pig2'] },
	{ data: '🐐', category: 'Nature', aliases: ['goat'] },
	{ data: '🐏', category: 'Nature', aliases: ['ram'] },
	{ data: '🐑', category: 'Nature', aliases: ['sheep'] },
	{ data: '🐕', category: 'Nature', aliases: ['dog2'] },
	{ data: '🐩', category: 'Nature', aliases: ['poodle'] },
	{ data: '🐈', category: 'Nature', aliases: ['cat2'] },
	{ data: '🐓', category: 'Nature', aliases: ['rooster'] },
	{ data: '🦃', category: 'Nature', aliases: ['turkey'] },
	{ data: '🕊', category: 'Nature', aliases: ['dove'] },
	{ data: '🐇', category: 'Nature', aliases: ['rabbit2'] },
	{ data: '🐁', category: 'Nature', aliases: ['mouse2'] },
	{ data: '🐀', category: 'Nature', aliases: ['rat'] },
	{ data: '🐿', category: 'Nature', aliases: ['chipmunk'] },
	{ data: '🐾', category: 'Nature', aliases: ['feet', 'paw_prints'] },
	{ data: '🐉', category: 'Nature', aliases: ['dragon'] },
	{ data: '🐲', category: 'Nature', aliases: ['dragon_face'] },
	{ data: '🌵', category: 'Nature', aliases: ['cactus'] },
	{ data: '🎄', category: 'Nature', aliases: ['christmas_tree'] },
	{ data: '🌲', category: 'Nature', aliases: ['evergreen_tree'] },
	{ data: '🌳', category: 'Nature', aliases: ['deciduous_tree'] },
	{ data: '🌴', category: 'Nature', aliases: ['palm_tree'] },
	{ data: '🌱', category: 'Nature', aliases: ['seedling'] },
	{ data: '🌿', category: 'Nature', aliases: ['herb'] },
	{ data: '☘️', category: 'Nature', aliases: ['shamrock'] },
	{ data: '🍀', category: 'Nature', aliases: ['four_leaf_clover'] },
	{ data: '🎍', category: 'Nature', aliases: ['bamboo'] },
	{ data: '🎋', category: 'Nature', aliases: ['tanabata_tree'] },
	{ data: '🍃', category: 'Nature', aliases: ['leaves'] },
	{ data: '🍂', category: 'Nature', aliases: ['fallen_leaf'] },
	{ data: '🍁', category: 'Nature', aliases: ['maple_leaf'] },
	{ data: '🍄', category: 'Nature', aliases: ['mushroom'] },
	{ data: '🌾', category: 'Nature', aliases: ['ear_of_rice'] },
	{ data: '💐', category: 'Nature', aliases: ['bouquet'] },
	{ data: '🌷', category: 'Nature', aliases: ['tulip'] },
	{ data: '🌹', category: 'Nature', aliases: ['rose'] },
	{ data: '🥀', category: 'Nature', aliases: ['wilted_flower'] },
	{ data: '🌻', category: 'Nature', aliases: ['sunflower'] },
	{ data: '🌼', category: 'Nature', aliases: ['blossom'] },
	{ data: '🌸', category: 'Nature', aliases: ['cherry_blossom'] },
	{ data: '🌺', category: 'Nature', aliases: ['hibiscus'] },
	{ data: '🌎', category: 'Nature', aliases: ['earth_americas'] },
	{ data: '🌍', category: 'Nature', aliases: ['earth_africa'] },
	{ data: '🌏', category: 'Nature', aliases: ['earth_asia'] },
	{ data: '🌕', category: 'Nature', aliases: ['full_moon'] },
	{ data: '🌖', category: 'Nature', aliases: ['waning_gibbous_moon'] },
	{ data: '🌗', category: 'Nature', aliases: ['last_quarter_moon'] },
	{ data: '🌘', category: 'Nature', aliases: ['waning_crescent_moon'] },
	{ data: '🌑', category: 'Nature', aliases: ['new_moon'] },
	{ data: '🌒', category: 'Nature', aliases: ['waxing_crescent_moon'] },
	{ data: '🌓', category: 'Nature', aliases: ['first_quarter_moon'] },
	{ data: '🌔', category: 'Nature', aliases: ['moon', 'waxing_gibbous_moon'] },
	{ data: '🌚', category: 'Nature', aliases: ['new_moon_with_face'] },
	{ data: '🌝', category: 'Nature', aliases: ['full_moon_with_face'] },
	{ data: '🌞', category: 'Nature', aliases: ['sun_with_face'] },
	{ data: '🌛', category: 'Nature', aliases: ['first_quarter_moon_with_face'] },
	{ data: '🌜', category: 'Nature', aliases: ['last_quarter_moon_with_face'] },
	{ data: '🌙', category: 'Nature', aliases: ['crescent_moon'] },
	{ data: '💫', category: 'Nature', aliases: ['dizzy'] },
	{ data: '⭐️', category: 'Nature', aliases: ['star'] },
	{ data: '🌟', category: 'Nature', aliases: ['star2'] },
	{ data: '✨', category: 'Nature', aliases: ['sparkles'] },
	{ data: '⚡️', category: 'Nature', aliases: ['zap'] },
	{ data: '🔥', category: 'Nature', aliases: ['fire'] },
	{ data: '💥', category: 'Nature', aliases: ['boom', 'collision'] },
	{ data: '☄', category: 'Nature', aliases: ['comet'] },
	{ data: '☀️', category: 'Nature', aliases: ['sunny'] },
	{ data: '🌤', category: 'Nature', aliases: ['sun_behind_small_cloud'] },
	{ data: '⛅️', category: 'Nature', aliases: ['partly_sunny'] },
	{ data: '🌥', category: 'Nature', aliases: ['sun_behind_large_cloud'] },
	{ data: '🌦', category: 'Nature', aliases: ['sun_behind_rain_cloud'] },
	{ data: '🌈', category: 'Nature', aliases: ['rainbow'] },
	{ data: '☁️', category: 'Nature', aliases: ['cloud'] },
	{ data: '🌧', category: 'Nature', aliases: ['cloud_with_rain'] },
	{ data: '⛈', category: 'Nature', aliases: ['cloud_with_lightning_and_rain'] },
	{ data: '🌩', category: 'Nature', aliases: ['cloud_with_lightning'] },
	{ data: '🌨', category: 'Nature', aliases: ['cloud_with_snow'] },
	{ data: '☃️', category: 'Nature', aliases: ['snowman_with_snow'] },
	{ data: '⛄️', category: 'Nature', aliases: ['snowman'] },
	{ data: '❄️', category: 'Nature', aliases: ['snowflake'] },
	{ data: '🌬', category: 'Nature', aliases: ['wind_face'] },
	{ data: '💨', category: 'Nature', aliases: ['dash'] },
	{ data: '🌪', category: 'Nature', aliases: ['tornado'] },
	{ data: '🌫', category: 'Nature', aliases: ['fog'] },
	{ data: '🌊', category: 'Nature', aliases: ['ocean'] },
	{ data: '💧', category: 'Nature', aliases: ['droplet'] },
	{ data: '💦', category: 'Nature', aliases: ['sweat_drops'] },
	{ data: '☔️', category: 'Nature', aliases: ['umbrella'] },
	{ data: '🍏', category: 'Foods', aliases: ['green_apple'] },
	{ data: '🍎', category: 'Foods', aliases: ['apple'] },
	{ data: '🍐', category: 'Foods', aliases: ['pear'] },
	{ data: '🍊', category: 'Foods', aliases: ['tangerine', 'orange', 'mandarin'] },
	{ data: '🍋', category: 'Foods', aliases: ['lemon'] },
	{ data: '🍌', category: 'Foods', aliases: ['banana'] },
	{ data: '🍉', category: 'Foods', aliases: ['watermelon'] },
	{ data: '🍇', category: 'Foods', aliases: ['grapes'] },
	{ data: '🍓', category: 'Foods', aliases: ['strawberry'] },
	{ data: '🍈', category: 'Foods', aliases: ['melon'] },
	{ data: '🍒', category: 'Foods', aliases: ['cherries'] },
	{ data: '🍑', category: 'Foods', aliases: ['peach'] },
	{ data: '🍍', category: 'Foods', aliases: ['pineapple'] },
	{ data: '🥝', category: 'Foods', aliases: ['kiwi_fruit'] },
	{ data: '🥑', category: 'Foods', aliases: ['avocado'] },
	{ data: '🍅', category: 'Foods', aliases: ['tomato'] },
	{ data: '🍆', category: 'Foods', aliases: ['eggplant'] },
	{ data: '🥒', category: 'Foods', aliases: ['cucumber'] },
	{ data: '🥕', category: 'Foods', aliases: ['carrot'] },
	{ data: '🌽', category: 'Foods', aliases: ['corn'] },
	{ data: '🌶', category: 'Foods', aliases: ['hot_pepper'] },
	{ data: '🥔', category: 'Foods', aliases: ['potato'] },
	{ data: '🍠', category: 'Foods', aliases: ['sweet_potato'] },
	{ data: '🌰', category: 'Foods', aliases: ['chestnut'] },
	{ data: '🥜', category: 'Foods', aliases: ['peanuts'] },
	{ data: '🍯', category: 'Foods', aliases: ['honey_pot'] },
	{ data: '🥐', category: 'Foods', aliases: ['croissant'] },
	{ data: '🍞', category: 'Foods', aliases: ['bread'] },
	{ data: '🥖', category: 'Foods', aliases: ['baguette_bread'] },
	{ data: '🧀', category: 'Foods', aliases: ['cheese'] },
	{ data: '🥚', category: 'Foods', aliases: ['egg'] },
	{ data: '🍳', category: 'Foods', aliases: ['fried_egg'] },
	{ data: '🥓', category: 'Foods', aliases: ['bacon'] },
	{ data: '🥞', category: 'Foods', aliases: ['pancakes'] },
	{ data: '🍤', category: 'Foods', aliases: ['fried_shrimp'] },
	{ data: '🍗', category: 'Foods', aliases: ['poultry_leg'] },
	{ data: '🍖', category: 'Foods', aliases: ['meat_on_bone'] },
	{ data: '🍕', category: 'Foods', aliases: ['pizza'] },
	{ data: '🌭', category: 'Foods', aliases: ['hotdog'] },
	{ data: '🍔', category: 'Foods', aliases: ['hamburger'] },
	{ data: '🍟', category: 'Foods', aliases: ['fries'] },
	{ data: '🥙', category: 'Foods', aliases: ['stuffed_flatbread'] },
	{ data: '🌮', category: 'Foods', aliases: ['taco'] },
	{ data: '🌯', category: 'Foods', aliases: ['burrito'] },
	{ data: '🥗', category: 'Foods', aliases: ['green_salad'] },
	{ data: '🥘', category: 'Foods', aliases: ['shallow_pan_of_food'] },
	{ data: '🍝', category: 'Foods', aliases: ['spaghetti'] },
	{ data: '🍜', category: 'Foods', aliases: ['ramen'] },
	{ data: '🍲', category: 'Foods', aliases: ['stew'] },
	{ data: '🍥', category: 'Foods', aliases: ['fish_cake'] },
	{ data: '🍣', category: 'Foods', aliases: ['sushi'] },
	{ data: '🍱', category: 'Foods', aliases: ['bento'] },
	{ data: '🍛', category: 'Foods', aliases: ['curry'] },
	{ data: '🍚', category: 'Foods', aliases: ['rice'] },
	{ data: '🍙', category: 'Foods', aliases: ['rice_ball'] },
	{ data: '🍘', category: 'Foods', aliases: ['rice_cracker'] },
	{ data: '🍢', category: 'Foods', aliases: ['oden'] },
	{ data: '🍡', category: 'Foods', aliases: ['dango'] },
	{ data: '🍧', category: 'Foods', aliases: ['shaved_ice'] },
	{ data: '🍨', category: 'Foods', aliases: ['ice_cream'] },
	{ data: '🍦', category: 'Foods', aliases: ['icecream'] },
	{ data: '🍰', category: 'Foods', aliases: ['cake'] },
	{ data: '🎂', category: 'Foods', aliases: ['birthday'] },
	{ data: '🍮', category: 'Foods', aliases: ['custard'] },
	{ data: '🍭', category: 'Foods', aliases: ['lollipop'] },
	{ data: '🍬', category: 'Foods', aliases: ['candy'] },
	{ data: '🍫', category: 'Foods', aliases: ['chocolate_bar'] },
	{ data: '🍿', category: 'Foods', aliases: ['popcorn'] },
	{ data: '🍩', category: 'Foods', aliases: ['doughnut'] },
	{ data: '🍪', category: 'Foods', aliases: ['cookie'] },
	{ data: '🥛', category: 'Foods', aliases: ['milk_glass'] },
	{ data: '🍼', category: 'Foods', aliases: ['baby_bottle'] },
	{ data: '☕️', category: 'Foods', aliases: ['coffee'] },
	{ data: '🍵', category: 'Foods', aliases: ['tea'] },
	{ data: '🍶', category: 'Foods', aliases: ['sake'] },
	{ data: '🍺', category: 'Foods', aliases: ['beer'] },
	{ data: '🍻', category: 'Foods', aliases: ['beers'] },
	{ data: '🥂', category: 'Foods', aliases: ['clinking_glasses'] },
	{ data: '🍷', category: 'Foods', aliases: ['wine_glass'] },
	{ data: '🥃', category: 'Foods', aliases: ['tumbler_glass'] },
	{ data: '🍸', category: 'Foods', aliases: ['cocktail'] },
	{ data: '🍹', category: 'Foods', aliases: ['tropical_drink'] },
	{ data: '🍾', category: 'Foods', aliases: ['champagne'] },
	{ data: '🥄', category: 'Foods', aliases: ['spoon'] },
	{ data: '🍴', category: 'Foods', aliases: ['fork_and_knife'] },
	{ data: '🍽', category: 'Foods', aliases: ['plate_with_cutlery'] },
	{ data: '⚽️', category: 'Activity', aliases: ['soccer'] },
	{ data: '🏀', category: 'Activity', aliases: ['basketball'] },
	{ data: '🏈', category: 'Activity', aliases: ['football'] },
	{ data: '⚾️', category: 'Activity', aliases: ['baseball'] },
	{ data: '🎾', category: 'Activity', aliases: ['tennis'] },
	{ data: '🏐', category: 'Activity', aliases: ['volleyball'] },
	{ data: '🏉', category: 'Activity', aliases: ['rugby_football'] },
	{ data: '🎱', category: 'Activity', aliases: ['8ball'] },
	{ data: '🏓', category: 'Activity', aliases: ['ping_pong'] },
	{ data: '🏸', category: 'Activity', aliases: ['badminton'] },
	{ data: '🥅', category: 'Activity', aliases: ['goal_net'] },
	{ data: '🏒', category: 'Activity', aliases: ['ice_hockey'] },
	{ data: '🏑', category: 'Activity', aliases: ['field_hockey'] },
	{ data: '🏏', category: 'Activity', aliases: ['cricket'] },
	{ data: '⛳️', category: 'Activity', aliases: ['golf'] },
	{ data: '🏹', category: 'Activity', aliases: ['bow_and_arrow'] },
	{ data: '🎣', category: 'Activity', aliases: ['fishing_pole_and_fish'] },
	{ data: '🥊', category: 'Activity', aliases: ['boxing_glove'] },
	{ data: '🥋', category: 'Activity', aliases: ['martial_arts_uniform'] },
	{ data: '⛸', category: 'Activity', aliases: ['ice_skate'] },
	{ data: '🎿', category: 'Activity', aliases: ['ski'] },
	{ data: '⛷', category: 'Activity', aliases: ['skier'] },
	{ data: '🏂', category: 'Activity', aliases: ['snowboarder'] },
	{ data: '🏋️‍♀️', category: 'Activity', aliases: ['weight_lifting_woman'] },
	{ data: '🏋', category: 'Activity', aliases: ['weight_lifting_man'] },
	{ data: '🤺', category: 'Activity', aliases: ['person_fencing'] },
	{ data: '🤼‍♀', category: 'Activity', aliases: ['women_wrestling'] },
	{ data: '🤼‍♂', category: 'Activity', aliases: ['men_wrestling'] },
	{ data: '🤸‍♀', category: 'Activity', aliases: ['woman_cartwheeling'] },
	{ data: '🤸‍♂', category: 'Activity', aliases: ['man_cartwheeling'] },
	{ data: '⛹️‍♀️', category: 'Activity', aliases: ['basketball_woman'] },
	{ data: '⛹', category: 'Activity', aliases: ['basketball_man'] },
	{ data: '🤾‍♀', category: 'Activity', aliases: ['woman_playing_handball'] },
	{ data: '🤾‍♂', category: 'Activity', aliases: ['man_playing_handball'] },
	{ data: '🏌️‍♀️', category: 'Activity', aliases: ['golfing_woman'] },
	{ data: '🏌', category: 'Activity', aliases: ['golfing_man'] },
	{ data: '🏄‍♀', category: 'Activity', aliases: ['surfing_woman'] },
	{ data: '🏄', category: 'Activity', aliases: ['surfing_man', 'surfer'] },
	{ data: '🏊‍♀', category: 'Activity', aliases: ['swimming_woman'] },
	{ data: '🏊', category: 'Activity', aliases: ['swimming_man', 'swimmer'] },
	{ data: '🤽‍♀', category: 'Activity', aliases: ['woman_playing_water_polo'] },
	{ data: '🤽‍♂', category: 'Activity', aliases: ['man_playing_water_polo'] },
	{ data: '🚣‍♀', category: 'Activity', aliases: ['rowing_woman'] },
	{ data: '🚣', category: 'Activity', aliases: ['rowing_man', 'rowboat'] },
	{ data: '🏇', category: 'Activity', aliases: ['horse_racing'] },
	{ data: '🚴‍♀', category: 'Activity', aliases: ['biking_woman'] },
	{ data: '🚴', category: 'Activity', aliases: ['biking_man', 'bicyclist'] },
	{ data: '🚵‍♀', category: 'Activity', aliases: ['mountain_biking_woman'] },
	{ data: '🚵', category: 'Activity', aliases: ['mountain_biking_man', 'mountain_bicyclist'] },
	{ data: '🎽', category: 'Activity', aliases: ['running_shirt_with_sash'] },
	{ data: '🏅', category: 'Activity', aliases: ['medal_sports'] },
	{ data: '🎖', category: 'Activity', aliases: ['medal_military'] },
	{ data: '🥇', category: 'Activity', aliases: ['1st_place_medal'] },
	{ data: '🥈', category: 'Activity', aliases: ['2nd_place_medal'] },
	{ data: '🥉', category: 'Activity', aliases: ['3rd_place_medal'] },
	{ data: '🏆', category: 'Activity', aliases: ['trophy'] },
	{ data: '🏵', category: 'Activity', aliases: ['rosette'] },
	{ data: '🎗', category: 'Activity', aliases: ['reminder_ribbon'] },
	{ data: '🎫', category: 'Activity', aliases: ['ticket'] },
	{ data: '🎟', category: 'Activity', aliases: ['tickets'] },
	{ data: '🎪', category: 'Activity', aliases: ['circus_tent'] },
	{ data: '🤹‍♀', category: 'Activity', aliases: ['woman_juggling'] },
	{ data: '🤹‍♂', category: 'Activity', aliases: ['man_juggling'] },
	{ data: '🎭', category: 'Activity', aliases: ['performing_arts'] },
	{ data: '🎨', category: 'Activity', aliases: ['art'] },
	{ data: '🎬', category: 'Activity', aliases: ['clapper'] },
	{ data: '🎤', category: 'Activity', aliases: ['microphone'] },
	{ data: '🎧', category: 'Activity', aliases: ['headphones'] },
	{ data: '🎼', category: 'Activity', aliases: ['musical_score'] },
	{ data: '🎹', category: 'Activity', aliases: ['musical_keyboard'] },
	{ data: '🥁', category: 'Activity', aliases: ['drum'] },
	{ data: '🎷', category: 'Activity', aliases: ['saxophone'] },
	{ data: '🎺', category: 'Activity', aliases: ['trumpet'] },
	{ data: '🎸', category: 'Activity', aliases: ['guitar'] },
	{ data: '🎻', category: 'Activity', aliases: ['violin'] },
	{ data: '🎲', category: 'Activity', aliases: ['game_die'] },
	{ data: '🎯', category: 'Activity', aliases: ['dart'] },
	{ data: '🎳', category: 'Activity', aliases: ['bowling'] },
	{ data: '🎮', category: 'Activity', aliases: ['video_game'] },
	{ data: '🎰', category: 'Activity', aliases: ['slot_machine'] },
	{ data: '🚗', category: 'Places', aliases: ['car', 'red_car'] },
	{ data: '🚕', category: 'Places', aliases: ['taxi'] },
	{ data: '🚙', category: 'Places', aliases: ['blue_car'] },
	{ data: '🚌', category: 'Places', aliases: ['bus'] },
	{ data: '🚎', category: 'Places', aliases: ['trolleybus'] },
	{ data: '🏎', category: 'Places', aliases: ['racing_car'] },
	{ data: '🚓', category: 'Places', aliases: ['police_car'] },
	{ data: '🚑', category: 'Places', aliases: ['ambulance'] },
	{ data: '🚒', category: 'Places', aliases: ['fire_engine'] },
	{ data: '🚐', category: 'Places', aliases: ['minibus'] },
	{ data: '🚚', category: 'Places', aliases: ['truck'] },
	{ data: '🚛', category: 'Places', aliases: ['articulated_lorry'] },
	{ data: '🚜', category: 'Places', aliases: ['tractor'] },
	{ data: '🛴', category: 'Places', aliases: ['kick_scooter'] },
	{ data: '🚲', category: 'Places', aliases: ['bike'] },
	{ data: '🛵', category: 'Places', aliases: ['motor_scooter'] },
	{ data: '🏍', category: 'Places', aliases: ['motorcycle'] },
	{ data: '🚨', category: 'Places', aliases: ['rotating_light'] },
	{ data: '🚔', category: 'Places', aliases: ['oncoming_police_car'] },
	{ data: '🚍', category: 'Places', aliases: ['oncoming_bus'] },
	{ data: '🚘', category: 'Places', aliases: ['oncoming_automobile'] },
	{ data: '🚖', category: 'Places', aliases: ['oncoming_taxi'] },
	{ data: '🚡', category: 'Places', aliases: ['aerial_tramway'] },
	{ data: '🚠', category: 'Places', aliases: ['mountain_cableway'] },
	{ data: '🚟', category: 'Places', aliases: ['suspension_railway'] },
	{ data: '🚃', category: 'Places', aliases: ['railway_car'] },
	{ data: '🚋', category: 'Places', aliases: ['train'] },
	{ data: '🚞', category: 'Places', aliases: ['mountain_railway'] },
	{ data: '🚝', category: 'Places', aliases: ['monorail'] },
	{ data: '🚄', category: 'Places', aliases: ['bullettrain_side'] },
	{ data: '🚅', category: 'Places', aliases: ['bullettrain_front'] },
	{ data: '🚈', category: 'Places', aliases: ['light_rail'] },
	{ data: '🚂', category: 'Places', aliases: ['steam_locomotive'] },
	{ data: '🚆', category: 'Places', aliases: ['train2'] },
	{ data: '🚇', category: 'Places', aliases: ['metro'] },
	{ data: '🚊', category: 'Places', aliases: ['tram'] },
	{ data: '🚉', category: 'Places', aliases: ['station'] },
	{ data: '🚁', category: 'Places', aliases: ['helicopter'] },
	{ data: '🛩', category: 'Places', aliases: ['small_airplane'] },
	{ data: '✈️', category: 'Places', aliases: ['airplane'] },
	{ data: '🛫', category: 'Places', aliases: ['flight_departure'] },
	{ data: '🛬', category: 'Places', aliases: ['flight_arrival'] },
	{ data: '🚀', category: 'Places', aliases: ['rocket'] },
	{ data: '🛰', category: 'Places', aliases: ['artificial_satellite'] },
	{ data: '💺', category: 'Places', aliases: ['seat'] },
	{ data: '🛶', category: 'Places', aliases: ['canoe'] },
	{ data: '⛵️', category: 'Places', aliases: ['boat', 'sailboat'] },
	{ data: '🛥', category: 'Places', aliases: ['motor_boat'] },
	{ data: '🚤', category: 'Places', aliases: ['speedboat'] },
	{ data: '🛳', category: 'Places', aliases: ['passenger_ship'] },
	{ data: '⛴', category: 'Places', aliases: ['ferry'] },
	{ data: '🚢', category: 'Places', aliases: ['ship'] },
	{ data: '⚓️', category: 'Places', aliases: ['anchor'] },
	{ data: '🚧', category: 'Places', aliases: ['construction'] },
	{ data: '⛽️', category: 'Places', aliases: ['fuelpump'] },
	{ data: '🚏', category: 'Places', aliases: ['busstop'] },
	{ data: '🚦', category: 'Places', aliases: ['vertical_traffic_light'] },
	{ data: '🚥', category: 'Places', aliases: ['traffic_light'] },
	{ data: '🗺', category: 'Places', aliases: ['world_map'] },
	{ data: '🗿', category: 'Places', aliases: ['moyai'] },
	{ data: '🗽', category: 'Places', aliases: ['statue_of_liberty'] },
	{ data: '⛲️', category: 'Places', aliases: ['fountain'] },
	{ data: '🗼', category: 'Places', aliases: ['tokyo_tower'] },
	{ data: '🏰', category: 'Places', aliases: ['european_castle'] },
	{ data: '🏯', category: 'Places', aliases: ['japanese_castle'] },
	{ data: '🏟', category: 'Places', aliases: ['stadium'] },
	{ data: '🎡', category: 'Places', aliases: ['ferris_wheel'] },
	{ data: '🎢', category: 'Places', aliases: ['roller_coaster'] },
	{ data: '🎠', category: 'Places', aliases: ['carousel_horse'] },
	{ data: '⛱', category: 'Places', aliases: ['parasol_on_ground'] },
	{ data: '🏖', category: 'Places', aliases: ['beach_umbrella'] },
	{ data: '🏝', category: 'Places', aliases: ['desert_island'] },
	{ data: '⛰', category: 'Places', aliases: ['mountain'] },
	{ data: '🏔', category: 'Places', aliases: ['mountain_snow'] },
	{ data: '🗻', category: 'Places', aliases: ['mount_fuji'] },
	{ data: '🌋', category: 'Places', aliases: ['volcano'] },
	{ data: '🏜', category: 'Places', aliases: ['desert'] },
	{ data: '🏕', category: 'Places', aliases: ['camping'] },
	{ data: '⛺️', category: 'Places', aliases: ['tent'] },
	{ data: '🛤', category: 'Places', aliases: ['railway_track'] },
	{ data: '🛣', category: 'Places', aliases: ['motorway'] },
	{ data: '🏗', category: 'Places', aliases: ['building_construction'] },
	{ data: '🏭', category: 'Places', aliases: ['factory'] },
	{ data: '🏠', category: 'Places', aliases: ['house'] },
	{ data: '🏡', category: 'Places', aliases: ['house_with_garden'] },
	{ data: '🏘', category: 'Places', aliases: ['houses'] },
	{ data: '🏚', category: 'Places', aliases: ['derelict_house'] },
	{ data: '🏢', category: 'Places', aliases: ['office'] },
	{ data: '🏬', category: 'Places', aliases: ['department_store'] },
	{ data: '🏣', category: 'Places', aliases: ['post_office'] },
	{ data: '🏤', category: 'Places', aliases: ['european_post_office'] },
	{ data: '🏥', category: 'Places', aliases: ['hospital'] },
	{ data: '🏦', category: 'Places', aliases: ['bank'] },
	{ data: '🏨', category: 'Places', aliases: ['hotel'] },
	{ data: '🏪', category: 'Places', aliases: ['convenience_store'] },
	{ data: '🏫', category: 'Places', aliases: ['school'] },
	{ data: '🏩', category: 'Places', aliases: ['love_hotel'] },
	{ data: '💒', category: 'Places', aliases: ['wedding'] },
	{ data: '🏛', category: 'Places', aliases: ['classical_building'] },
	{ data: '⛪️', category: 'Places', aliases: ['church'] },
	{ data: '🕌', category: 'Places', aliases: ['mosque'] },
	{ data: '🕍', category: 'Places', aliases: ['synagogue'] },
	{ data: '🕋', category: 'Places', aliases: ['kaaba'] },
	{ data: '⛩', category: 'Places', aliases: ['shinto_shrine'] },
	{ data: '🗾', category: 'Places', aliases: ['japan'] },
	{ data: '🎑', category: 'Places', aliases: ['rice_scene'] },
	{ data: '🏞', category: 'Places', aliases: ['national_park'] },
	{ data: '🌅', category: 'Places', aliases: ['sunrise'] },
	{ data: '🌄', category: 'Places', aliases: ['sunrise_over_mountains'] },
	{ data: '🌠', category: 'Places', aliases: ['stars'] },
	{ data: '🎇', category: 'Places', aliases: ['sparkler'] },
	{ data: '🎆', category: 'Places', aliases: ['fireworks'] },
	{ data: '🌇', category: 'Places', aliases: ['city_sunrise'] },
	{ data: '🌆', category: 'Places', aliases: ['city_sunset'] },
	{ data: '🏙', category: 'Places', aliases: ['cityscape'] },
	{ data: '🌃', category: 'Places', aliases: ['night_with_stars'] },
	{ data: '🌌', category: 'Places', aliases: ['milky_way'] },
	{ data: '🌉', category: 'Places', aliases: ['bridge_at_night'] },
	{ data: '🌁', category: 'Places', aliases: ['foggy'] },
	{ data: '⌚️', category: 'Objects', aliases: ['watch'] },
	{ data: '📱', category: 'Objects', aliases: ['iphone'] },
	{ data: '📲', category: 'Objects', aliases: ['calling'] },
	{ data: '💻', category: 'Objects', aliases: ['computer'] },
	{ data: '⌨️', category: 'Objects', aliases: ['keyboard'] },
	{ data: '🖥', category: 'Objects', aliases: ['desktop_computer'] },
	{ data: '🖨', category: 'Objects', aliases: ['printer'] },
	{ data: '🖱', category: 'Objects', aliases: ['computer_mouse'] },
	{ data: '🖲', category: 'Objects', aliases: ['trackball'] },
	{ data: '🕹', category: 'Objects', aliases: ['joystick'] },
	{ data: '🗜', category: 'Objects', aliases: ['clamp'] },
	{ data: '💽', category: 'Objects', aliases: ['minidisc'] },
	{ data: '💾', category: 'Objects', aliases: ['floppy_disk'] },
	{ data: '💿', category: 'Objects', aliases: ['cd'] },
	{ data: '📀', category: 'Objects', aliases: ['dvd'] },
	{ data: '📼', category: 'Objects', aliases: ['vhs'] },
	{ data: '📷', category: 'Objects', aliases: ['camera'] },
	{ data: '📸', category: 'Objects', aliases: ['camera_flash'] },
	{ data: '📹', category: 'Objects', aliases: ['video_camera'] },
	{ data: '🎥', category: 'Objects', aliases: ['movie_camera'] },
	{ data: '📽', category: 'Objects', aliases: ['film_projector'] },
	{ data: '🎞', category: 'Objects', aliases: ['film_strip'] },
	{ data: '📞', category: 'Objects', aliases: ['telephone_receiver'] },
	{ data: '☎️', category: 'Objects', aliases: ['phone', 'telephone'] },
	{ data: '📟', category: 'Objects', aliases: ['pager'] },
	{ data: '📠', category: 'Objects', aliases: ['fax'] },
	{ data: '📺', category: 'Objects', aliases: ['tv'] },
	{ data: '📻', category: 'Objects', aliases: ['radio'] },
	{ data: '🎙', category: 'Objects', aliases: ['studio_microphone'] },
	{ data: '🎚', category: 'Objects', aliases: ['level_slider'] },
	{ data: '🎛', category: 'Objects', aliases: ['control_knobs'] },
	{ data: '⏱', category: 'Objects', aliases: ['stopwatch'] },
	{ data: '⏲', category: 'Objects', aliases: ['timer_clock'] },
	{ data: '⏰', category: 'Objects', aliases: ['alarm_clock'] },
	{ data: '🕰', category: 'Objects', aliases: ['mantelpiece_clock'] },
	{ data: '⌛️', category: 'Objects', aliases: ['hourglass'] },
	{ data: '⏳', category: 'Objects', aliases: ['hourglass_flowing_sand'] },
	{ data: '📡', category: 'Objects', aliases: ['satellite'] },
	{ data: '🔋', category: 'Objects', aliases: ['battery'] },
	{ data: '🔌', category: 'Objects', aliases: ['electric_plug'] },
	{ data: '💡', category: 'Objects', aliases: ['bulb'] },
	{ data: '🔦', category: 'Objects', aliases: ['flashlight'] },
	{ data: '🕯', category: 'Objects', aliases: ['candle'] },
	{ data: '🗑', category: 'Objects', aliases: ['wastebasket'] },
	{ data: '🛢', category: 'Objects', aliases: ['oil_drum'] },
	{ data: '💸', category: 'Objects', aliases: ['money_with_wings'] },
	{ data: '💵', category: 'Objects', aliases: ['dollar'] },
	{ data: '💴', category: 'Objects', aliases: ['yen'] },
	{ data: '💶', category: 'Objects', aliases: ['euro'] },
	{ data: '💷', category: 'Objects', aliases: ['pound'] },
	{ data: '💰', category: 'Objects', aliases: ['moneybag'] },
	{ data: '💳', category: 'Objects', aliases: ['credit_card'] },
	{ data: '💎', category: 'Objects', aliases: ['gem'] },
	{ data: '⚖️', category: 'Objects', aliases: ['balance_scale'] },
	{ data: '🔧', category: 'Objects', aliases: ['wrench'] },
	{ data: '🔨', category: 'Objects', aliases: ['hammer'] },
	{ data: '⚒', category: 'Objects', aliases: ['hammer_and_pick'] },
	{ data: '🛠', category: 'Objects', aliases: ['hammer_and_wrench'] },
	{ data: '⛏', category: 'Objects', aliases: ['pick'] },
	{ data: '🔩', category: 'Objects', aliases: ['nut_and_bolt'] },
	{ data: '⚙️', category: 'Objects', aliases: ['gear'] },
	{ data: '⛓', category: 'Objects', aliases: ['chains'] },
	{ data: '🔫', category: 'Objects', aliases: ['gun'] },
	{ data: '💣', category: 'Objects', aliases: ['bomb'] },
	{ data: '🔪', category: 'Objects', aliases: ['hocho', 'knife'] },
	{ data: '🗡', category: 'Objects', aliases: ['dagger'] },
	{ data: '⚔️', category: 'Objects', aliases: ['crossed_swords'] },
	{ data: '🛡', category: 'Objects', aliases: ['shield'] },
	{ data: '🚬', category: 'Objects', aliases: ['smoking'] },
	{ data: '⚰️', category: 'Objects', aliases: ['coffin'] },
	{ data: '⚱️', category: 'Objects', aliases: ['funeral_urn'] },
	{ data: '🏺', category: 'Objects', aliases: ['amphora'] },
	{ data: '🔮', category: 'Objects', aliases: ['crystal_ball'] },
	{ data: '📿', category: 'Objects', aliases: ['prayer_beads'] },
	{ data: '💈', category: 'Objects', aliases: ['barber'] },
	{ data: '⚗️', category: 'Objects', aliases: ['alembic'] },
	{ data: '🔭', category: 'Objects', aliases: ['telescope'] },
	{ data: '🔬', category: 'Objects', aliases: ['microscope'] },
	{ data: '🕳', category: 'Objects', aliases: ['hole'] },
	{ data: '💊', category: 'Objects', aliases: ['pill'] },
	{ data: '💉', category: 'Objects', aliases: ['syringe'] },
	{ data: '🌡', category: 'Objects', aliases: ['thermometer'] },
	{ data: '🚽', category: 'Objects', aliases: ['toilet'] },
	{ data: '🚰', category: 'Objects', aliases: ['potable_water'] },
	{ data: '🚿', category: 'Objects', aliases: ['shower'] },
	{ data: '🛁', category: 'Objects', aliases: ['bathtub'] },
	{ data: '🛀', category: 'Objects', aliases: ['bath'] },
	{ data: '🛎', category: 'Objects', aliases: ['bellhop_bell'] },
	{ data: '🔑', category: 'Objects', aliases: ['key'] },
	{ data: '🗝', category: 'Objects', aliases: ['old_key'] },
	{ data: '🚪', category: 'Objects', aliases: ['door'] },
	{ data: '🛋', category: 'Objects', aliases: ['couch_and_lamp'] },
	{ data: '🛏', category: 'Objects', aliases: ['bed'] },
	{ data: '🛌', category: 'Objects', aliases: ['sleeping_bed'] },
	{ data: '🖼', category: 'Objects', aliases: ['framed_picture'] },
	{ data: '🛍', category: 'Objects', aliases: ['shopping'] },
	{ data: '🛒', category: 'Objects', aliases: ['shopping_cart'] },
	{ data: '🎁', category: 'Objects', aliases: ['gift'] },
	{ data: '🎈', category: 'Objects', aliases: ['balloon'] },
	{ data: '🎏', category: 'Objects', aliases: ['flags'] },
	{ data: '🎀', category: 'Objects', aliases: ['ribbon'] },
	{ data: '🎊', category: 'Objects', aliases: ['confetti_ball'] },
	{ data: '🎉', category: 'Objects', aliases: ['tada'] },
	{ data: '🎎', category: 'Objects', aliases: ['dolls'] },
	{ data: '🏮', category: 'Objects', aliases: ['izakaya_lantern', 'lantern'] },
	{ data: '🎐', category: 'Objects', aliases: ['wind_chime'] },
	{ data: '✉️', category: 'Objects', aliases: ['email', 'envelope'] },
	{ data: '📩', category: 'Objects', aliases: ['envelope_with_arrow'] },
	{ data: '📨', category: 'Objects', aliases: ['incoming_envelope'] },
	{ data: '📧', category: 'Objects', aliases: ['e-mail'] },
	{ data: '💌', category: 'Objects', aliases: ['love_letter'] },
	{ data: '📥', category: 'Objects', aliases: ['inbox_tray'] },
	{ data: '📤', category: 'Objects', aliases: ['outbox_tray'] },
	{ data: '📦', category: 'Objects', aliases: ['package'] },
	{ data: '🏷', category: 'Objects', aliases: ['label'] },
	{ data: '📪', category: 'Objects', aliases: ['mailbox_closed'] },
	{ data: '📫', category: 'Objects', aliases: ['mailbox'] },
	{ data: '📬', category: 'Objects', aliases: ['mailbox_with_mail'] },
	{ data: '📭', category: 'Objects', aliases: ['mailbox_with_no_mail'] },
	{ data: '📮', category: 'Objects', aliases: ['postbox'] },
	{ data: '📯', category: 'Objects', aliases: ['postal_horn'] },
	{ data: '📜', category: 'Objects', aliases: ['scroll'] },
	{ data: '📃', category: 'Objects', aliases: ['page_with_curl'] },
	{ data: '📄', category: 'Objects', aliases: ['page_facing_up'] },
	{ data: '📑', category: 'Objects', aliases: ['bookmark_tabs'] },
	{ data: '📊', category: 'Objects', aliases: ['bar_chart'] },
	{ data: '📈', category: 'Objects', aliases: ['chart_with_upwards_trend'] },
	{ data: '📉', category: 'Objects', aliases: ['chart_with_downwards_trend'] },
	{ data: '🗒', category: 'Objects', aliases: ['spiral_notepad'] },
	{ data: '🗓', category: 'Objects', aliases: ['spiral_calendar'] },
	{ data: '📆', category: 'Objects', aliases: ['calendar'] },
	{ data: '📅', category: 'Objects', aliases: ['date'] },
	{ data: '📇', category: 'Objects', aliases: ['card_index'] },
	{ data: '🗃', category: 'Objects', aliases: ['card_file_box'] },
	{ data: '🗳', category: 'Objects', aliases: ['ballot_box'] },
	{ data: '🗄', category: 'Objects', aliases: ['file_cabinet'] },
	{ data: '📋', category: 'Objects', aliases: ['clipboard'] },
	{ data: '📁', category: 'Objects', aliases: ['file_folder'] },
	{ data: '📂', category: 'Objects', aliases: ['open_file_folder'] },
	{ data: '🗂', category: 'Objects', aliases: ['card_index_dividers'] },
	{ data: '🗞', category: 'Objects', aliases: ['newspaper_roll'] },
	{ data: '📰', category: 'Objects', aliases: ['newspaper'] },
	{ data: '📓', category: 'Objects', aliases: ['notebook'] },
	{ data: '📔', category: 'Objects', aliases: ['notebook_with_decorative_cover'] },
	{ data: '📒', category: 'Objects', aliases: ['ledger'] },
	{ data: '📕', category: 'Objects', aliases: ['closed_book'] },
	{ data: '📗', category: 'Objects', aliases: ['green_book'] },
	{ data: '📘', category: 'Objects', aliases: ['blue_book'] },
	{ data: '📙', category: 'Objects', aliases: ['orange_book'] },
	{ data: '📚', category: 'Objects', aliases: ['books'] },
	{ data: '📖', category: 'Objects', aliases: ['book', 'open_book'] },
	{ data: '🔖', category: 'Objects', aliases: ['bookmark'] },
	{ data: '🔗', category: 'Objects', aliases: ['link'] },
	{ data: '📎', category: 'Objects', aliases: ['paperclip'] },
	{ data: '🖇', category: 'Objects', aliases: ['paperclips'] },
	{ data: '📐', category: 'Objects', aliases: ['triangular_ruler'] },
	{ data: '📏', category: 'Objects', aliases: ['straight_ruler'] },
	{ data: '📌', category: 'Objects', aliases: ['pushpin'] },
	{ data: '📍', category: 'Objects', aliases: ['round_pushpin'] },
	{ data: '✂️', category: 'Objects', aliases: ['scissors'] },
	{ data: '🖊', category: 'Objects', aliases: ['pen'] },
	{ data: '🖋', category: 'Objects', aliases: ['fountain_pen'] },
	{ data: '✒️', category: 'Objects', aliases: ['black_nib'] },
	{ data: '🖌', category: 'Objects', aliases: ['paintbrush'] },
	{ data: '🖍', category: 'Objects', aliases: ['crayon'] },
	{ data: '📝', category: 'Objects', aliases: ['memo', 'pencil'] },
	{ data: '✏️', category: 'Objects', aliases: ['pencil2'] },
	{ data: '🔍', category: 'Objects', aliases: ['mag'] },
	{ data: '🔎', category: 'Objects', aliases: ['mag_right'] },
	{ data: '🔏', category: 'Objects', aliases: ['lock_with_ink_pen'] },
	{ data: '🔐', category: 'Objects', aliases: ['closed_lock_with_key'] },
	{ data: '🔒', category: 'Objects', aliases: ['lock'] },
	{ data: '🔓', category: 'Objects', aliases: ['unlock'] },
	{ data: '❤️', category: 'Symbols', aliases: ['heart'] },
	{ data: '💛', category: 'Symbols', aliases: ['yellow_heart'] },
	{ data: '💚', category: 'Symbols', aliases: ['green_heart'] },
	{ data: '💙', category: 'Symbols', aliases: ['blue_heart'] },
	{ data: '💜', category: 'Symbols', aliases: ['purple_heart'] },
	{ data: '🖤', category: 'Symbols', aliases: ['black_heart'] },
	{ data: '💔', category: 'Symbols', aliases: ['broken_heart'] },
	{ data: '❣️', category: 'Symbols', aliases: ['heavy_heart_exclamation'] },
	{ data: '💕', category: 'Symbols', aliases: ['two_hearts'] },
	{ data: '💞', category: 'Symbols', aliases: ['revolving_hearts'] },
	{ data: '💓', category: 'Symbols', aliases: ['heartbeat'] },
	{ data: '💗', category: 'Symbols', aliases: ['heartpulse'] },
	{ data: '💖', category: 'Symbols', aliases: ['sparkling_heart'] },
	{ data: '💘', category: 'Symbols', aliases: ['cupid'] },
	{ data: '💝', category: 'Symbols', aliases: ['gift_heart'] },
	{ data: '💟', category: 'Symbols', aliases: ['heart_decoration'] },
	{ data: '☮️', category: 'Symbols', aliases: ['peace_symbol'] },
	{ data: '✝️', category: 'Symbols', aliases: ['latin_cross'] },
	{ data: '☪️', category: 'Symbols', aliases: ['star_and_crescent'] },
	{ data: '🕉', category: 'Symbols', aliases: ['om'] },
	{ data: '☸️', category: 'Symbols', aliases: ['wheel_of_dharma'] },
	{ data: '✡️', category: 'Symbols', aliases: ['star_of_david'] },
	{ data: '🔯', category: 'Symbols', aliases: ['six_pointed_star'] },
	{ data: '🕎', category: 'Symbols', aliases: ['menorah'] },
	{ data: '☯️', category: 'Symbols', aliases: ['yin_yang'] },
	{ data: '☦️', category: 'Symbols', aliases: ['orthodox_cross'] },
	{ data: '🛐', category: 'Symbols', aliases: ['place_of_worship'] },
	{ data: '⛎', category: 'Symbols', aliases: ['ophiuchus'] },
	{ data: '♈️', category: 'Symbols', aliases: ['aries'] },
	{ data: '♉️', category: 'Symbols', aliases: ['taurus'] },
	{ data: '♊️', category: 'Symbols', aliases: ['gemini'] },
	{ data: '♋️', category: 'Symbols', aliases: ['cancer'] },
	{ data: '♌️', category: 'Symbols', aliases: ['leo'] },
	{ data: '♍️', category: 'Symbols', aliases: ['virgo'] },
	{ data: '♎️', category: 'Symbols', aliases: ['libra'] },
	{ data: '♏️', category: 'Symbols', aliases: ['scorpius'] },
	{ data: '♐️', category: 'Symbols', aliases: ['sagittarius'] },
	{ data: '♑️', category: 'Symbols', aliases: ['capricorn'] },
	{ data: '♒️', category: 'Symbols', aliases: ['aquarius'] },
	{ data: '♓️', category: 'Symbols', aliases: ['pisces'] },
	{ data: '🆔', category: 'Symbols', aliases: ['id'] },
	{ data: '⚛️', category: 'Symbols', aliases: ['atom_symbol'] },
	{ data: '🉑', category: 'Symbols', aliases: ['accept'] },
	{ data: '☢️', category: 'Symbols', aliases: ['radioactive'] },
	{ data: '☣️', category: 'Symbols', aliases: ['biohazard'] },
	{ data: '📴', category: 'Symbols', aliases: ['mobile_phone_off'] },
	{ data: '📳', category: 'Symbols', aliases: ['vibration_mode'] },
	{ data: '🈶', category: 'Symbols', aliases: ['u6709'] },
	{ data: '🈚️', category: 'Symbols', aliases: ['u7121'] },
	{ data: '🈸', category: 'Symbols', aliases: ['u7533'] },
	{ data: '🈺', category: 'Symbols', aliases: ['u55b6'] },
	{ data: '🈷️', category: 'Symbols', aliases: ['u6708'] },
	{ data: '✴️', category: 'Symbols', aliases: ['eight_pointed_black_star'] },
	{ data: '🆚', category: 'Symbols', aliases: ['vs'] },
	{ data: '💮', category: 'Symbols', aliases: ['white_flower'] },
	{ data: '🉐', category: 'Symbols', aliases: ['ideograph_advantage'] },
	{ data: '㊙️', category: 'Symbols', aliases: ['secret'] },
	{ data: '㊗️', category: 'Symbols', aliases: ['congratulations'] },
	{ data: '🈴', category: 'Symbols', aliases: ['u5408'] },
	{ data: '🈵', category: 'Symbols', aliases: ['u6e80'] },
	{ data: '🈹', category: 'Symbols', aliases: ['u5272'] },
	{ data: '🈲', category: 'Symbols', aliases: ['u7981'] },
	{ data: '🅰️', category: 'Symbols', aliases: ['a'] },
	{ data: '🅱️', category: 'Symbols', aliases: ['b'] },
	{ data: '🆎', category: 'Symbols', aliases: ['ab'] },
	{ data: '🆑', category: 'Symbols', aliases: ['cl'] },
	{ data: '🅾️', category: 'Symbols', aliases: ['o2'] },
	{ data: '🆘', category: 'Symbols', aliases: ['sos'] },
	{ data: '❌', category: 'Symbols', aliases: ['x'] },
	{ data: '⭕️', category: 'Symbols', aliases: ['o'] },
	{ data: '🛑', category: 'Symbols', aliases: ['stop_sign'] },
	{ data: '⛔️', category: 'Symbols', aliases: ['no_entry'] },
	{ data: '📛', category: 'Symbols', aliases: ['name_badge'] },
	{ data: '🚫', category: 'Symbols', aliases: ['no_entry_sign'] },
	{ data: '💯', category: 'Symbols', aliases: ['100'] },
	{ data: '💢', category: 'Symbols', aliases: ['anger'] },
	{ data: '♨️', category: 'Symbols', aliases: ['hotsprings'] },
	{ data: '🚷', category: 'Symbols', aliases: ['no_pedestrians'] },
	{ data: '🚯', category: 'Symbols', aliases: ['do_not_litter'] },
	{ data: '🚳', category: 'Symbols', aliases: ['no_bicycles'] },
	{ data: '🚱', category: 'Symbols', aliases: ['non-potable_water'] },
	{ data: '🔞', category: 'Symbols', aliases: ['underage'] },
	{ data: '📵', category: 'Symbols', aliases: ['no_mobile_phones'] },
	{ data: '🚭', category: 'Symbols', aliases: ['no_smoking'] },
	{ data: '❗️', category: 'Symbols', aliases: ['exclamation', 'heavy_exclamation_mark'] },
	{ data: '❕', category: 'Symbols', aliases: ['grey_exclamation'] },
	{ data: '❓', category: 'Symbols', aliases: ['question'] },
	{ data: '❔', category: 'Symbols', aliases: ['grey_question'] },
	{ data: '‼️', category: 'Symbols', aliases: ['bangbang'] },
	{ data: '⁉️', category: 'Symbols', aliases: ['interrobang'] },
	{ data: '🔅', category: 'Symbols', aliases: ['low_brightness'] },
	{ data: '🔆', category: 'Symbols', aliases: ['high_brightness'] },
	{ data: '〽️', category: 'Symbols', aliases: ['part_alternation_mark'] },
	{ data: '⚠️', category: 'Symbols', aliases: ['warning'] },
	{ data: '🚸', category: 'Symbols', aliases: ['children_crossing'] },
	{ data: '🔱', category: 'Symbols', aliases: ['trident'] },
	{ data: '⚜️', category: 'Symbols', aliases: ['fleur_de_lis'] },
	{ data: '🔰', category: 'Symbols', aliases: ['beginner'] },
	{ data: '♻️', category: 'Symbols', aliases: ['recycle'] },
	{ data: '✅', category: 'Symbols', aliases: ['white_check_mark'] },
	{ data: '🈯️', category: 'Symbols', aliases: ['u6307'] },
	{ data: '💹', category: 'Symbols', aliases: ['chart'] },
	{ data: '❇️', category: 'Symbols', aliases: ['sparkle'] },
	{ data: '✳️', category: 'Symbols', aliases: ['eight_spoked_asterisk'] },
	{ data: '❎', category: 'Symbols', aliases: ['negative_squared_cross_mark'] },
	{ data: '🌐', category: 'Symbols', aliases: ['globe_with_meridians'] },
	{ data: '💠', category: 'Symbols', aliases: ['diamond_shape_with_a_dot_inside'] },
	{ data: 'Ⓜ️', category: 'Symbols', aliases: ['m'] },
	{ data: '🌀', category: 'Symbols', aliases: ['cyclone'] },
	{ data: '💤', category: 'Symbols', aliases: ['zzz'] },
	{ data: '🏧', category: 'Symbols', aliases: ['atm'] },
	{ data: '🚾', category: 'Symbols', aliases: ['wc'] },
	{ data: '♿️', category: 'Symbols', aliases: ['wheelchair'] },
	{ data: '🅿️', category: 'Symbols', aliases: ['parking'] },
	{ data: '🈳', category: 'Symbols', aliases: ['u7a7a'] },
	{ data: '🈂️', category: 'Symbols', aliases: ['sa'] },
	{ data: '🛂', category: 'Symbols', aliases: ['passport_control'] },
	{ data: '🛃', category: 'Symbols', aliases: ['customs'] },
	{ data: '🛄', category: 'Symbols', aliases: ['baggage_claim'] },
	{ data: '🛅', category: 'Symbols', aliases: ['left_luggage'] },
	{ data: '🚹', category: 'Symbols', aliases: ['mens'] },
	{ data: '🚺', category: 'Symbols', aliases: ['womens'] },
	{ data: '🚼', category: 'Symbols', aliases: ['baby_symbol'] },
	{ data: '🚻', category: 'Symbols', aliases: ['restroom'] },
	{ data: '🚮', category: 'Symbols', aliases: ['put_litter_in_its_place'] },
	{ data: '🎦', category: 'Symbols', aliases: ['cinema'] },
	{ data: '📶', category: 'Symbols', aliases: ['signal_strength'] },
	{ data: '🈁', category: 'Symbols', aliases: ['koko'] },
	{ data: '🔣', category: 'Symbols', aliases: ['symbols'] },
	{ data: 'ℹ️', category: 'Symbols', aliases: ['information_source'] },
	{ data: '🔤', category: 'Symbols', aliases: ['abc'] },
	{ data: '🔡', category: 'Symbols', aliases: ['abcd'] },
	{ data: '🔠', category: 'Symbols', aliases: ['capital_abcd'] },
	{ data: '🆖', category: 'Symbols', aliases: ['ng'] },
	{ data: '🆗', category: 'Symbols', aliases: ['ok'] },
	{ data: '🆙', category: 'Symbols', aliases: ['up'] },
	{ data: '🆒', category: 'Symbols', aliases: ['cool'] },
	{ data: '🆕', category: 'Symbols', aliases: ['new'] },
	{ data: '🆓', category: 'Symbols', aliases: ['free'] },
	{ data: '0️⃣', category: 'Symbols', aliases: ['zero'] },
	{ data: '1️⃣', category: 'Symbols', aliases: ['one'] },
	{ data: '2️⃣', category: 'Symbols', aliases: ['two'] },
	{ data: '3️⃣', category: 'Symbols', aliases: ['three'] },
	{ data: '4️⃣', category: 'Symbols', aliases: ['four'] },
	{ data: '5️⃣', category: 'Symbols', aliases: ['five'] },
	{ data: '6️⃣', category: 'Symbols', aliases: ['six'] },
	{ data: '7️⃣', category: 'Symbols', aliases: ['seven'] },
	{ data: '8️⃣', category: 'Symbols', aliases: ['eight'] },
	{ data: '9️⃣', category: 'Symbols', aliases: ['nine'] },
	{ data: '🔟', category: 'Symbols', aliases: ['keycap_ten'] },
	{ data: '🔢', category: 'Symbols', aliases: ['1234'] },
	{ data: '#️⃣', category: 'Symbols', aliases: ['hash'] },
	{ data: '*️⃣', category: 'Symbols', aliases: ['asterisk'] },
	{ data: '▶️', category: 'Symbols', aliases: ['arrow_forward'] },
	{ data: '⏸', category: 'Symbols', aliases: ['pause_button'] },
	{ data: '⏯', category: 'Symbols', aliases: ['play_or_pause_button'] },
	{ data: '⏹', category: 'Symbols', aliases: ['stop_button'] },
	{ data: '⏺', category: 'Symbols', aliases: ['record_button'] },
	{ data: '⏭', category: 'Symbols', aliases: ['next_track_button'] },
	{ data: '⏮', category: 'Symbols', aliases: ['previous_track_button'] },
	{ data: '⏩', category: 'Symbols', aliases: ['fast_forward'] },
	{ data: '⏪', category: 'Symbols', aliases: ['rewind'] },
	{ data: '⏫', category: 'Symbols', aliases: ['arrow_double_up'] },
	{ data: '⏬', category: 'Symbols', aliases: ['arrow_double_down'] },
	{ data: '◀️', category: 'Symbols', aliases: ['arrow_backward'] },
	{ data: '🔼', category: 'Symbols', aliases: ['arrow_up_small'] },
	{ data: '🔽', category: 'Symbols', aliases: ['arrow_down_small'] },
	{ data: '➡️', category: 'Symbols', aliases: ['arrow_right'] },
	{ data: '⬅️', category: 'Symbols', aliases: ['arrow_left'] },
	{ data: '⬆️', category: 'Symbols', aliases: ['arrow_up'] },
	{ data: '⬇️', category: 'Symbols', aliases: ['arrow_down'] },
	{ data: '↗️', category: 'Symbols', aliases: ['arrow_upper_right'] },
	{ data: '↘️', category: 'Symbols', aliases: ['arrow_lower_right'] },
	{ data: '↙️', category: 'Symbols', aliases: ['arrow_lower_left'] },
	{ data: '↖️', category: 'Symbols', aliases: ['arrow_upper_left'] },
	{ data: '↕️', category: 'Symbols', aliases: ['arrow_up_down'] },
	{ data: '↔️', category: 'Symbols', aliases: ['left_right_arrow'] },
	{ data: '↪️', category: 'Symbols', aliases: ['arrow_right_hook'] },
	{ data: '↩️', category: 'Symbols', aliases: ['leftwards_arrow_with_hook'] },
	{ data: '⤴️', category: 'Symbols', aliases: ['arrow_heading_up'] },
	{ data: '⤵️', category: 'Symbols', aliases: ['arrow_heading_down'] },
	{ data: '🔀', category: 'Symbols', aliases: ['twisted_rightwards_arrows'] },
	{ data: '🔁', category: 'Symbols', aliases: ['repeat'] },
	{ data: '🔂', category: 'Symbols', aliases: ['repeat_one'] },
	{ data: '🔄', category: 'Symbols', aliases: ['arrows_counterclockwise'] },
	{ data: '🔃', category: 'Symbols', aliases: ['arrows_clockwise'] },
	{ data: '🎵', category: 'Symbols', aliases: ['musical_note'] },
	{ data: '🎶', category: 'Symbols', aliases: ['notes'] },
	{ data: '➕', category: 'Symbols', aliases: ['heavy_plus_sign'] },
	{ data: '➖', category: 'Symbols', aliases: ['heavy_minus_sign'] },
	{ data: '➗', category: 'Symbols', aliases: ['heavy_division_sign'] },
	{ data: '✖️', category: 'Symbols', aliases: ['heavy_multiplication_x'] },
	{ data: '💲', category: 'Symbols', aliases: ['heavy_dollar_sign'] },
	{ data: '💱', category: 'Symbols', aliases: ['currency_exchange'] },
	{ data: '™️', category: 'Symbols', aliases: ['tm'] },
	{ data: '©️', category: 'Symbols', aliases: ['copyright'] },
	{ data: '®️', category: 'Symbols', aliases: ['registered'] },
	{ data: '〰️', category: 'Symbols', aliases: ['wavy_dash'] },
	{ data: '➰', category: 'Symbols', aliases: ['curly_loop'] },
	{ data: '➿', category: 'Symbols', aliases: ['loop'] },
	{ data: '🔚', category: 'Symbols', aliases: ['end'] },
	{ data: '🔙', category: 'Symbols', aliases: ['back'] },
	{ data: '🔛', category: 'Symbols', aliases: ['on'] },
	{ data: '🔝', category: 'Symbols', aliases: ['top'] },
	{ data: '🔜', category: 'Symbols', aliases: ['soon'] },
	{ data: '✔️', category: 'Symbols', aliases: ['heavy_check_mark'] },
	{ data: '☑️', category: 'Symbols', aliases: ['ballot_box_with_check'] },
	{ data: '🔘', category: 'Symbols', aliases: ['radio_button'] },
	{ data: '⚪️', category: 'Symbols', aliases: ['white_circle'] },
	{ data: '⚫️', category: 'Symbols', aliases: ['black_circle'] },
	{ data: '🔴', category: 'Symbols', aliases: ['red_circle'] },
	{ data: '🔵', category: 'Symbols', aliases: ['large_blue_circle'] },
	{ data: '🔺', category: 'Symbols', aliases: ['small_red_triangle'] },
	{ data: '🔻', category: 'Symbols', aliases: ['small_red_triangle_down'] },
	{ data: '🔸', category: 'Symbols', aliases: ['small_orange_diamond'] },
	{ data: '🔹', category: 'Symbols', aliases: ['small_blue_diamond'] },
	{ data: '🔶', category: 'Symbols', aliases: ['large_orange_diamond'] },
	{ data: '🔷', category: 'Symbols', aliases: ['large_blue_diamond'] },
	{ data: '🔳', category: 'Symbols', aliases: ['white_square_button'] },
	{ data: '🔲', category: 'Symbols', aliases: ['black_square_button'] },
	{ data: '▪️', category: 'Symbols', aliases: ['black_small_square'] },
	{ data: '▫️', category: 'Symbols', aliases: ['white_small_square'] },
	{ data: '◾️', category: 'Symbols', aliases: ['black_medium_small_square'] },
	{ data: '◽️', category: 'Symbols', aliases: ['white_medium_small_square'] },
	{ data: '◼️', category: 'Symbols', aliases: ['black_medium_square'] },
	{ data: '◻️', category: 'Symbols', aliases: ['white_medium_square'] },
	{ data: '⬛️', category: 'Symbols', aliases: ['black_large_square'] },
	{ data: '⬜️', category: 'Symbols', aliases: ['white_large_square'] },
	{ data: '🔈', category: 'Symbols', aliases: ['speaker'] },
	{ data: '🔇', category: 'Symbols', aliases: ['mute'] },
	{ data: '🔉', category: 'Symbols', aliases: ['sound'] },
	{ data: '🔊', category: 'Symbols', aliases: ['loud_sound'] },
	{ data: '🔔', category: 'Symbols', aliases: ['bell'] },
	{ data: '🔕', category: 'Symbols', aliases: ['no_bell'] },
	{ data: '📣', category: 'Symbols', aliases: ['mega'] },
	{ data: '📢', category: 'Symbols', aliases: ['loudspeaker'] },
	{ data: '👁‍🗨', category: 'Symbols', aliases: ['eye_speech_bubble'] },
	{ data: '💬', category: 'Symbols', aliases: ['speech_balloon'] },
	{ data: '💭', category: 'Symbols', aliases: ['thought_balloon'] },
	{ data: '🗯', category: 'Symbols', aliases: ['right_anger_bubble'] },
	{ data: '♠️', category: 'Symbols', aliases: ['spades'] },
	{ data: '♣️', category: 'Symbols', aliases: ['clubs'] },
	{ data: '♥️', category: 'Symbols', aliases: ['hearts'] },
	{ data: '♦️', category: 'Symbols', aliases: ['diamonds'] },
	{ data: '🃏', category: 'Symbols', aliases: ['black_joker'] },
	{ data: '🎴', category: 'Symbols', aliases: ['flower_playing_cards'] },
	{ data: '🀄️', category: 'Symbols', aliases: ['mahjong'] },
	{ data: '🕐', category: 'Symbols', aliases: ['clock1'] },
	{ data: '🕑', category: 'Symbols', aliases: ['clock2'] },
	{ data: '🕒', category: 'Symbols', aliases: ['clock3'] },
	{ data: '🕓', category: 'Symbols', aliases: ['clock4'] },
	{ data: '🕔', category: 'Symbols', aliases: ['clock5'] },
	{ data: '🕕', category: 'Symbols', aliases: ['clock6'] },
	{ data: '🕖', category: 'Symbols', aliases: ['clock7'] },
	{ data: '🕗', category: 'Symbols', aliases: ['clock8'] },
	{ data: '🕘', category: 'Symbols', aliases: ['clock9'] },
	{ data: '🕙', category: 'Symbols', aliases: ['clock10'] },
	{ data: '🕚', category: 'Symbols', aliases: ['clock11'] },
	{ data: '🕛', category: 'Symbols', aliases: ['clock12'] },
	{ data: '🕜', category: 'Symbols', aliases: ['clock130'] },
	{ data: '🕝', category: 'Symbols', aliases: ['clock230'] },
	{ data: '🕞', category: 'Symbols', aliases: ['clock330'] },
	{ data: '🕟', category: 'Symbols', aliases: ['clock430'] },
	{ data: '🕠', category: 'Symbols', aliases: ['clock530'] },
	{ data: '🕡', category: 'Symbols', aliases: ['clock630'] },
	{ data: '🕢', category: 'Symbols', aliases: ['clock730'] },
	{ data: '🕣', category: 'Symbols', aliases: ['clock830'] },
	{ data: '🕤', category: 'Symbols', aliases: ['clock930'] },
	{ data: '🕥', category: 'Symbols', aliases: ['clock1030'] },
	{ data: '🕦', category: 'Symbols', aliases: ['clock1130'] },
	{ data: '🕧', category: 'Symbols', aliases: ['clock1230'] },
	{ data: '🏳️', category: 'Flags', aliases: ['white_flag'] },
	{ data: '🏴', category: 'Flags', aliases: ['black_flag'] },
	{ data: '🏁', category: 'Flags', aliases: ['checkered_flag'] },
	{ data: '🚩', category: 'Flags', aliases: ['triangular_flag_on_post'] },
	{ data: '🏳️‍🌈', category: 'Flags', aliases: ['rainbow_flag'] },
	{ data: '🇦🇫', category: 'Flags', aliases: ['afghanistan'] },
	{ data: '🇦🇽', category: 'Flags', aliases: ['aland_islands'] },
	{ data: '🇦🇱', category: 'Flags', aliases: ['albania'] },
	{ data: '🇩🇿', category: 'Flags', aliases: ['algeria'] },
	{ data: '🇦🇸', category: 'Flags', aliases: ['american_samoa'] },
	{ data: '🇦🇩', category: 'Flags', aliases: ['andorra'] },
	{ data: '🇦🇴', category: 'Flags', aliases: ['angola'] },
	{ data: '🇦🇮', category: 'Flags', aliases: ['anguilla'] },
	{ data: '🇦🇶', category: 'Flags', aliases: ['antarctica'] },
	{ data: '🇦🇬', category: 'Flags', aliases: ['antigua_barbuda'] },
	{ data: '🇦🇷', category: 'Flags', aliases: ['argentina'] },
	{ data: '🇦🇲', category: 'Flags', aliases: ['armenia'] },
	{ data: '🇦🇼', category: 'Flags', aliases: ['aruba'] },
	{ data: '🇦🇺', category: 'Flags', aliases: ['australia'] },
	{ data: '🇦🇹', category: 'Flags', aliases: ['austria'] },
	{ data: '🇦🇿', category: 'Flags', aliases: ['azerbaijan'] },
	{ data: '🇧🇸', category: 'Flags', aliases: ['bahamas'] },
	{ data: '🇧🇭', category: 'Flags', aliases: ['bahrain'] },
	{ data: '🇧🇩', category: 'Flags', aliases: ['bangladesh'] },
	{ data: '🇧🇧', category: 'Flags', aliases: ['barbados'] },
	{ data: '🇧🇾', category: 'Flags', aliases: ['belarus'] },
	{ data: '🇧🇪', category: 'Flags', aliases: ['belgium'] },
	{ data: '🇧🇿', category: 'Flags', aliases: ['belize'] },
	{ data: '🇧🇯', category: 'Flags', aliases: ['benin'] },
	{ data: '🇧🇲', category: 'Flags', aliases: ['bermuda'] },
	{ data: '🇧🇹', category: 'Flags', aliases: ['bhutan'] },
	{ data: '🇧🇴', category: 'Flags', aliases: ['bolivia'] },
	{ data: '🇧🇶', category: 'Flags', aliases: ['caribbean_netherlands'] },
	{ data: '🇧🇦', category: 'Flags', aliases: ['bosnia_herzegovina'] },
	{ data: '🇧🇼', category: 'Flags', aliases: ['botswana'] },
	{ data: '🇧🇷', category: 'Flags', aliases: ['brazil'] },
	{ data: '🇮🇴', category: 'Flags', aliases: ['british_indian_ocean_territory'] },
	{ data: '🇻🇬', category: 'Flags', aliases: ['british_virgin_islands'] },
	{ data: '🇧🇳', category: 'Flags', aliases: ['brunei'] },
	{ data: '🇧🇬', category: 'Flags', aliases: ['bulgaria'] },
	{ data: '🇧🇫', category: 'Flags', aliases: ['burkina_faso'] },
	{ data: '🇧🇮', category: 'Flags', aliases: ['burundi'] },
	{ data: '🇨🇻', category: 'Flags', aliases: ['cape_verde'] },
	{ data: '🇰🇭', category: 'Flags', aliases: ['cambodia'] },
	{ data: '🇨🇲', category: 'Flags', aliases: ['cameroon'] },
	{ data: '🇨🇦', category: 'Flags', aliases: ['canada'] },
	{ data: '🇮🇨', category: 'Flags', aliases: ['canary_islands'] },
	{ data: '🇰🇾', category: 'Flags', aliases: ['cayman_islands'] },
	{ data: '🇨🇫', category: 'Flags', aliases: ['central_african_republic'] },
	{ data: '🇹🇩', category: 'Flags', aliases: ['chad'] },
	{ data: '🇨🇱', category: 'Flags', aliases: ['chile'] },
	{ data: '🇨🇳', category: 'Flags', aliases: ['cn'] },
	{ data: '🇨🇽', category: 'Flags', aliases: ['christmas_island'] },
	{ data: '🇨🇨', category: 'Flags', aliases: ['cocos_islands'] },
	{ data: '🇨🇴', category: 'Flags', aliases: ['colombia'] },
	{ data: '🇰🇲', category: 'Flags', aliases: ['comoros'] },
	{ data: '🇨🇬', category: 'Flags', aliases: ['congo_brazzaville'] },
	{ data: '🇨🇩', category: 'Flags', aliases: ['congo_kinshasa'] },
	{ data: '🇨🇰', category: 'Flags', aliases: ['cook_islands'] },
	{ data: '🇨🇷', category: 'Flags', aliases: ['costa_rica'] },
	{ data: '🇨🇮', category: 'Flags', aliases: ['cote_divoire'] },
	{ data: '🇭🇷', category: 'Flags', aliases: ['croatia'] },
	{ data: '🇨🇺', category: 'Flags', aliases: ['cuba'] },
	{ data: '🇨🇼', category: 'Flags', aliases: ['curacao'] },
	{ data: '🇨🇾', category: 'Flags', aliases: ['cyprus'] },
	{ data: '🇨🇿', category: 'Flags', aliases: ['czech_republic'] },
	{ data: '🇩🇰', category: 'Flags', aliases: ['denmark'] },
	{ data: '🇩🇯', category: 'Flags', aliases: ['djibouti'] },
	{ data: '🇩🇲', category: 'Flags', aliases: ['dominica'] },
	{ data: '🇩🇴', category: 'Flags', aliases: ['dominican_republic'] },
	{ data: '🇪🇨', category: 'Flags', aliases: ['ecuador'] },
	{ data: '🇪🇬', category: 'Flags', aliases: ['egypt'] },
	{ data: '🇸🇻', category: 'Flags', aliases: ['el_salvador'] },
	{ data: '🇬🇶', category: 'Flags', aliases: ['equatorial_guinea'] },
	{ data: '🇪🇷', category: 'Flags', aliases: ['eritrea'] },
	{ data: '🇪🇪', category: 'Flags', aliases: ['estonia'] },
	{ data: '🇪🇹', category: 'Flags', aliases: ['ethiopia'] },
	{ data: '🇪🇺', category: 'Flags', aliases: ['eu', 'european_union'] },
	{ data: '🇫🇰', category: 'Flags', aliases: ['falkland_islands'] },
	{ data: '🇫🇴', category: 'Flags', aliases: ['faroe_islands'] },
	{ data: '🇫🇯', category: 'Flags', aliases: ['fiji'] },
	{ data: '🇫🇮', category: 'Flags', aliases: ['finland'] },
	{ data: '🇫🇷', category: 'Flags', aliases: ['fr'] },
	{ data: '🇬🇫', category: 'Flags', aliases: ['french_guiana'] },
	{ data: '🇵🇫', category: 'Flags', aliases: ['french_polynesia'] },
	{ data: '🇹🇫', category: 'Flags', aliases: ['french_southern_territories'] },
	{ data: '🇬🇦', category: 'Flags', aliases: ['gabon'] },
	{ data: '🇬🇲', category: 'Flags', aliases: ['gambia'] },
	{ data: '🇬🇪', category: 'Flags', aliases: ['georgia'] },
	{ data: '🇩🇪', category: 'Flags', aliases: ['de'] },
	{ data: '🇬🇭', category: 'Flags', aliases: ['ghana'] },
	{ data: '🇬🇮', category: 'Flags', aliases: ['gibraltar'] },
	{ data: '🇬🇷', category: 'Flags', aliases: ['greece'] },
	{ data: '🇬🇱', category: 'Flags', aliases: ['greenland'] },
	{ data: '🇬🇩', category: 'Flags', aliases: ['grenada'] },
	{ data: '🇬🇵', category: 'Flags', aliases: ['guadeloupe'] },
	{ data: '🇬🇺', category: 'Flags', aliases: ['guam'] },
	{ data: '🇬🇹', category: 'Flags', aliases: ['guatemala'] },
	{ data: '🇬🇬', category: 'Flags', aliases: ['guernsey'] },
	{ data: '🇬🇳', category: 'Flags', aliases: ['guinea'] },
	{ data: '🇬🇼', category: 'Flags', aliases: ['guinea_bissau'] },
	{ data: '🇬🇾', category: 'Flags', aliases: ['guyana'] },
	{ data: '🇭🇹', category: 'Flags', aliases: ['haiti'] },
	{ data: '🇭🇳', category: 'Flags', aliases: ['honduras'] },
	{ data: '🇭🇰', category: 'Flags', aliases: ['hong_kong'] },
	{ data: '🇭🇺', category: 'Flags', aliases: ['hungary'] },
	{ data: '🇮🇸', category: 'Flags', aliases: ['iceland'] },
	{ data: '🇮🇳', category: 'Flags', aliases: ['india'] },
	{ data: '🇮🇩', category: 'Flags', aliases: ['indonesia'] },
	{ data: '🇮🇷', category: 'Flags', aliases: ['iran'] },
	{ data: '🇮🇶', category: 'Flags', aliases: ['iraq'] },
	{ data: '🇮🇪', category: 'Flags', aliases: ['ireland'] },
	{ data: '🇮🇲', category: 'Flags', aliases: ['isle_of_man'] },
	{ data: '🇮🇱', category: 'Flags', aliases: ['israel'] },
	{ data: '🇮🇹', category: 'Flags', aliases: ['it'] },
	{ data: '🇯🇲', category: 'Flags', aliases: ['jamaica'] },
	{ data: '🇯🇵', category: 'Flags', aliases: ['jp'] },
	{ data: '🎌', category: 'Flags', aliases: ['crossed_flags'] },
	{ data: '🇯🇪', category: 'Flags', aliases: ['jersey'] },
	{ data: '🇯🇴', category: 'Flags', aliases: ['jordan'] },
	{ data: '🇰🇿', category: 'Flags', aliases: ['kazakhstan'] },
	{ data: '🇰🇪', category: 'Flags', aliases: ['kenya'] },
	{ data: '🇰🇮', category: 'Flags', aliases: ['kiribati'] },
	{ data: '🇽🇰', category: 'Flags', aliases: ['kosovo'] },
	{ data: '🇰🇼', category: 'Flags', aliases: ['kuwait'] },
	{ data: '🇰🇬', category: 'Flags', aliases: ['kyrgyzstan'] },
	{ data: '🇱🇦', category: 'Flags', aliases: ['laos'] },
	{ data: '🇱🇻', category: 'Flags', aliases: ['latvia'] },
	{ data: '🇱🇧', category: 'Flags', aliases: ['lebanon'] },
	{ data: '🇱🇸', category: 'Flags', aliases: ['lesotho'] },
	{ data: '🇱🇷', category: 'Flags', aliases: ['liberia'] },
	{ data: '🇱🇾', category: 'Flags', aliases: ['libya'] },
	{ data: '🇱🇮', category: 'Flags', aliases: ['liechtenstein'] },
	{ data: '🇱🇹', category: 'Flags', aliases: ['lithuania'] },
	{ data: '🇱🇺', category: 'Flags', aliases: ['luxembourg'] },
	{ data: '🇲🇴', category: 'Flags', aliases: ['macau'] },
	{ data: '🇲🇰', category: 'Flags', aliases: ['macedonia'] },
	{ data: '🇲🇬', category: 'Flags', aliases: ['madagascar'] },
	{ data: '🇲🇼', category: 'Flags', aliases: ['malawi'] },
	{ data: '🇲🇾', category: 'Flags', aliases: ['malaysia'] },
	{ data: '🇲🇻', category: 'Flags', aliases: ['maldives'] },
	{ data: '🇲🇱', category: 'Flags', aliases: ['mali'] },
	{ data: '🇲🇹', category: 'Flags', aliases: ['malta'] },
	{ data: '🇲🇭', category: 'Flags', aliases: ['marshall_islands'] },
	{ data: '🇲🇶', category: 'Flags', aliases: ['martinique'] },
	{ data: '🇲🇷', category: 'Flags', aliases: ['mauritania'] },
	{ data: '🇲🇺', category: 'Flags', aliases: ['mauritius'] },
	{ data: '🇾🇹', category: 'Flags', aliases: ['mayotte'] },
	{ data: '🇲🇽', category: 'Flags', aliases: ['mexico'] },
	{ data: '🇫🇲', category: 'Flags', aliases: ['micronesia'] },
	{ data: '🇲🇩', category: 'Flags', aliases: ['moldova'] },
	{ data: '🇲🇨', category: 'Flags', aliases: ['monaco'] },
	{ data: '🇲🇳', category: 'Flags', aliases: ['mongolia'] },
	{ data: '🇲🇪', category: 'Flags', aliases: ['montenegro'] },
	{ data: '🇲🇸', category: 'Flags', aliases: ['montserrat'] },
	{ data: '🇲🇦', category: 'Flags', aliases: ['morocco'] },
	{ data: '🇲🇿', category: 'Flags', aliases: ['mozambique'] },
	{ data: '🇲🇲', category: 'Flags', aliases: ['myanmar'] },
	{ data: '🇳🇦', category: 'Flags', aliases: ['namibia'] },
	{ data: '🇳🇷', category: 'Flags', aliases: ['nauru'] },
	{ data: '🇳🇵', category: 'Flags', aliases: ['nepal'] },
	{ data: '🇳🇱', category: 'Flags', aliases: ['netherlands'] },
	{ data: '🇳🇨', category: 'Flags', aliases: ['new_caledonia'] },
	{ data: '🇳🇿', category: 'Flags', aliases: ['new_zealand'] },
	{ data: '🇳🇮', category: 'Flags', aliases: ['nicaragua'] },
	{ data: '🇳🇪', category: 'Flags', aliases: ['niger'] },
	{ data: '🇳🇬', category: 'Flags', aliases: ['nigeria'] },
	{ data: '🇳🇺', category: 'Flags', aliases: ['niue'] },
	{ data: '🇳🇫', category: 'Flags', aliases: ['norfolk_island'] },
	{ data: '🇲🇵', category: 'Flags', aliases: ['northern_mariana_islands'] },
	{ data: '🇰🇵', category: 'Flags', aliases: ['north_korea'] },
	{ data: '🇳🇴', category: 'Flags', aliases: ['norway'] },
	{ data: '🇴🇲', category: 'Flags', aliases: ['oman'] },
	{ data: '🇵🇰', category: 'Flags', aliases: ['pakistan'] },
	{ data: '🇵🇼', category: 'Flags', aliases: ['palau'] },
	{ data: '🇵🇸', category: 'Flags', aliases: ['palestinian_territories'] },
	{ data: '🇵🇦', category: 'Flags', aliases: ['panama'] },
	{ data: '🇵🇬', category: 'Flags', aliases: ['papua_new_guinea'] },
	{ data: '🇵🇾', category: 'Flags', aliases: ['paraguay'] },
	{ data: '🇵🇪', category: 'Flags', aliases: ['peru'] },
	{ data: '🇵🇭', category: 'Flags', aliases: ['philippines'] },
	{ data: '🇵🇳', category: 'Flags', aliases: ['pitcairn_islands'] },
	{ data: '🇵🇱', category: 'Flags', aliases: ['poland'] },
	{ data: '🇵🇹', category: 'Flags', aliases: ['portugal'] },
	{ data: '🇵🇷', category: 'Flags', aliases: ['puerto_rico'] },
	{ data: '🇶🇦', category: 'Flags', aliases: ['qatar'] },
	{ data: '🇷🇪', category: 'Flags', aliases: ['reunion'] },
	{ data: '🇷🇴', category: 'Flags', aliases: ['romania'] },
	{ data: '🇷🇺', category: 'Flags', aliases: ['ru'] },
	{ data: '🇷🇼', category: 'Flags', aliases: ['rwanda'] },
	{ data: '🇧🇱', category: 'Flags', aliases: ['st_barthelemy'] },
	{ data: '🇸🇭', category: 'Flags', aliases: ['st_helena'] },
	{ data: '🇰🇳', category: 'Flags', aliases: ['st_kitts_nevis'] },
	{ data: '🇱🇨', category: 'Flags', aliases: ['st_lucia'] },
	{ data: '🇵🇲', category: 'Flags', aliases: ['st_pierre_miquelon'] },
	{ data: '🇻🇨', category: 'Flags', aliases: ['st_vincent_grenadines'] },
	{ data: '🇼🇸', category: 'Flags', aliases: ['samoa'] },
	{ data: '🇸🇲', category: 'Flags', aliases: ['san_marino'] },
	{ data: '🇸🇹', category: 'Flags', aliases: ['sao_tome_principe'] },
	{ data: '🇸🇦', category: 'Flags', aliases: ['saudi_arabia'] },
	{ data: '🇸🇳', category: 'Flags', aliases: ['senegal'] },
	{ data: '🇷🇸', category: 'Flags', aliases: ['serbia'] },
	{ data: '🇸🇨', category: 'Flags', aliases: ['seychelles'] },
	{ data: '🇸🇱', category: 'Flags', aliases: ['sierra_leone'] },
	{ data: '🇸🇬', category: 'Flags', aliases: ['singapore'] },
	{ data: '🇸🇽', category: 'Flags', aliases: ['sint_maarten'] },
	{ data: '🇸🇰', category: 'Flags', aliases: ['slovakia'] },
	{ data: '🇸🇮', category: 'Flags', aliases: ['slovenia'] },
	{ data: '🇸🇧', category: 'Flags', aliases: ['solomon_islands'] },
	{ data: '🇸🇴', category: 'Flags', aliases: ['somalia'] },
	{ data: '🇿🇦', category: 'Flags', aliases: ['south_africa'] },
	{ data: '🇬🇸', category: 'Flags', aliases: ['south_georgia_south_sandwich_islands'] },
	{ data: '🇰🇷', category: 'Flags', aliases: ['kr'] },
	{ data: '🇸🇸', category: 'Flags', aliases: ['south_sudan'] },
	{ data: '🇪🇸', category: 'Flags', aliases: ['es'] },
	{ data: '🇱🇰', category: 'Flags', aliases: ['sri_lanka'] },
	{ data: '🇸🇩', category: 'Flags', aliases: ['sudan'] },
	{ data: '🇸🇷', category: 'Flags', aliases: ['suriname'] },
	{ data: '🇸🇿', category: 'Flags', aliases: ['swaziland'] },
	{ data: '🇸🇪', category: 'Flags', aliases: ['sweden'] },
	{ data: '🇨🇭', category: 'Flags', aliases: ['switzerland'] },
	{ data: '🇸🇾', category: 'Flags', aliases: ['syria'] },
	{ data: '🇹🇼', category: 'Flags', aliases: ['taiwan'] },
	{ data: '🇹🇯', category: 'Flags', aliases: ['tajikistan'] },
	{ data: '🇹🇿', category: 'Flags', aliases: ['tanzania'] },
	{ data: '🇹🇭', category: 'Flags', aliases: ['thailand'] },
	{ data: '🇹🇱', category: 'Flags', aliases: ['timor_leste'] },
	{ data: '🇹🇬', category: 'Flags', aliases: ['togo'] },
	{ data: '🇹🇰', category: 'Flags', aliases: ['tokelau'] },
	{ data: '🇹🇴', category: 'Flags', aliases: ['tonga'] },
	{ data: '🇹🇹', category: 'Flags', aliases: ['trinidad_tobago'] },
	{ data: '🇹🇳', category: 'Flags', aliases: ['tunisia'] },
	{ data: '🇹🇷', category: 'Flags', aliases: ['tr'] },
	{ data: '🇹🇲', category: 'Flags', aliases: ['turkmenistan'] },
	{ data: '🇹🇨', category: 'Flags', aliases: ['turks_caicos_islands'] },
	{ data: '🇹🇻', category: 'Flags', aliases: ['tuvalu'] },
	{ data: '🇺🇬', category: 'Flags', aliases: ['uganda'] },
	{ data: '🇺🇦', category: 'Flags', aliases: ['ukraine'] },
	{ data: '🇦🇪', category: 'Flags', aliases: ['united_arab_emirates'] },
	{ data: '🇬🇧', category: 'Flags', aliases: ['gb', 'uk'] },
	{ data: '🇺🇸', category: 'Flags', aliases: ['us'] },
	{ data: '🇻🇮', category: 'Flags', aliases: ['us_virgin_islands'] },
	{ data: '🇺🇾', category: 'Flags', aliases: ['uruguay'] },
	{ data: '🇺🇿', category: 'Flags', aliases: ['uzbekistan'] },
	{ data: '🇻🇺', category: 'Flags', aliases: ['vanuatu'] },
	{ data: '🇻🇦', category: 'Flags', aliases: ['vatican_city'] },
	{ data: '🇻🇪', category: 'Flags', aliases: ['venezuela'] },
	{ data: '🇻🇳', category: 'Flags', aliases: ['vietnam'] },
	{ data: '🇼🇫', category: 'Flags', aliases: ['wallis_futuna'] },
	{ data: '🇪🇭', category: 'Flags', aliases: ['western_sahara'] },
	{ data: '🇾🇪', category: 'Flags', aliases: ['yemen'] },
	{ data: '🇿🇲', category: 'Flags', aliases: ['zambia'] },
	{ data: '🇿🇼', category: 'Flags', aliases: ['zimbabwe'] }
]);

export const regex = Object.freeze({
	templateParam: /(?:{\$)([^{}<]{1,})(?:})/g
});

/**
 * Different ways in which a post can be displayed
 */
export const userPostDisplayModes = Object.freeze({
	preview: 'preview',
	compactPreview: 'compactPreview',
	title: 'title',
	thumbnail: 'thumbnail',
	thumbnailOnly: 'thumbnailOnly'
});

export const userContent = Object.freeze({
	defaults: {
		maxMediaPerPost: 6,
		minMediaPerPost: 0
	}
});

/**
 * Default displayMode for a post
 */
export const defaultUserPostDisplayMode = userPostDisplayModes.title;

/**
 * Post Types Available to Create User Post in a User Content Search Container
 */
export const userPostTypes = Object.freeze({
	media: 'media',
	status: 'status',
	article: 'article'
});

/**
 * Default Post Types if they've not been set up
 */
export const defaultUserPostType = userPostTypes.media;

/**
 * Default Post Types if they've not been set up
 */
export const defaultUserPostTypes = [defaultUserPostType];

/**
 * Sources media attachments can come from.
 */
export const postMediaSources = Object.freeze({
	local: 'local',
	youtube: 'youtube',
	googleDrive: 'googleDrive',
	oneDrive: 'oneDrive',
	vimeo: 'vimeo'
});

/** Default number of comments loaded per user post per page of results */
export const defaultUserPostCommentsLimitPerPage = 5;

/**
 * Types of media attachment files supported by User Content
 * Search Container.
  */
export const mediaTypes = {
	image: 'image',
	audio: 'audio',
	video: 'video',
	document: 'document',
	link: 'link'
};

export const mediaTypesPluralToSingular = Object.freeze({
	images: mediaTypes.image,
	audio: mediaTypes.audio,
	videos: mediaTypes.video,
	documents: mediaTypes.document,
	links: mediaTypes.link
});

/**
 * Media types with less visual appeal.
 */
export const mediaTypesWithoutThumbnails = Object.freeze([
	mediaTypes.document,
	mediaTypes.audio,
	mediaTypes.link
]);

export const mediaTypesWithThumbnails = Object.freeze([
	mediaTypes.video,
	mediaTypes.image,
	userPostMediaTypes.images,
	userPostMediaTypes.videos
]);

export const remoteMediaSourcesPerUserPostMediaType = Object.freeze({
	[userPostMediaTypes.documents]: [
		postMediaSources.googleDrive,
		postMediaSources.oneDrive
	],
	[userPostMediaTypes.videos]: [
		postMediaSources.vimeo,
		postMediaSources.youtube
	]
});

export const allowedFileExtensions = Object.freeze({
	pdf: 'pdf',
	docx: 'docx',
	doc: 'doc',
	xlsx: 'xlsx',
	xls: 'xls',
	pptx: 'pptx',
	ppt: 'ppt',
	odt: 'odt',
	ods: 'ods',
	odp: 'odp'
});

export const googleDriveFileTypes = Object.freeze({
	googleDocs: 'googleDocs',
	googleSheets: 'googleSheets',
	googleSlides: 'googleSlides'
});

export const oneDriveFileTypes = Object.freeze({
	oneDriveWord: 'oneDriveWord',
	oneDriveExcel: 'oneDriveExcel',
	oneDrivePowerpoint: 'oneDrivePowerpoint'
});

export const oneDriveFileTypesByExtension = Object.freeze({
	[allowedFileExtensions.doc]: oneDriveFileTypes.oneDriveWord,
	[allowedFileExtensions.docx]: oneDriveFileTypes.oneDriveWord,
	[allowedFileExtensions.xlsx]: oneDriveFileTypes.oneDriveExcel,
	[allowedFileExtensions.slx]: oneDriveFileTypes.oneDriveExcel,
	[allowedFileExtensions.pptx]: oneDriveFileTypes.oneDrivePowerpoint,
	[allowedFileExtensions.ppt]: oneDriveFileTypes.oneDrivePowerpoint
});

export const allowedRemoteFileTypes = Object.freeze({
	...googleDriveFileTypes, ...oneDriveFileTypes
});

export const allSupportedFileTypes = Object.freeze({
	...allowedFileExtensions,
	...allowedRemoteFileTypes
});

export const embeddableDocumentProviders = Object.freeze({
	googleDrive: 'googleDrive',
	oneDrive: 'oneDrive'
});

export const providerShareLinkSupportLinksPerLocale = Object.freeze({
	[I18N.cLanguages.en]: {
		[embeddableDocumentProviders.googleDrive]: 'https://support.google.com/drive/answer/2494822?hl=en-GB&ref_topic=7000947',
		[embeddableDocumentProviders.oneDrive]: 'https://support.microsoft.com/en-gb/office/share-onedrive-files-and-folders-9fcc2f7d-de0c-4cec-93b0-a82024800c07#:~:text=Tip%3A%20You%20can%20generate%20sharing,you%20want%20to%20send%20it.'
	},
	[I18N.cLanguages.fr]: {
		[embeddableDocumentProviders.googleDrive]: 'https://support.google.com/drive/answer/2494822?hl=fr&ref_topic=7000947',
		[embeddableDocumentProviders.oneDrive]: 'https://support.microsoft.com/fr-fr/office/partager-des-fichiers-et-des-dossiers-onedrive-9fcc2f7d-de0c-4cec-93b0-a82024800c07#:~:text=Tip%3A%20You%20can%20generate%20sharing,you%20want%20to%20send%20it.'
	}
});

export const msOfficeExtensions = Object.freeze([
	allowedFileExtensions.doc,
	allowedFileExtensions.docx,
	allowedFileExtensions.pptx,
	allowedFileExtensions.ppt,
	allowedFileExtensions.xls,
	allowedFileExtensions.xlsx
]);

export const libreOfficeExtensions = Object.freeze([
	allowedFileExtensions.odt,
	allowedFileExtensions.ods,
	allowedFileExtensions.odp
]);

export const useOneDriveEmbedForExtensions = Object.freeze([
	...libreOfficeExtensions,
	...msOfficeExtensions
]);

/**
 * For each media type, the allowed file types / file extensions.
 * Used to validate files before they're uploaded by `PostMediaUploader`.
 */
export const fileTypesPerMediaType = Object.freeze({
	[userPostMediaTypes.images]: ['image/*'],
	[userPostMediaTypes.videos]: ['video/*'],
	[userPostMediaTypes.audio]: ['audio/flac', 'audio/ogg', '.aac', 'audio/wav', 'audio/mpeg', 'audio/x-m4a'],
	[userPostMediaTypes.documents]: ['.pdf', '.docx', '.doc', '.odt', '.xlsx', '.xls', '.ods', '.pptx', '.ppt', '.odp']
});

export const allAllowedFileTypes = Object.freeze([
	...fileTypesPerMediaType[userPostMediaTypes.images],
	...fileTypesPerMediaType[userPostMediaTypes.videos],
	...fileTypesPerMediaType[userPostMediaTypes.audio],
	...fileTypesPerMediaType[userPostMediaTypes.documents]
]);

export const createUserPostModalTabs = Object.freeze({
	postDetails: 'postDetails',
	postUpload: 'postUpload',
	postLink: 'postLink',
	postThumbnail: 'postThumbnail'
});

export const allowedMimes = Object.freeze({
	'application/pdf': 'application/pdf',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	'application/msword': 'application/msword',
	'application/vnd.oasis.opendocument.text': 'application/vnd.oasis.opendocument.text',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'application/vnd.ms-excel': 'application/vnd.ms-excel',
	'application/vnd.oasis.opendocument.spreadsheet': 'application/vnd.oasis.opendocument.spreadsheet',
	'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
	'application/vnd.ms-powerpoint': 'application/vnd.ms-powerpoint',
	'application/vnd.oasis.opendocument.presentation': 'application/vnd.oasis.opendocument.presentation'
});

/** For each file extension, the associated file mime. */
export const mediaMimesPerExtension = Object.freeze({
	[allowedFileExtensions.pdf]: allowedMimes['application/pdf'],
	[allowedFileExtensions.docx]: allowedMimes['application/vnd.openxmlformats-officedocument.wordprocessingml.document'], // .docx
	[allowedFileExtensions.doc]: allowedMimes['application/msword'], // .doc
	[allowedFileExtensions.odt]: allowedMimes['application/vnd.oasis.opendocument.text'], // .odt (Open Office Document)
	[allowedFileExtensions.xlsx]: allowedMimes['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'], // .xlsx
	[allowedFileExtensions.xls]: allowedMimes['application/vnd.ms-excel'], // .xls
	[allowedFileExtensions.ods]: allowedMimes['application/vnd.oasis.opendocument.spreadsheet'], // .ods Open Office Spreadsheet
	[allowedFileExtensions.pptx]: allowedMimes['application/vnd.openxmlformats-officedocument.presentationml.presentation'], // .pptx
	[allowedFileExtensions.ppt]: allowedMimes['application/vnd.ms-powerpoint'], // .ppt
	[allowedFileExtensions.odp]: allowedMimes['application/vnd.oasis.opendocument.presentation'] // .odp Open Office presentation
});

export const extensionsPerMime = Object.freeze({
	[allowedMimes['application/pdf']]: allowedFileExtensions.pdf,
	[allowedMimes['application/vnd.openxmlformats-officedocument.wordprocessingml.document']]: allowedFileExtensions.docx,
	[allowedMimes['application/msword']]: allowedFileExtensions.doc,
	[allowedMimes['application/vnd.oasis.opendocument.text']]: allowedFileExtensions.odt,
	[allowedMimes['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']]: allowedFileExtensions.xlsx,
	[allowedMimes['application/vnd.ms-excel']]: allowedFileExtensions.xls,
	[allowedMimes['application/vnd.oasis.opendocument.spreadsheet']]: allowedFileExtensions.ods,
	[allowedMimes['application/vnd.openxmlformats-officedocument.presentationml.presentation']]: allowedFileExtensions.pptx,
	[allowedMimes['application/vnd.ms-powerpoint']]: allowedFileExtensions.ppt,
	[allowedMimes['application/vnd.oasis.opendocument.presentation']]: allowedFileExtensions.odp
});

/**
 * If admins choose not to use OneDrive Viewer to embed uploaded
 * docs, these types of files need to be downloaded.
 */
export const downloadTypesIfNoOneDriveViewer = [
	allowedFileExtensions.docx,
	allowedFileExtensions.doc,
	allowedFileExtensions.odt,
	allowedFileExtensions.xlsx,
	allowedFileExtensions.xls,
	allowedFileExtensions.ods,
	allowedFileExtensions.pptx,
	allowedFileExtensions.ppt,
	allowedFileExtensions.odp
];

/**
 * Types of content a delete confirm modal can ask confirmation to delete
 */
export const deleteConfirmModalContentDeletedTypes = Object.freeze({
	comment: 'comment',
	post: 'post'
});

/**
 * Max file size allowed.
 * 100MB
 */
export const maxFileSizeAllowed = 1e8;

export const userContentSearchContainer = {
	userPost: {
		ultraCompactPostMediaListThreshold: 6,
		maxPostMessageDefaultDisplayLength: 200
	},
	userPostMediaTypes
};

export const scopeContexts = Object.freeze({
	user: 'user',
	entity: 'entity'
});

/**
 * The minimum width required for a fullscreen post to display split
 * the media display and content display horizontally instead
 * of vertically, in pixels.
 */
export const fullscreenModalUserPostHorizontalMinWidth = 800;

/**
 * All available asynchronous process types that can be referenced in the admin
 * store. Should reference `(@/types/process).ProcessType`, but the disconnect
 * between enums in JSDoc and TS make this incredibly difficult. The better type
 * information is available in the aforementioned declaration file.
 *
 * @enum {string}
 */
export const processType = Object.freeze({
	autoSubscriber: 'autoSubscriber'
});

/**
 * All current progress steps for the entity auto-subscribe tool.
 */
export const entityAutoSubscribeProgressSteps = Object.freeze({
	// Step 1
	initialise: 'initialise',
	// Step 2
	writeData: 'writeData'
});

/**
 * Available QR code correction levels.
 * See https://en.wikipedia.org/wiki/QR_code#Error_correction for more detail.
 */
export const QRCodeCorrectionLevel = Object.freeze({
	L: 'L',
	M: 'M',
	Q: 'Q',
	H: 'H'
});

/**
 * Max length the value of a QR Code can be depending on its correction level.
 * Could use bytes for more precision, but as it's very unlikely the QR Code will be used to show only numbers, length should do the trick.
 * Roughly coming from https://www.qrcode.com/en/about/version.html.
 * Adapted from testing and so that a 250px QR code could be readable.
 */
export const maxQRCodeValueLengthPerCorrectionLevel = Object.freeze({
	L: 2000,
	M: 1500,
	Q: 1000,
	H: 500
});

/** Default QR code settings */
export const defaultQRCodeSettings = Object.freeze({
	correctionLevel: QRCodeCorrectionLevel.L,
	backgroundColor: '#fff',
	foregroundColor: '#000',
	size: 100,
	fullWidthSetting: false
});

export const defaultUserContentSearchContainerSettings = Object.freeze({
	allowReports: true
});

export const ConnectionServiceType = Object.freeze({
	sendEmail: 'sendEmail',
	message: 'message',
	schedule: 'schedule'
});

export const BorderedFieldContainerDisplayModes = Object.freeze({
	regular: 'regular',
	danger: 'danger',
	success: 'success'
});

export const maxMenteeFieldId = '038497ac-9369-4b85-a912-3f5daefcf4d1';

export const cDefaultFromEmailAddress = 'help@aluminati.net';

/** @enum {import('@/types/app').DateUnits} */
export const cDateUnits = /** @type {typeof import('@/types/app').DateUnits} */(Object.freeze({
	seconds: 'seconds',
	minutes: 'minutes',
	hours: 'hours',
	days: 'days',
	weeks: 'weeks',
	months: 'months',
	years: 'years'
}));

export const inlineImageUploaderPortals = Object.freeze({
	inline: 'inline-image-uploader',
	lightroom: 'lightroom-image-uploader'
});

export const cExportTablePredefinedFilters = Object.freeze({
	matchOnListId: 'matchOnListId',
	userLists: 'userLists',
	showOldConnections: 'showOldConnections',
	entityId: 'entityId',
	conceptId: 'conceptId',
	accountId: 'accountId',
	createdBy: 'createdBy'
});

export const cBlockPanelExtensions = Object.freeze({
	fadeOutIfInPast: 'fadeOutIfInPast',
	attachToEntity: 'attachToEntity',
	profileLink: 'profileLink',
	attachToProgramme: 'attachToProgramme',
	heroSettings: 'heroSettings'
});

/** @type {typeof import('../types/components/ui/colorAndGradientPicker').ColorAndGradientTabs} ColorAndGradientTabs */
/** @enum {ColorAndGradientTabs} */
export const cColorAndGradientTabs = {
	/** @type {ColorAndGradientTabs} */
	solid: 'solid',
	/** @type {ColorAndGradientTabs} */
	gradient: 'gradient'
};

/** @type {typeof import('../types/gradient').RadialType} RadialType */
/** @enum {RadialType} */
export const gradientRadialType = Object.freeze({
	/** @type {RadialType} */
	circle: 'circle',
	/** @type {RadialType} */
	ellipse: 'ellipse'
});

/** Default solid colour for colorAndGradientColor Component */
export const cColorAndGradientFallbackColor = '#ffffff';

/** Default gradient colour for colorAndGradientColor Component */
export const cColorAndGradientFallbackGradientColor = Utils.deepFreeze({
	radial: false,
	radialType: gradientRadialType.circle,
	rotation: 0,
	position: { x: 50, y: 50 },
	colors: [
		{ value: 'rgba(76,143,138,1)', position: 0 },
		{ value: 'rgba(123,201,196,1)', position: 70 }
	]
});

export const cAppThemes = Object.freeze({
	light: 'light',
	dark: 'dark'
});

export const cImgTagAttributes = Object.freeze({
	eager: 'eager',
	async: 'async',
	high: 'high',
	lazy: 'lazy',
	auto: 'auto',
	low: 'low'
});

export const cCustomGlobalCssStyles = Object.freeze({
	globalStyleCustomGlobalCssStyles: 'global-style-customGlobalCssStyles'
});

/** Coming from `/aluminate-api/src/models/ReportAbuse/index.ts` */
export const reportAbuseTypes = Object.freeze({
	pm: 'pm',
	discussion: 'discussion',
	profile: 'profile',
	entity: 'entity',
	file: 'file',
	discussionMessage: 'discussionMessage',
	headlessPost: 'headlessPost',
	headlessComment: 'headlessComment'
});

export const cApplicationStateChangeSources = {
	membershipRequest: 'membershipRequest'
};

export const cDefaultIso = 'default';

export const appShortKeys = Object.freeze({
	editMode: 'alt+shift+e',
	textEditMode: 'alt+shift+j',
	debugInfo: 'alt+shift+d',
	safeMode: 'alt+shift+s'
});

export const cItemTypes = Object.freeze({
	module: 'module',
	page: 'page',
	block: 'block',
	field: 'field',
	dataSchema: 'dataSchema',
	entity: 'entity',
	application: 'application',
	entityDefinition: 'entityDefinition',
	powerUp: 'powerUp',
	workflow: 'workflow',
	workflowAction: 'workflowAction',
	dataMapping: 'dataMapping',
	template: 'template'
});

export const cItemTypesPlural = Object.freeze({
	modules: 'modules',
	pages: 'pages',
	blocks: 'blocks',
	fields: 'fields',
	dataSchemas: 'dataSchemas',
	entities: 'entities',
	applications: 'applications',
	entityDefinitions: 'entityDefinitions',
	powerUps: 'powerUps',
	workflows: 'workflows',
	workflowActions: 'workflowActions',
	dataMappings: 'dataMappings',
	templates: 'templates'
});

export const cItemTypeIds = Object.freeze({
	[cItemTypes.module]: 1,
	[cItemTypes.page]: 2,
	[cItemTypes.block]: 3,
	[cItemTypes.field]: 4,
	[cItemTypes.dataSchema]: 5,
	[cItemTypes.entity]: 6,
	[cItemTypes.application]: 7,
	[cItemTypes.entityDefinition]: 8,
	[cItemTypes.powerUp]: 9,
	[cItemTypes.workflow]: 10,
	[cItemTypes.workflowAction]: 11,
	[cItemTypes.dataMapping]: 12,
	[cItemTypes.template]: 13
});

export const cItemTypesPerId = Object.freeze({
	1: [cItemTypes.module],
	2: [cItemTypes.page],
	3: [cItemTypes.block],
	4: [cItemTypes.field],
	5: [cItemTypes.dataSchema],
	6: [cItemTypes.entity],
	7: [cItemTypes.application],
	8: [cItemTypes.entityDefinition],
	9: [cItemTypes.powerUp],
	10: [cItemTypes.workflow],
	11: [cItemTypes.workflowAction],
	12: [cItemTypes.dataMapping],
	13: [cItemTypes.template]
});

export const cStructureUpdateDelayMs = 600;

export const translationDataKeys = Object.freeze({
	key: 'key',
	defaultValue: 'defaultValue',
	translatedValue: 'translatedValue'
});

export const cItemsTableFields = {
	/**
	 * `createdAt` is pulled from the `itemsMeta` table, but should be present
	 * on every row returned
 	 */
	createdAt: 'createdAt',
	updatedAt: 'updatedAt',
	abstract: 'abstract',
	accountId: 'accountId',
	createdBy: 'createdBy',
	data: 'data',
	id: 'id',
	revisionId: 'revisionId',
	title: 'title',
	type: 'type',
	typeId: 'typeId',
	usages: 'usages',
	_key: '_key',
	_schema: '_schema',
	_source: '_source',
	controls: 'controls'
};

export const blockUsageTypes = {
	page: 'page',
	block: 'block'
};

// 5MB = 5242880 bytes (5 * 1024^2)
export const cMaxImageSizeInUploaders = 5242880;

export const cSearchFilterLogicalOperators = Object.freeze({
	or: 'or',
	and: 'and'
});

export const cActiveFilterIds = Object.freeze({
	bool: 'bool',
	userList: 'userList',
	hiddenAndVisibleUserList: 'hiddenAndVisibleUserList',
	definitionId: 'definitionId',
	hasConnections: 'hasConnections',
	hasMemberships: 'hasMemberships',
	entityMembership: 'entityMembership'
});

export const cAppSettingsIllustrationTabs = {
	popup: 'popup',
	splash: 'splash',
	app: 'app'
};

export const cCarouselBlockDefaultHeight = 400;

export const cCarouselBlockMaxDotItemsDefault = 12;

export const cEditBlockTabs = {
	settings: 'settings',
	visibility: 'visibility',
	permissions: 'permissions',
	design: 'design',
	notifications: 'notifications',
	style: 'style'
};

export const cItemVisibilityStates = {
	advanced: 'advanced',
	basic: 'basic',
	custom: 'custom'
};

export const cTabsPermissions = {
	[cEditBlockTabs.settings]: 'manageSettings',
	[cEditBlockTabs.visibility]: 'manageVisibility',
	[cEditBlockTabs.permissions]: 'managePermissions',
	[cEditBlockTabs.design]: 'manageDesign',
	[cEditBlockTabs.style]: 'manageCss'
};

export const cUserNamesOrInitialsAvailableOpts = Object.freeze({
	initialsFirstName: 'f',
	fullFirstName: 'F',
	initialMiddleName: 'm',
	fullMiddleName: 'M',
	initialLastName: 'l',
	fullLastName: 'L'
});

export const cYearMaxLength = 6;

export const cInvalidDatePlaceholder = '';

export const cFormInputKeyCodes = Object.freeze({
	UNIDENTIFIABLE: 'Unidentified', // unidentifiable keys
	ENTER: 'Enter' // Enter
});

export const cGeoLocFilterDefaults = Object.freeze({
	cFixedRadiusMode: false,
	cMapHeight: 380,
	cMapRadius: 140,
	cMapPosition: { lat: 51.5, lng: -0.12 }, // London
	cMinZoom: 5,
	cMedianZoom: 9,
	cMaxZoom: 13,
	cMinRadiusKm: 1,
	cMaxRadiusKm: 500
});

export const cGeoLocFilterDefaultOptions = Utils.deepFreeze([
	{
		label: 'London',
		value: {
			lat: cGeoLocFilterDefaults.cMapPosition.lat,
			lon: cGeoLocFilterDefaults.cMapPosition.lng
		}
	}
]);

export const cPaginationTypes = {
	numbered: 'numbered',
	loadMore: 'loadMore'
};

export const cCurrentUserOverviewDefaults = Object.freeze({
	viewType: {
		full: 'full',
		compact: 'compact'
	},
	userInitials: {
		showFirstInitial: 'showFirstInitial',
		showFirstLastInitials: 'showFirstLastInitials'
	}
});

export const cNavigationTags = ['A', 'AREA'];

export const cBlockWrapperCssClass = 'elementControls';

// Services that wants to have recording feature disabled for Jitsi Video Chat
export const cDisableJitsiRecordingServices = [
	165 // Sheffield
];

export const cNotificationRequestTypes = Object.freeze({
	request: 'request',
	requestAccepted: 'requestAccepted',
	requestRejected: 'requestRejected',
	requestCompleted: 'requestCompleted'
});
