import Handlebars from 'handlebars';
import helpers from 'handlebars-kit';
import dot from 'dot-object';
import moment from 'moment';
import { generateImagePath } from '@/plugins/imagePath';
import router from '@/router';
import store from '@/store';
import { I18N, KeyAliases } from 'acb-lib';
import i18n from '@/plugins/vue-i18n';
import { getKeyForAlias } from '@/plugins/keyAlias';
import { cUserNamesOrInitialsAvailableOpts } from '@/configs/constants';
import { cRegexValidImageWidth } from '@/configs/regex';
import { uuidValidate as isUUID } from '@/utils/tools';

/**
 * @typedef {import('acb-lib').AllowedThumbnailWidth} AllowedThumbnailWidth
 * @typedef {import('acb-lib').AllowedThumbnailWidthConst} AllowedThumbnailWidthConst
 */

window.$$handlebarsDynamicLinkNavigation = (e) =>
{
	e.preventDefault();
	let element = e.target,
		url = element.getAttribute('data-url');

	if(!url)
	{
		element = element.parentNode;
		url = element.getAttribute('data-url');
	}

	router.push(url);
};

let promises = [];

export function hasPromises()
{
	return promises.length > 0;
}

export async function executePromises()
{
	await Promise.all(promises);
	promises = [];
}

helpers({
	handlebars: Handlebars
});

if(process.env.LIVE === 'true' || process.env.STAGING === 'true')
{
	// get rid of any data dumps on live. We'll probably still need them on qa and angtest for any building
	Handlebars.unregisterHelper('log'); // we can't have data leaks through the console.log on live!

	// removes the `{{log ...}}` from the block content, so the rest of the content doesn't disappear
	Handlebars.registerHelper('log', () =>
	{
		return '';
	});
}

/**
 * The `image` helper accepts an imageId, imageProp, imageWidth
 * - by default (without imageProp, imageWidth) the url of the image is returned
 * - imageProp is the key in the image.data (loaded in store), returns image data prop value
 * - imageWidth is the target resolution width of the requested image, if given the image will return the closest
 *   resolution to these 6 options: 56 | 250 | 300 | 500 | 600 | 1920
 * 	 acceptable formats: 500 | '500' | '500px'
 */
Handlebars.registerHelper('image', (imageId, imageProp, imageWidth) =>
{
	const returnImageUrl = imageProp === 'url' || typeof imageProp !== 'string';

	if(returnImageUrl) return getGeneratedImagePath(imageId, imageWidth);

	return getImagePropertyValue(imageId, imageProp);
});

/**
 * getConceptProp: Get the value assigned to a Concept property, given the ID of
 * the required Concept.
 *
 * @example
 * // Returns the name of the Concept with the ID "concept-1234"
 * {{getConceptProp "concept-1234" "name"}}
 *
 * @example
 * // Returns the creation date of the Concept which defines the Entity in scope
 * {{getConceptProp entity.definition "_createdAt"}}
 *
 * @example
 * // Checks the name of the Concept is "Groups" and runs some conditional logic
 * {{#if (eq (getConceptProp entity.definition "name") "Groups")}}
 *   <p>This entity is a group!</p>
 * {{else}}
 *   <p> This entity is NOT a group!</p>
 * {{/if}}
 */
Handlebars.registerHelper('getConceptProp', (conceptId, propName) =>
{
	if(
		typeof conceptId !== 'string' ||
		!isUUID(conceptId) ||
		typeof propName !== 'string'
	)
	{
		return undefined;
	}

	const concept = store.getters['entityDefinitions/byId'](conceptId);

	if(!concept)
	{
		store.dispatch('entityDefinitions/load', { id: conceptId });
	}

	return concept?.[propName];
});

/**
 * Parses `imageWidth` parameter then gets returns image src path with defined resolution
 * @param {number | string} imageId
 * @param {number | string} imageWidth resolution of image to be returned
 * @returns {string} Image path with defined resolution or max resolution if `imageWidth`is invalid
 */
const getGeneratedImagePath = (imageId, imageWidth) =>
{
	const parsedImageWidth = parseImageWidth(imageWidth);

	if(parsedImageWidth) return generateImagePath(imageId, true, parsedImageWidth);

	return generateImagePath(imageId);
};

/**
 * Checks if imageWidth is a valid value for image handlebar then converts it to an int.
 *
 * Valid `imageWidth` = `'500' | '500px' | 500`
 *
 * Only integers and pixel values (`px`) supported, no other units are valid.
 * @param {number | string} imageWidth
 * @returns {number | null} Valid `imageWidth` integer, if invalid `null` is returned
 */
const parseImageWidth = (imageWidth) =>
{
	if(typeof imageWidth === 'string')
	{
		const validImageWidth = cRegexValidImageWidth.test(imageWidth);

		if(validImageWidth) return parseInt(imageWidth, 10);
	}

	if(typeof imageWidth === 'number')
	{
		return imageWidth;
	}

	return null;
};

/**
 * Gets property from file data in store
 * @param {number | string} imageId
 * @param {string} imageProp Image data property key or path to value
 * @returns {string} Value of image property
 */
const getImagePropertyValue = (imageId, imageProp) =>
{
	const imageData = store.getters['files/byId'](imageId)?.data;

	if(!imageData)
	{
		promises.push(store.dispatch('files/loadFiles', { fileIds: [imageId] }));

		return '';
	}

	return dot.pick(imageProp, imageData);
};

/**
 * The `usersName` helper accepts an accountId and prints out the target user's first and last full names.
 * Example: `{{usersName 101}}` where 101 is the target user's accountId.
 * If the 'middle' argument is passed (e.g. `{{usersName 101 "middle"}}`) then it will include their middle name as well.
 * The `usersNames` helper can be used to target the 'user' or 'profile' in the handlebars
 */
Handlebars.registerHelper('usersName', (accountId, ...opts) =>
{
	const nameAliases = opts.includes('middle') ?
		[KeyAliases.keys.FIRSTNAME, KeyAliases.keys.MIDDLENAME, KeyAliases.keys.LASTNAME] :
		[KeyAliases.keys.FIRSTNAME, KeyAliases.keys.LASTNAME];

	const profileName = store.getters['profiles/getName'](accountId, nameAliases);

	if(profileName === '')
	{
		promises.push(store.dispatch('profiles/loadProfile', accountId));

		return '';
	}

	return profileName;
});

/**
 * Return the initials and/or names of the specified 'user' or 'profile', to be used with the `usersNames` helper.
 * @param {String} source - 'user' or 'profile'
 * @param {String} opts - Options e.g. 'FmL' = first and last full names with middle initial
 * @param {Array<Object>} context - the Handlebars context data
 * @return {String} - The user's initials or names, as specified in the options.
 */
const getUsersNamesOrInitials = (source, opts, context) =>
{
	const profileData = context?.data?.root?.[source] || {};

	const firstNameKey = getKeyForAlias(KeyAliases.keys.FIRSTNAME);
	const middleNameKey = getKeyForAlias(KeyAliases.keys.MIDDLENAME);
	const lastNameKey = getKeyForAlias(KeyAliases.keys.LASTNAME);

	const {
		initialsFirstName,
		fullFirstName,
		initialMiddleName,
		fullMiddleName,
		initialLastName,
		fullLastName
	} = cUserNamesOrInitialsAvailableOpts;
	let first = '',
		middle = '',
		last = '';

	if(profileData[firstNameKey])
	{
		if(opts.includes(initialsFirstName))
		{
			first = profileData[firstNameKey].slice(0, 1);
		}
		else if(opts.includes(fullFirstName))
		{
			first = ` ${profileData[firstNameKey]} `;
		}
	}

	if(profileData[middleNameKey])
	{
		if(opts.includes(initialMiddleName))
		{
			middle = profileData[middleNameKey].slice(0, 1);
		}
		else if(opts.includes(fullMiddleName))
		{
			middle = ` ${profileData[middleNameKey]} `;
		}
	}

	if(profileData[lastNameKey])
	{
		if(opts.includes(initialLastName))
		{
			last = profileData[lastNameKey].slice(0, 1);
		}
		else if(opts.includes(fullLastName))
		{
			last = ` ${profileData[lastNameKey]}`;
		}
	}

	return `${first}${middle}${last}`.replaceAll('  ', ' ');
};

/**
 * Print out names or initials, from either the 'user' or 'profile' in the handlebars context.
 * Examples (name example: Joshua Methuselah Welham):
 * 		`{{usersNames "user" "FL"}}` => `Joshua Welham`
 * 		`{{usersNames "user" "FmL"}}` => `Joshua M Welham`
 * 		`{{usersNames "user" "Fml"}}` => `Joshua MW`
 * 		`{{usersNames "user" "fmL"}}` => `JM Welham`
 * Substitute "profile" for "user" to target the profile in the context.
 * Not to be confused with the `usersName` helper (above), which requires a specific accountId.
 */
Handlebars.registerHelper('usersNames', getUsersNamesOrInitials);

// {{#link '/a/path'}}Link test{{/link}}
// Handlebars.registerHelper('link', (url, options) =>
// {
// 	return new Handlebars.SafeString(`<a href="${url}" data-url="${url}" onclick="$$handlebarsDynamicLinkNavigation(event)">${options.fn({ ...options.data.root, url })}</a>`);
// });

// Example usage: <a {{innerLink '/a/page'}}>Link text</a>
Handlebars.registerHelper('innerLink', (url, targetId, options) =>
{
	const urlToUse = targetId && ['string', 'number'].includes(typeof targetId) ? `${url}${targetId}` : url;
	let hrefUrlToUse = urlToUse;

	// href needs to make sure the inner links work too, by adding the BASE_URL in the beginning of the link
	if(process.env.BASE_URL && urlToUse.substring(0, 1) === '/')
	{
		hrefUrlToUse = `${process.env.BASE_URL}${urlToUse.substring(1)}`;
	}

	return new Handlebars.SafeString(`href="${hrefUrlToUse}" data-url="${urlToUse}" onclick="$$handlebarsDynamicLinkNavigation(event)"`);
});

Handlebars.registerHelper('concat', (...rest) =>
{
	let outStr = '';

	rest.forEach((arg) =>
	{
		if(typeof arg !== 'object')
		{
			outStr += arg;
		}
	});

	return outStr;
});

/**
 * Prints out encoded string, encoding replaces special characters not suitable for
 * <a/> href property
 *
 * `{{urlEncode '?query=hello world'}}`
 *
 * Result = '?query=hello%20world'
 */
Handlebars.registerHelper('urlEncode', (urlString, ...params) =>
{
	if(!params.length)
	{
		// If `params` is empty, no url has been supplied to the helper so
		// url === this
		return '';
	}

	if(urlString === undefined || urlString === null)
	{
		return '';
	}

	// If input string is valid url use encodeURI()
	if(URL.canParse(urlString))
	{
		return encodeURI(urlString);
	}

	// Otherwise input string is url param, use encodeURIComponent() instead
	return encodeURIComponent(urlString);
});

// {{value 'entity.someField'}} OR {{value 'profile.someField'}} OR {{value 'user.someField'}}
Handlebars.registerHelper('value', (path, options) =>
{
	if(!path) return '';

	const splitPath = path.split('.');

	const type = splitPath.shift();

	switch(type)
	{
		case 'profile':
			return dot.pick(splitPath.join('.'), options?.data?.root?.__profileOptions);
		case 'user':
			return dot.pick(splitPath.join('.'), options?.data?.root?.__userOptions);
		case 'entity':
			return dot.pick(splitPath.join('.'), options?.data?.root?.__entityOptions);
		case 'keyAlias':
			return dot.pick(splitPath.join('.'), options?.data?.root?.__keyAliasOptions);
		default:
			return '';
	}
});

// Example usage: {{defaultTimeFormat '12:01:01'}} or {{defaultTimeFormat variable}} or {{defaultTimeFormat '12:01' 'HH:mm'}}
Handlebars.registerHelper('defaultTimeFormat', (time, sourceFormat, options) =>
{
	let format = 'HH:mm:ss';

	if(typeof sourceFormat === 'string')
	{
		format = sourceFormat;
	}

	return moment(time, format).format(store.getters['app/settings/get']('date').timeFormat);
});

// Example usage: {{defaultDateFormat '2020-01-01'}} or {{defaultDateFormat variable}}
Handlebars.registerHelper('defaultDateFormat', (date, options) =>
{
	const numData = Number(date);

	// eslint-disable-next-line no-restricted-globals
	if(isNaN(numData))
	{
		return moment(date).format(store.getters['app/settings/get']('date').dateFormat);
	}

	return moment.unix(numData).format(store.getters['app/settings/get']('date').dateFormat);
});

// Example usage: {{defaultDateAndTimeFormat '2020-01-01 12:01:01'}} or {{defaultDateAndTimeFormat variable}}
Handlebars.registerHelper('defaultDateAndTimeFormat', (dateAndTime, options) =>
{
	return moment(dateAndTime).format(store.getters['app/settings/get']('date').dateAndTimeFormat);
});

Handlebars.registerHelper('dateTimeInPast', (date, time, options) =>
{
	// let localMoment;

	// if(time && typeof time !== 'object')
	// {
	// 	localMoment = moment(`${date} ${time}`);
	// }
	// else
	// {
	// 	localMoment = moment(date);
	// }

	// return moment().isAfter(localMoment);

	return moment().isAfter(moment(`${date} 23:59:59`));
});

/**
 * Ensure that, if there are any i18n blocks, there's at least
 * one for each enabled language.
 *
 * @param {string[]} codes
 * @param {string[]} enabledLanguages
 */
export function assertMissingLangDeclaration(codes)
{
	const missingLanguages = store.getters['i18n/enabledLanguages']
		.filter((enabledLanguage) => !codes.includes(enabledLanguage))
		.map((code) => I18N.cLanguagesEnglish[code]);

	if(missingLanguages.length)
	{
		throw new Error(getI18nForKey(
			'notAllLangsDeclared',
			{ missingLanguages: missingLanguages.join(', ') }
		));
	}
}

/**
 * Ensure that each language has been
 * declared the same number of times.
 *
 * e.g. if English and Italian are enabled and there's 2 English
 * i18n blocks, there should also be 2 Italian i18n blocks.
 * @param {string[]} codes
 */
export function assertDeclarationCount(codes)
{
	const occurrences = codes.reduce((acc, lang) =>
	{
		if(!acc[lang])
		{
			acc[lang] = 1;

			return acc;
		}

		acc[lang] += 1;

		return acc;
	}, {});

	if(Object.values(occurrences).every((val) => Object.values(occurrences)[0] === val))
	{
		return;
	}

	const occurrencesI18n = Object.keys(occurrences).map((lang) => (
		getI18nForKey(
			'timesOccurrence',
			{
				lang: I18N.cLanguagesEnglish[lang]
			},
			occurrences[lang] || 0
		)
	)).join(', ');

	// e.g. "English: 2 times, Spanish: 1 time"
	throw new Error(getI18nForKey(
		'notAllSameNumber',
		{ occurrences: occurrencesI18n }
	));
}

// usage:
//	{{#i18n 'en' }}
// 		English value
//	{{/i18n}}
Handlebars.registerHelper('i18n', (languageCode, options) =>
{
	// ignore any {{i18n}} syntax if only english is enabled
	if(store.getters['i18n/enabledLanguages'].length === 1)
	{
		return options.inverse(this);
	}

	assertValidLangCode(languageCode);
	assertEnabledLanguageCode(languageCode, store.getters['i18n/enabledLanguages']);

	if(languageCode !== store.getters['i18n/localeActive'])
	{
		return options.inverse(this);
	}

	return options.fn(this);
});

/**
 *
 * @param {string} key
 * @param {Record<string, string>} params
 * @param {number} [count]
 * @returns {string}
 */
function getI18nForKey(key, params = undefined, count = undefined)
{
	const i18nPath = `admin.panel.handlebars.i18n.errors.${key}`;

	if(typeof count === 'undefined')
	{
		return i18n.t(i18nPath, params);
	}

	return i18n.tc(i18nPath, count, params);
}

/**
 * Ensure the i18n blocks are using valid ISO 639-1 codes.
 *
 * @param {string} code
 */
function assertValidLangCode(code)
{
	if(!(Object.values(I18N.cLanguageCodes).includes(code)))
	{
		throw new Error(getI18nForKey('notAValidKey', { code }));
	}
}

/**
 * Ensure the i18n blocks are using enabled languages.
 *
 * @param {string} code
 */
function assertEnabledLanguageCode(code)
{
	if(!(store.getters['i18n/enabledLanguages'].includes(code)))
	{
		throw new Error(getI18nForKey('langNotEnabled', { code }));
	}
}
