import dot from 'dot-object';
import Vue from 'vue';
import secretSettings from '@/store/app/secret/settings';
import { get, post } from '@/utils/api';
import { profileLinkFromBlockData } from '@/utils/routerLinkGenerator';

const modules = {
	secret: secretSettings
};

const state = {
	settings: false,
	keyAliases: {},
	mappedKeyAliases: {},
	themeModeEnabled: false,
	theme: 'light',
	themeLockKey: '',
	headerHeight: 0,
	themeStyles: []
};

const mutations = {
	SET_SETTINGS(state, settings)
	{
		Vue.set(state, 'settings', settings);
	},
	MERGE_SETTINGS(state, changes)
	{
		Vue.set(state, 'settings', { ...state.settings, ...changes });
	},
	MERGE_KEY_ALIASES(state, changes)
	{
		Vue.set(state, 'keyAliases', { ...state.keyAliases, ...changes });
	},
	MERGE_MAPPED_KEY_ALIASES(state, changes)
	{
		Vue.set(state, 'mappedKeyAliases', { ...state.mappedKeyAliases, ...changes });
	},
	KEY_ALIASES(state, aliases)
	{
		Vue.set(state, 'keyAliases', aliases);
	},
	MAPPED_KEY_ALIASES(state, aliases)
	{
		Vue.set(state, 'mappedKeyAliases', aliases);
	},
	SET_THEME(state, theme = 'light')
	{
		Vue.set(state, 'theme', theme);
	},
	TOGGLE_THEME_MODE_ENABLED(state, enabled)
	{
		Vue.set(state, 'themeModeEnabled', enabled);
	},
	SET_THEME_LOCK_KEY(state, lockKey)
	{
		Vue.set(state, 'themeLockKey', lockKey);
	},
	SET_HEADER_HEIGHT(state, height)
	{
		Vue.set(state, 'headerHeight', height);
	},
	SET_THEME_STYLES(state, styles)
	{
		Vue.set(state, 'themeStyles', styles);
	}
};

const actions = {
	async loadSettings({ dispatch })
	{
		const { data } = await get('/settings');

		dispatch('setSettings', data);

		return data.settings;
	},
	setSettings({ commit }, data)
	{
		commit('SET_SETTINGS', data.settings);
		commit('KEY_ALIASES', data.keyAliases.aliases);
		commit('MAPPED_KEY_ALIASES', data.keyAliases.mapped);
	},
	async setSearchSettings({ commit }, changes)
	{
		const { data } = await post('settings/searchSettings', { changes });

		return data;
	},
	async changeSetting({ dispatch, rootGetters, commit }, { name, changes, merge, overwritePaths })
	{
		const { data } = await post(`settings/${name}`, { changes, merge, overwritePaths });

		if(data && merge && name === 'keyAliases')
		{
			const mapped = Object.entries(changes).reduce((agg, [alias, id]) =>
			{
				agg[alias] = rootGetters['dataSchemas/byId'](id).key;

				return agg;
			}, {});

			dispatch('mergeKeyAliases', changes);
			dispatch('mergeMappedKeyAliases', mapped);
		}
		else if(name === 'manifest')
		{
			// Just fire off the manifest update
			get('settings/updateManifest');
		}

		// update local settings on return
		commit('SET_SETTINGS', data);

		return data;
	},
	mergeKeyAliases({ commit, rootGetters }, changes)
	{
		commit('MERGE_KEY_ALIASES', changes);
	},
	mergeMappedKeyAliases({ commit }, changes)
	{
		commit('MERGE_MAPPED_KEY_ALIASES', changes);
	},
	mergeSettings({ commit }, changes)
	{
		commit('MERGE_SETTINGS', changes);
	},
	applyTheme({ state: { themeModeEnabled, theme: currentTheme = 'light', themeLockKey } }, { theme, override } = {})
	{
		// preserve theme
		if(themeLockKey || !(override || themeModeEnabled))
		{
			return;
		}

		const appTheme = theme === undefined ?
			currentTheme :
			theme;

		this._vm.$q.dark.set(appTheme === 'auto' ?
			appTheme :
			appTheme === 'dark');
	},
	toggleThemeModeEnabled({ commit, dispatch, state: { themeModeEnabled, theme } }, { force } = {})
	{
		const isEnabled = force === undefined ?
			!themeModeEnabled :
			force;

		dispatch('applyTheme', { theme: isEnabled ? theme : 'light', override: true });
		commit('TOGGLE_THEME_MODE_ENABLED', isEnabled);
	},
	setTheme({ commit, dispatch }, theme)
	{
		dispatch('applyTheme', { theme });
		commit('SET_THEME', theme);
	},
	toggleThemeLock({ commit, state: { themeLockKey } }, { lockKey, lock })
	{
		if(themeLockKey && themeLockKey !== lockKey)
		{
			return;
		}

		commit('SET_THEME_LOCK_KEY', lock ? lockKey : '');
	},
	setHeaderHeight({ commit, state: { headerHeight } }, height)
	{
		if(height > -1)
		{
			commit('SET_HEADER_HEIGHT', Math.round(height));
		}
	},
	async getThemeStyles({ commit })
	{
		const { data } = await post('service/getThemeStyles');

		commit('SET_THEME_STYLES', data);
	},
	async setThemeStyles({ commit, state }, changes)
	{
		const styles = state.themeStyles;

		const modifiedStyles = styles.map((style) =>
		{
			const updatedStyle = changes.find((updated) => updated.rule === style.rule);

			return updatedStyle ? { ...style, ...updatedStyle } : style;
		});

		// update post
		const result = await post('service/themeStyles', { styles: modifiedStyles });

		commit('SET_THEME_STYLES', modifiedStyles);

		return result;
	}
};

const getters = {
	get: (state) => (key) => dot.pick(key, state.settings),
	themeModeEnabled: (state) => state.themeModeEnabled,
	defaultProfilePage: (state, getters, rootState, rootGetters) => (targetId = rootGetters['user/accountId']) =>
	{
		const parts = getters.get('structure.defaults.viewProfile') || {};

		return profileLinkFromBlockData({ profileLink: parts }, { targetId }, false);
	},
	defaultProfileEditPage: (state, getters, rootState, rootGetters) =>
	{
		const parts = getters.get('structure.defaults.myProfile') || {};

		return profileLinkFromBlockData({ profileLink: parts });
	},
	keyAliases: (state) => state.keyAliases,
	keyForAlias: (state, getters) => (alias) => getters.keyAliases[alias] || null,
	mappedKeyAliases: (state) => state.mappedKeyAliases,
	mappedKeyForAlias: (state, getters) => (alias) => getters.mappedKeyAliases[alias] || null,
	headerHeight: (state) => state.headerHeight || 0,
	themeStyles: (state) => state.themeStyles,
	themeStylesByRules: (state) => (rules) =>
	{
		return Object.values(state.themeStyles).reduce((acc, arg) =>
		{
			if(rules.find((style) => style === arg.rule))
			{
				acc.push(arg);
			}

			return acc;
		}, []);
	},
	getModuleOrder: (state) => state.settings?.structure?.orders?.modules?.order || []
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	modules,
	getters
};
