import { Utils } from 'acb-lib';

/**
 * @typedef {import('../types/app').AllowedThumbnailWidth} AllowedThumbnailWidth
 */

const ImagePath = {};
let store;

/**
 * Allows generating a link to a document (e.g. pdf, word document, etc.).
 * @param {String} audioFileId Id of the document.
 * @param {boolean} breakCache
 * @param {('cors' | null)} mode Allows using CORS
 * @returns {String} URL of the document.
 */
export function generateDocumentPath(documentId, breakCache = false, mode = null)
{
	const token = store?.getters['user/imageAccessToken'];

	const mediaType = 'document';

	if(!documentId || !token) return Utils.getFallbackUrl();

	const endpoint = mode === 'cors' ? `${process.env.API}api/v1/${mediaType}/cors/` : `${process.env.API}api/v1/${mediaType}/`;
	let url = `${endpoint}${documentId}?token=${token}`;

	if(breakCache)
	{
		url += `&${Date.now()}`;
	}

	return url;
}

/**
 * Allows generating a link to an audio file
 * @param {String} audioFileId Id of the audio file.
 * @param {boolean} breakCache
 * @param {('cors' | null)} mode Allows using CORS
 * @returns {String} URL of the audio file.
 */
export function generateAudioFilePath(audioFileId, breakCache = false, mode = null)
{
	const token = store?.getters['user/imageAccessToken'];

	const mediaType = 'audio';

	if(!audioFileId || !token) return Utils.getFallbackUrl();

	const endpoint = mode === 'cors' ? `${process.env.API}api/v1/${mediaType}/cors/` : `${process.env.API}api/v1/${mediaType}/`;
	let url = `${endpoint}${audioFileId}?token=${token}`;

	if(breakCache)
	{
		url += `&${Date.now()}`;
	}

	return url;
}

/**
 * Allows generating a link to a video file.
 * @param {String} mediaFileId Id of the video file.
 * @param {Boolean} thumb Will cause the function to return the thumbnail of the video.
 * @param {boolean} breakCache
 * @param {('cors' | null)} mode Allows using CORS.
 * @returns {String} URL of the video OR of its thumbnail.
 */
export function generateVideoPath(mediaFileId, thumb = false, breakCache = false, mode = null)
{
	const token = store?.getters['user/imageAccessToken'];

	const mediaType = 'video';

	if(!mediaFileId || !token) return Utils.getFallbackUrl();

	const endpoint = mode === 'cors' ? `${process.env.API}api/v1/${mediaType}/cors/` : `${process.env.API}api/v1/${mediaType}/`;
	let url = `${endpoint}${mediaFileId}?token=${token}`;

	if(thumb)
	{
		url += '&thumb=true';
	}

	if(breakCache)
	{
		url += `&${Date.now()}`;
	}

	return url;
}

/**
 * @callback MyCallback
 * @returns {number}
 */

/**
 * Generates the path of an image.
 * @param {(string | number)} imageId
 * @param {boolean} thumb If `true` will return the thumbnail of the image.
 * Default thumbnail width is `56px`. Use `thumbWidth` for another width.
 * @callback WidthCalculator
 * @returns {number}
 */

/**
 * Generates the path of an image.
 * @param {(string | number)} imageId
 * @param {boolean} thumb If `true` will return the thumbnail of the image. Default
 * thumbnail width is `56px`. Use `thumbWidth` for another width.
 * @param {(AllowedThumbnailWidth | WidthCalculator)} thumbWidthOrFunction The
 * desired width of the image. Should be an allowed thumbnail width. Alternatively
 * accepts a function to calculate the width (e.g. based on parent size)
 * @param {boolean} breakCache
 * @param {string} mode Optional CORS setting.
 * @returns {string} The path to the image whose id was provided.
 */
export function generateImagePath(
	imageId,
	thumb = false,
	thumbWidthOrFunction = undefined,
	breakCache = false,
	mode = undefined
)
{
	const token = store?.getters['user/imageAccessToken'];

	if(!imageId || !token) return Utils.getFallbackUrl();

	const endPointBaseUrl = process.env.API;

	return Utils.generateImagePath(
		token,
		imageId,
		endPointBaseUrl,
		thumb,
		thumbWidthOrFunction,
		breakCache,
		mode
	);
}

ImagePath.install = function install(Vue, options = {})
{
	store = options.store;

	Vue.prototype.$imagePath = function returnImagePath(imageId, breakCache = false, mode = undefined)
	{
		return generateImagePath(imageId, false, undefined, breakCache, mode);
	};

	Vue.prototype.$videoPath = function returnVideoPath(videoId, breakCache = false, mode = undefined)
	{
		return generateVideoPath(videoId, false, undefined, breakCache, mode);
	};

	Vue.prototype.$videoThumbPath = function returnThumbPath(videoId, thumbWidth = undefined, breakCache = false, mode = undefined)
	{
		return generateVideoPath(videoId, true, thumbWidth, breakCache, mode);
	};

	Vue.prototype.$audioPath = function returnAudioPath(audioFileId, breakCache = false, mode = undefined)
	{
		return generateAudioFilePath(audioFileId, false, undefined, breakCache, mode);
	};

	Vue.prototype.$documentPath = function returnDocumentPath(documentId, breakCache = false, mode = undefined)
	{
		return generateDocumentPath(documentId, false, undefined, breakCache, mode);
	};

	Vue.prototype.$thumbPath = function returnThumbPath(imageId, thumbWidth = undefined, breakCache = false, mode = undefined)
	{
		return generateImagePath(imageId, true, thumbWidth, breakCache, mode);
	};
};

export default ImagePath;
