import { addNormal } from '@/utils/notifications';
import { getKeyForAlias } from '@/plugins/keyAlias';
import { userCan } from '@/plugins/Permissions';

let connected = false;

export default function syncSocketAndStore(socket, store, router)
{
	// socket.on('connect', () => console.log('I connect'));
	// socket.on('disconnect', () => console.log('No more connect'));
	// socket.on('error', (error) => console.log('socket error', error));
	// socket.on('connect_error', (error) => console.log('socket connect_error', error));
	// socket.on('connect_timeout', (error) => console.log('socket connect_timeout', error));
	// socket.on('reconnect', (error) => console.log('socket reconnect', error));
	// socket.on('reconnect_attempt', (error) => console.log('socket reconnect_attempt', error));
	// socket.on('reconnecting', (error) => console.log('socket reconnecting', error));
	// socket.on('reconnect_error', (error) => console.log('socket reconnect_error', error));
	// socket.on('reconnect_failed', (error) => console.log('socket reconnect_failed', error));

	socket.on('API_MESSAGE', (message) =>
	{
		return console.log(`%c${message}`, 'background-color: red; color: white');
	});

	socket.on('CONNECTED_INSTANCE', (instance) =>
	{
		store.commit('app/SET_CONNECTED_INSTANCE', instance);
	});

	socket.on('SERVER_MESSAGE', (message) =>
	{
		if(message === 'connected')
		{
			if(connected)
			{
				console.log('connected');
				store.dispatch('i18n/load', true);
			}

			connected = true;

			store.dispatch('app/setConnected');
		}
		else
		{
			console.log(`%c${message}`, 'background-color: blue; color: white');
		}
	});

	socket.on('i18n:update', () =>
	{
		store.dispatch('i18n/load', true);
	});

	socket.on('profile:update', (data) =>
	{
		const [accountId] = data.type.split(':');

		store.dispatch('profiles/updateProfileData', { accountId, data: data.data });
	});

	// The current user updated their data in another tab
	socket.on('user:updateData', (data) =>
	{
		const { accountId } = store.state.user;

		store.dispatch('profiles/updateProfileData', { accountId, data });
	});

	socket.on('chat:newMessage', async (data) =>
	{
		const { default: sanitizeHtml } = await import('sanitize-html');

		const { latestMessage } = data;

		const sanitizeOptions = {
			allowedTags: ['p', 'b', 'i', 'em', 'u', 'strong', 'br', 'a'],
			// As we allow users to send links we have to make sure it opens safely in a new tab
			transformTags: {
				a(tagName, attribs)
				{
					attribs.target = '_blank';
					attribs.rel = 'noopener noreferrer nofollow';

					return {
						tagName,
						attribs
					};
				}
			},
			// Only allow http and https and not ftp or email links
			allowedSchemes: ['http', 'https', 'mailto', 'tel']
		};

		// strip html tags from string for toast notification
		latestMessage.message = sanitizeHtml(latestMessage.message, sanitizeOptions);

		const currentlyViewingThisChat = store.state.route.name === 'chat' && latestMessage.targetId === store.state.route.params.targetId;

		await store.dispatch('chats/loadChat', latestMessage.targetId);
		store.dispatch('chats/updateChatData', data);
		store.dispatch('user/notifications/updateChatNotification', { updateSeen: !currentlyViewingThisChat, message: data.latestMessage });

		// If the user is not currently on the chat page, send a notification
		if(!currentlyViewingThisChat)
		{
			await store.dispatch('profiles/loadProfile', latestMessage.from);
			const usersName = store.getters['profiles/getDataValueByPath'](latestMessage.from, getKeyForAlias('FIRSTNAME'), 'profile');

			// TODO: How can we make this translatable (view)?
			const viewLabel = store.getters['i18n/get']('chat.view');

			addNormal(`${usersName}: ${sanitizeHtml(latestMessage.message, { allowedTags: [], allowedAttributes: {} })}`, [{ label: viewLabel, handler: () => router.push({ name: 'chat', params: { targetId: latestMessage.targetId } }) }]);

			if(typeof Notification !== 'undefined')
			{
				if(store.getters['app/notificationPermission'] === 'granted')
				{
					navigator.serviceWorker.getRegistration().then((reg) =>
					{
						if(reg)
						{
							const body = sanitizeHtml(
								latestMessage.message,
								{
									allowedTags: [],
									allowedAttributes: {}
								}
							);

							// At the moment there is no click listener on this
							// Need to make it so that it checks to see if this window is open anywhere, and if not, open a window, otherwise, redirect
							reg.showNotification(`${usersName}`, {
								body
							});
						}
					});
				}
			}
		}
	});

	socket.on('chat:unreadCount', (data) =>
	{
		const currentlyViewingThisChat = store.state.route.name === 'chat' && data.id === store.state.route.params.targetId;

		if(!currentlyViewingThisChat)
		{
			store.dispatch('chats/updateChatData', data);
		}
		else if(data.unread > 0) // no point in updating if there's no unread messages
		{
			store.dispatch('chats/markAsRead', data.id);
		}
	});

	socket.on('events:notification', async (payload) =>
	{
		await store.dispatch('user/notifications/addSystemNotification', payload);

		// add back when discussion feed is a thing BUT this is commented out because the discussion feed query is causing errors because it's too large of a query so that needs fixing
		// if(payload.typeId === 7 || payload.typeId === 6)
		// {
		// await store.dispatch('user/notifications/loadFollowedItems', { muted: false });
		// }
	});

	socket.on('settings:changes', (data) =>
	{
		store.dispatch('app/settings/mergeSettings', data);
	});

	socket.on('settings:updated', (data) =>
	{
		store.dispatch('app/settings/setSettings', data);
	});

	socket.on('items:changes', (data) =>
	{
		store.dispatch('app/handleServerResponse', data, { root: true });
	});

	socket.on('permissions:update', (data) =>
	{
		store.dispatch('user/setPermissions', data.permissions);
	});

	socket.on('permissions:global:change', (data) =>
	{
		// Staggering the updates to stop us DDOSing ourselves
		// This may not work out, we will have to re-evaluate
		setTimeout(async () =>
		{
			await store.dispatch('user/getPermissions');

			// double check if the user can do these admin actions - if not, deactivate
			// edit mode
			if(!userCan('manageEditMode', 'administration'))
			{
				store.dispatch('admin/setEditMode', false);
			}

			// text mode
			if(!userCan('manageTextEdit', 'administration'))
			{
				store.dispatch('i18n/admin/setEditMode', false);
			}
		}, Math.random() * (data?.total || 50) * 20); // refresh 50 people in one second on average. Probability of all 50 landing on the same ms is (1/1000)^50
	});

	socket.on('userLists:updated', async (data) =>
	{
		const module = store.getters['structure/modules/getActiveModule'];
		const page = store.getters['structure/pages/getActivePage'];

		// set state that the module needs to update content for removal scenario
		store.dispatch('structure/modules/updateModuleForRemoval', data?.removingFromList || false);

		if(module && page)
		{
			store.dispatch('structure/modules/loadModule', module.id);
			// we only need to force reload the page content when removing a user from a list
			await store.dispatch('structure/pages/forcePageLoad', page);
		}
	});

	socket.on('userLists:updated:state', (data) =>
	{
		const { membershipId, updatedState } = data;

		store.dispatch('user/updateUserListState', { membershipId, updatedState });
	});

	socket.on('userLists:memberships:added', (data) =>
	{
		const { newMemberships } = data;

		newMemberships.forEach((newMembership) =>
		{
			store.dispatch('user/upsertListMembership', newMembership, { root: true });
		});
	});

	// note that this is by userListId and _not_ membershipId
	socket.on('userLists:memberships:removed', (data) =>
	{
		const { userListIds } = data;

		userListIds.forEach((userListId) =>
		{
			// TODO figure out if this can cause issues when they're all being removed simultaneously from the same array
			store.dispatch('user/removeListMembershipByUserListId', userListId, { root: true });
		});
	});

	socket.on('notifications:itemsToSubscribeTo', (data) =>
	{
		// This can cause issues when loading the app sometimes (mainly for the monitoring script for some reason) so if it dies we'll just log it to the console
		try
		{
			store.dispatch('user/notifications/updateUsersEntitySubscriptions', { data, addToArray: true });
		}
		catch(e)
		{
			console.log(e);
		}
	});

	socket.on('insights:dashboard', (/** @type {Insights.SocketOnDashboard} */ dashboardDefinition) =>
	{
		store.dispatch('insights2/admin/handleDashboardSocketMessage', dashboardDefinition);
	});

	socket.on('insights:reports', (/** @type {Insights.SocketOnReports} */ reports) =>
	{
		store.dispatch('insights2/admin/handleReportsSocketMessage', reports);
	});

	socket.on('connection:state:update', async (data) =>
	{
		await store.dispatch('applications/connectionRequests/resetConnections');
		await store.dispatch(
			'applications/connectionRequests/loadPotentialConnections',
			data.leaderId
		);

		await store.dispatch('applications/connectionRequests/loadOwnConnectionRequests');
	});

	if(process.env.NODE_ENV !== 'production')
	{
		socket.on('structure:change', () =>
		{
			// DO NOT make the client automatically update the structure - imagine thousands of users doing a simultaneous call for update - it will cause a DDOS
			// store.dispatch('structure/changesWaiting'); // TODO
		});
	}
}
